import { PageContainer } from "@ant-design/pro-layout";
import React from "react";

import { useClinica } from "../contexts";

const Home: React.FC = () => {
  const clinica = useClinica();

  return <PageContainer>Home {clinica.clinicaAtual?.nome}</PageContainer>;
};

export default Home;
