import { Select, Skeleton } from "antd";
import { RefSelectProps, SelectProps } from "antd/es/select";
import { SkeletonInputProps } from "antd/es/skeleton/Input";
import React, { useEffect, useState } from "react";

import { apiAutoComplete } from "../services/apis";
import { CompromissoModel } from "../services/models";

type SelectCompromissoValueType = number;
type SelectCompromissoOptionType = CompromissoModel;

export interface SelectCompromissoProps
  extends SelectProps<
    SelectCompromissoValueType | SelectCompromissoValueType[],
    SelectCompromissoOptionType
  > {
  loadingProps?: SkeletonInputProps;
}

/**
 * Cache interno para evitar rechamadas em todos locais que usam esse componente
 */
let CACHE: CompromissoModel[] | null = null;

export const SelectCompromisso = React.forwardRef<
  RefSelectProps,
  SelectCompromissoProps
>((props, ref) => {
  const { loadingProps, ...rest } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [compromissos, setCompromissos] = useState<CompromissoModel[]>([]);

  /**
   * Inicializa a lista de compromissos
   */
  useEffect(() => {
    /**
     * Caso tiver cache, use o cache para definir a lista
     */
    if (CACHE) {
      setCompromissos(CACHE);
      return;
    }

    setIsLoading(true);

    apiAutoComplete
      .compromissos()
      .then((response) => {
        const { data } = response.data;
        CACHE = data.filter((d) => d.exibeLista);
        setCompromissos(data.filter((d) => d.exibeLista));
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Skeleton.Input {...loadingProps} />;
  }

  return (
    <Select<
      SelectCompromissoValueType | SelectCompromissoValueType[],
      SelectCompromissoOptionType
    >
      dropdownMatchSelectWidth={300}
      optionFilterProp={"text"}
      options={compromissos.map((c) => ({
        ...c,
        value: c.idCompromisso,
        text: c.motivo,
        label: c.motivo,
      }))}
      status={error ? "error" : undefined}
      {...rest}
      style={{
        minWidth: "100px",
        ...rest.style,
      }}
      ref={ref}
    />
  );
});
