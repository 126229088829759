/* eslint-disable react-hooks/rules-of-hooks */
import "./index.scss";

import ProForm, {
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-form";
import { Button, Card, Form, FormInstance } from "antd";
import React, { useState } from "react";

import ModalEmitirRaioXPago from "./ModalEmitirRaioX";
import ModalPago from "./ModalPagoImg";
import ParcelaRaioX from "./ParcelasRaioX";

const emissaoRaioX: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [imagem, setImagem] = useState(false);
  const [emitirRaioXPago, setEmitirRaioXPago] = useState(false);
  const [qtdPagamentos, setQtdPagamentos] = useState<number>(1);

  async function OpenModalImagem(row: any) {
    if (row) {
      setImagem(row);
    } else {
      setImagem(false);
    }
    setIsModalVisible(true);
  }

  async function OpenModalEmitirRaioXPago(row: any) {
    if (row) {
      setEmitirRaioXPago(row);
    } else {
      setEmitirRaioXPago(false);
    }
    setIsModalVisible2(true);
  }

  const formRef = React.createRef<FormInstance>();

  const onCancel = () => {
    formRef.current?.resetFields();
  };
  return (
    <>
      <Form ref={formRef}>
        <br />
        <br />
        <ProForm.Group>
          <ProFormSelect
            options={[
              {
                value: "1",
                label: "Panoramico",
              },
              {
                value: "2",
                label: "Periapical",
              },
            ]}
            width="sm"
            name="raio-x"
            label="Tipo Raio-X"
          />
          <ProFormSelect
            options={[
              {
                value: "1",
                label: "1",
              },
              {
                value: "2",
                label: "2",
              },
              {
                value: "3",
                label: "3",
              },
              {
                value: "4",
                label: "4",
              },
            ]}
            width="sm"
            name="quantidade"
            label="Quantidade"
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText width="lg" name="paciente" label="Paciente/Prontuário" />
          <ProFormText width="md" name="CPF" label="CPF" />
          <ProFormText
            width="lg"
            name="valor"
            label="Valor "
            placeholder="120,00"
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText width="lg" name="prontuario" label="Prontuário" />
          <ProFormText
            width="md"
            name="nascimento"
            label="Data de Nascimento"
            dataFormat="DD/MM/YYYY"
          />
          <ProFormSelect
            options={[
              {
                value: "1",
                label: "Londrina - Zona Norte",
              },
              {
                value: "2",
                label: "Londrina - Av. Bandeirantes",
              },
            ]}
            width="sm"
            name="Clinica"
            label="Clinica"
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormTextArea width="xl" label="Observações" name="obs" />
        </ProForm.Group>
        <div className="formas-pagmentos">
          <Card size="small">
            <table>
              <tr>
                <th colSpan={5}>
                  <p
                    className="title"
                    style={{ marginBottom: 10, marginTop: 0 }}
                  >
                    Selecione a quantidade de formas de pagamento:
                  </p>
                </th>
              </tr>
              <tr>
                <td>
                  <Button
                    type="primary"
                    onClick={() => setQtdPagamentos(1)}
                    shape="circle"
                  >
                    1
                  </Button>{" "}
                </td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => setQtdPagamentos(2)}
                    shape="circle"
                  >
                    2
                  </Button>{" "}
                </td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => setQtdPagamentos(3)}
                    shape="circle"
                  >
                    3
                  </Button>{" "}
                </td>
              </tr>
            </table>
            <table className="table-payment" style={{ marginTop: 20 }}>
              {Array.from({ length: qtdPagamentos }).map(() => {
                return <ParcelaRaioX />;
              })}
            </table>
          </Card>
        </div>
        <ProForm.Group style={{ textAlign: "center" }}>
          {imagem && (
            <ModalPago
              visible={isModalVisible}
              imagem={imagem}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
            />
          )}
          {emitirRaioXPago && (
            <ModalEmitirRaioXPago
              visible={isModalVisible2}
              emitirRaioX={emitirRaioXPago}
              onOk={() => setIsModalVisible2(false)}
              onCancel={() => setIsModalVisible2(false)}
            />
          )}
          <Button key="1" type="primary" onClick={OpenModalImagem}>
            Adicionar Imagem
          </Button>
          <Button key="2" type="primary" onClick={OpenModalEmitirRaioXPago}>
            Emitir Raio-X Pago
          </Button>
          <Button key="3" type="primary" danger onClick={onCancel}>
            Cancelar
          </Button>
        </ProForm.Group>
      </Form>
    </>
  );
};

export default emissaoRaioX;
