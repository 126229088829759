import {SearchOutlined} from "@ant-design/icons";
import {Button, DatePicker } from 'antd';
import React from 'react';

const HeaderFilter: React.FC = () => {
    function onChange() {
    }
    return (
        <div className="filters">
            <label>
                <span style={{margin: 10}}>Data das consultas de avaliação:</span>
                <DatePicker style={{padding: 10}} onChange={onChange} />
                <Button style={{marginLeft: 10}} type="primary" icon={<SearchOutlined />} size="large">
                    Buscar
                </Button>
            </label>
        </div>
    );
};

export default HeaderFilter