import "./index.scss";

import { DeleteOutlined, EyeOutlined, StopOutlined } from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import type { ActionType, ProColumns } from "@ant-design/pro-table";
import ProTable from "@ant-design/pro-table";
import { Button, Dropdown, Menu, message, Popconfirm } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SelectClinica } from "../../components/SelectClinica";
import { SelectCompromisso } from "../../components/SelectCompromisso";
import { SelectDentista } from "../../components/SelectDentista";
import { useClinica } from "../../contexts";
import { apiAgendaCompromisso } from "../../services/apis";
import { AgendaCompromissoModel } from "../../services/models";
import { AgendaCompromissoFormModal } from "./components/AgendaCompromissoFormModal";

export const AgendaCompromisso: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idAgendaCompromisso, setIdAgendaCompromisso] = useState<number | null>(
    null
  );

  const [searchParams, setSearchParams] = useSearchParams({
    aba: "dentista",
  });

  const tableRef = React.useRef<ActionType>(null);

  const navigate = useNavigate();

  const { clinicaAtual } = useClinica();

  async function openModalForm(idAgendaCompromisso: number | null) {
    setIdAgendaCompromisso(idAgendaCompromisso);
    setIsModalVisible(true);
  }

  const closeModal = () => {
    setIdAgendaCompromisso(null);
    setIsModalVisible(false);
  };

  const menuAcoes = (row: AgendaCompromissoModel) => {
    const dataInicio = dayjs(row.dataInicio);
    const dataFinal = dayjs(row.dataFinal);

    if (dataFinal.isSameOrBefore(dayjs())) {
      return (
        <Button onClick={() => openModalForm(row.idAgendaCompromisso)}>
          <EyeOutlined />
        </Button>
      );
    }

    let botao = (
      <>
        <DeleteOutlined /> Deletar
      </>
    );
    let title = "Esta certo disso ?";
    let mensagemDelete = "Deletando...";

    if (dataInicio.isSameOrBefore(dayjs())) {
      botao = (
        <>
          <StopOutlined /> Finalizar
        </>
      );
      title =
        "Esta certo disso ? Isso irá alterar a data final para a data de ontem";
      mensagemDelete = "Finalizando...";
    }

    const onConfirm = () => {
      message.loading({ content: mensagemDelete, key: "delete" });
      apiAgendaCompromisso
        .excluir(row.idAgendaCompromisso)
        .then(() => {
          message.success({ content: "Pronto", key: "delete" });
          tableRef.current?.reload();
        })
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response?.data?.message) {
            message.error({
              content: error.response?.data?.message,
              key: "delete",
            });
          } else {
            message.error({
              content: "Erro desconhecido",
              key: "delete",
            });
          }
        })
        .finally(() => {});
    };

    return (
      <Dropdown.Button
        onClick={() => openModalForm(row.idAgendaCompromisso)}
        overlay={
          <Menu>
            <Popconfirm title={title} onConfirm={onConfirm}>
              <Menu.Item key={"deletar"} danger>
                {botao}
              </Menu.Item>
            </Popconfirm>
          </Menu>
        }
      >
        <EyeOutlined />
      </Dropdown.Button>
    );
  };

  const columns: ProColumns<AgendaCompromissoModel>[] = [
    {
      title: "Clinica",
      dataIndex: ["clinica", "nomeClinica"],
      valueType: "select",
      hideInSearch: searchParams.get("aba") !== "clinica",
      hideInTable: searchParams.get("aba") !== "clinica",
      renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
        if (type === "form") {
          return null;
        }

        return (
          <SelectClinica
            showSearch
            onSelect={rest.onSelect}
            value={rest.value}
          />
        );
      },
      search: {
        transform: (value) => ({
          idClinica: value,
        }),
      },
    },
    {
      title: "Dentista",
      dataIndex: ["dentista", "apelidoDentista"],
      valueType: "select",
      hideInSearch: searchParams.get("aba") !== "dentista",
      hideInTable: searchParams.get("aba") !== "dentista",
      renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
        if (type === "form") {
          return null;
        }

        return (
          <SelectDentista
            showSearch
            onSelect={rest.onSelect}
            value={rest.value}
            mode={"multiple"}
            allowClear
          />
        );
      },
      search: {
        transform: (value) => ({
          idDentista: value,
        }),
      },
    },
    {
      title: "Data Início",
      dataIndex: "dataInicio",
      valueType: "date",
      initialValue: dayjs(),
      fieldProps: {
        format: ["DD/MM/YYYY", "YYYY-MM-DD"],
      },
      search: {
        transform: (value) => ({
          dataInicio: value.format("YYYY-MM-DD"),
        }),
      },
      render: (dom, row) =>
        row.dataInicio ? dayjs(row.dataInicio).format("DD/MM/YYYY") : "",
    },
    {
      title: "Data Fim",
      dataIndex: "dataFinal",
      valueType: "date",
      fieldProps: {
        format: ["DD/MM/YYYY", "YYYY-MM-DD"],
      },
      search: {
        transform: (value) => ({
          dataFinal: value.format("YYYY-MM-DD"),
        }),
      },
      render: (dom, row) =>
        row.dataFinal ? dayjs(row.dataFinal).format("DD/MM/YYYY") : "",
    },
    {
      title: "Hora Início",
      dataIndex: "horaInicio",
      valueType: "time",
      hideInSearch: true,
      render: (dom, row) =>
        row.horaInicio ? dayjs(row.horaInicio, "HH:mm:ss").format("HH:mm") : "",
    },
    {
      title: "Hora Fim",
      dataIndex: "horaFim",
      hideInSearch: true,
      render: (dom, row) =>
        row.horaFim ? dayjs(row.horaFim, "HH:mm:ss").format("HH:mm") : "",
    },
    {
      title: "Compromisso",
      dataIndex: ["compromisso", "motivo"],
      valueType: "text",
      renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
        if (type === "form") {
          return null;
        }

        return (
          <SelectCompromisso
            showSearch
            onSelect={rest.onSelect}
            value={rest.value}
            mode={"multiple"}
          />
        );
      },
      search: {
        transform: (value) => ({
          idCompromisso: value,
        }),
      },
    },
    {
      hideInSearch: true,
      title: "Ações",
      valueType: "option",
      render: (dom, row: any) => [menuAcoes(row)],
    },
  ];
  return (
    <PageContainer
      title="Horários Indisponíveis"
      header={{
        onBack: () => navigate(".."),
      }}
      extra={[
        <Button key="1" type="primary" onClick={() => openModalForm(null)}>
          Novo
        </Button>,
      ]}
    >
      <AgendaCompromissoFormModal
        defaultTipo={searchParams.get("aba") || undefined}
        idAgendaCompromisso={idAgendaCompromisso}
        visible={isModalVisible}
        onOk={() => {
          closeModal();
          tableRef.current?.reload();
        }}
        onCancel={closeModal}
      />
      <ProTable<AgendaCompromissoModel>
        actionRef={tableRef}
        columns={columns}
        request={(params, sorter, filter) => {
          const { pageSize, current, ...rest } = params;

          return apiAgendaCompromisso
            .list({
              params: {
                per_page: pageSize,
                page: current,
                tipo: searchParams.get("aba") as any,
                idClinicaDentista: [clinicaAtual.id],
                ...rest,
              },
            })
            .then((data) => ({
              data: data.data.data,
              success: true,
              total: data.data.meta.total,
            }))
            .catch((error) => ({
              data: [],
              success: false,
              total: 0,
            }));
        }}
        toolbar={{
          menu: {
            type: "tab",
            activeKey: searchParams.get("aba") as any,
            items: [
              {
                key: "dentista",
                label: <span>Dentista</span>,
              },
              {
                key: "clinica",
                label: <span>Clinica</span>,
              },
              {
                key: "global",
                label: <span>Global</span>,
              },
            ],
            onChange: (key) => {
              setSearchParams({
                ...searchParams,
                aba: key as string,
              });
              tableRef.current?.reload(true);
            },
          },
        }}
        rowKey="idAgendaCompromisso"
        pagination={{
          showQuickJumper: true,
        }}
        search={{
          layout: "vertical",
          defaultCollapsed: true,
        }}
        dateFormatter={false}
      />
    </PageContainer>
  );
};
