import api from "../api";
import {
  CaptacaoPacienteModel,
  ClinicaModel,
  CompromissoModel,
  PacienteModel,
  TodosPacientesModel,
} from "../models";
import { PaginatedRequest, PaginatedResponse } from "../utilities";

export function captacaoPaciente(
  options: {
    params?: {
      searchText?: string;
      idClinica?: number;
      idCaptacaoPaciente?: number;
    };
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<CaptacaoPacienteModel>>(
    "autocomplete/captacao-pacientes",
    options
  );
}

export function clinicas(
  options: {
    params?: PaginatedRequest<{
      q?: string;
    }>;
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<ClinicaModel>>(
    "autocomplete/clinicas",
    options
  );
}

export function compromissos(
  options: {
    params?: PaginatedRequest<{
      q?: string;
    }>;
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<CompromissoModel>>(
    "autocomplete/compromissos",
    options
  );
}

export function paciente(
  options: {
    params?: {
      searchText?: string;
      idClinica?: number;
      idPaciente?: number;
      comercial?: boolean;
    };
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<PacienteModel>>(
    "autocomplete/paciente",
    options
  );
}

export function pacienteAgendaUsuario(
  options: {
    params?: {
      searchText?: string;
      idClinica?: number;
      idPaciente?: number;
      comercial?: boolean;
    };
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<PacienteModel>>(
    "agenda/autocomplete-paciente",
    options
  );
}


export function todosPacientes(
  options: {
    params?: {
      searchText?: string;
      idClinica?: number;
      idPaciente?: number;
      idCaptacaoPaciente?: number;
    };
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<TodosPacientesModel>>(
    "autocomplete/todos-pacientes",
    options
  );
}
