import ProForm from "@ant-design/pro-form";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button } from "antd";
import React, { useEffect, useState } from "react";

import ModalAddLab from "./ModalAddLaboratorio";
import { Laboratorios } from "./types";


const Gerenciar: React.FC = () => {
  const [dados, setDados] = useState<Laboratorios[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: Laboratorios[] = [];

    for (let i = 0; i < 20; i += 1) {
      tableList.push({
        nome: "Lab Max - Luciana",
        documento: "31.515.561/0001-57",
      });
    }
    setDados(tableList);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
    }),
};

  const columns: ProColumns<Laboratorios>[] = [ 
    {
      title: "Nome",
      dataIndex: "nome",
    },
    {
      title: "Documento",
      dataIndex: "documento",
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addLab, setAddLab] = useState(false);

  async function OpenModalLab(row: any) {
    if (row) {
      setAddLab(row);
    } else {
      setAddLab(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
     {addLab && (
        <ModalAddLab
          visible={isModalVisible}
          addLab={addLab}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      <ProForm.Group>
        <Button type="primary" onClick={OpenModalLab}>Adicionar Novo Laboratorio</Button>
      </ProForm.Group>

      <ProTable<Laboratorios>
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
        }}  
        columns={columns}
        request={() => {
          return Promise.resolve({
            data: tableList,
            success: true,
          });
        }}
        rowKey="key"
        search={false}
        dateFormatter="string"
      />
    </>
  );
};

export default Gerenciar;
