import ProForm, { ProFormText } from "@ant-design/pro-form";
import { Modal } from "antd";
import React from "react";

interface ModalCodBarras {
  visible: boolean;
  codBarras: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const OrdemServico: React.FC<ModalCodBarras> = (props) => {
  return (
    <>
      <Modal
        title={"Pesquisa por Código de Barras"}
        width={"40%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <ProForm submitter={false}>
          {" "}
          <div style={{ textAlign: "center" }}>
            <img src={"	https://hmldh.oralsin.com.br/img/scanBarcode.gif"} alt="" />
          </div>
          <br />
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            <span id="explain">
              Posicione o leitor e passe pelo código conforme o exemplo acima,
              ou digite o código abaixo e aperte a tecla 'Enter'
            </span>
          </div>
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            <ProFormText width="sm"></ProFormText>
          </div>
        </ProForm>
      </Modal>
    </>
  );
};

export default OrdemServico;
