import { Button, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
import Text from "antd/es/typography/Text";
import React, { useState } from 'react';

import { useCheckout } from './../Context/CheckoutContext';

const { TabPane } = Tabs;

const Odontograma: React.FC = (props, root) => {

    const {
        optionFaceSelected,
        setOptionFaceSelected,
        optionsFace,
        teethSelected,
        addTeeth,
        removeTeeth,
    } = useCheckout()
    const [selectClass, setSelectClass] = useState<string>(optionsFace[1]);
    const [activeTeethClass, setActiveTeethClass] = useState<any>([]);

    function buttonClick( selected: number ){
        setOptionFaceSelected(selected)
        setSelectClass(optionsFace[selected])
    }

    function getClass(button: number){
      if(button === optionFaceSelected){
          return "active"
      }
      return void 1
    }

    function getDenteUrl(dente: number, active: boolean = false){
        if(active){
            return `https://hmldh.oralsin.com.br/img/dentes_numerados/green_${dente}.png`
        } else {
            return `https://hmldh.oralsin.com.br/img/dentes_numerados/gray_${dente}.png`
        }
    }

    function hasTeeth(teeth: number){
        return teethSelected.indexOf(teeth) !== -1
    }

    const teethClicked = (teeth: number) => {
        if(hasTeeth(teeth)){
            removeTeeth(teeth)
        } else {
            addTeeth(teeth)
        }
        setActiveTeethClass(teethSelected)
    }

    function getTeethClass(dente: number){
        if(activeTeethClass.indexOf(dente) !== -1){
            return 'active'
        }
        return void 1
    }

    const denteImg = (dente: number) => <img
        key={dente}
        className={getTeethClass(dente)}
        onClick={()=>teethClicked(dente)}
        src={getDenteUrl(dente)}
        id={dente.toString()}
        alt={dente.toString()}
    />;

    return <>
        <div className="arcadas">
            <div className="options">
                <Button className={getClass(1)} onClick={() => buttonClick(1)}>
                    Boca
                    <img style={{ width: "2em", marginLeft: 5 }} src={'https://hmldh.oralsin.com.br/img/boca.png'} />
                </Button>
                <Button className={getClass(2)} onClick={() => buttonClick(2)}>
                    Arcada
                    <img style={{ width: "2em", marginLeft: 5 }} src={'https://hmldh.oralsin.com.br/img/arcada.png'} />
                </Button>
                <Button className={getClass(3)} onClick={() => buttonClick(3)}>
                    Quadrante
                    <img style={{ width: "2em", marginLeft: 5 }} src={'https://hmldh.oralsin.com.br/img/quadrante.png'} />
                </Button>
                <Button className={getClass(4)} onClick={() => buttonClick(4)}>
                    Dente
                    <img style={{ width: "2em", marginLeft: 5 }} src={'https://hmldh.oralsin.com.br/img/dente.png'} />
                </Button>
            </div>
            <div className={`${selectClass} dentes`}>
                <div className="superior linha">
                    <div className="bloco bloco-1">
                        { [18, 17, 16, 15, 14, 13, 12, 11].map( denteImg )}
                    </div>
                    <div className="bloco bloco-2">
                        { [21, 22, 23, 24, 25, 26, 27, 28].map( denteImg )}
                    </div>
                </div>
                <div className="inferior linha">
                    <div className="bloco bloco-1">
                        { [48, 47, 46, 45, 44, 43, 42, 41,].map( denteImg )}
                    </div>
                    <div className="bloco bloco-2">
                        { [31,32,33,34,35,36,37,38,].map( denteImg )}
                    </div>
                </div>
            </div>
           <div className='tab-content-odoto'>
               <Tabs tabPosition='left'>
                   <TabPane tab="Observações" key="1">
                       <TextArea rows={4} />
                   </TabPane>
                   <TabPane tab="Obs. para Impressão" key="2">
                       <Text type="danger">Essa observação sairá impressa</Text>
                       <TextArea rows={4} />
                   </TabPane>
                   <TabPane tab="Histórico" key="3">
                       Nenhum Histórico
                   </TabPane>
               </Tabs>
           </div>
        </div>
    </>;
};

export default Odontograma