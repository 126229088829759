/* eslint-disable react-hooks/rules-of-hooks */
import "./index.scss";

import { SearchOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, DatePicker, Tabs } from "antd";
import React, { useState } from "react";

import BuscarRaioXData from "./BuscarRaioXData";
import ListaEspera from "./ListaEspera";
import RaioXGratuito from "./RaioXGratuito";
import RaioXPago from "./RaioXPago";

const { TabPane } = Tabs;

const emissaoRaioX: React.FC = () => {
  const [buscarImg, setBuscarImg] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  async function Open(row: any) {
    if (row) {
      setBuscarImg(row);
    } else {
      setBuscarImg(false);
    }
  }

  return (
    <>
      <PageContainer>
        <ProCard
          tabs={{
            type: "card",
          }}
        >
          <ProCard.TabPane key="0" tab="Novo Exame">
            <div>
              <Tabs centered>
                <TabPane tab="Lista Espera" key="1">
                  <ListaEspera />
                </TabPane>
                <TabPane tab="Exame Pago" key="2">
                  <RaioXPago />
                </TabPane>
                <TabPane tab="Exame Gratuito" key="3">
                  <RaioXGratuito />
                </TabPane>
              </Tabs>
            </div>
          </ProCard.TabPane>
          <ProCard.TabPane
            key="2"
            tab="Exame do Dia"
            style={{ textAlign: "center" }}
          >
            Data: <DatePicker format={"DD/MM/YYYY"} />{" "}
            <Button type="primary" icon={<SearchOutlined />} onClick={Open}>
              {" "}
              Buscar
            </Button>{" "}
            {buscarImg && (
              <BuscarRaioXData
                visible={isVisible}
                buscarImg={buscarImg}
                onOk={() => setIsVisible(false)}
                onCancel={() => setIsVisible(false)}
              />
            )}
          </ProCard.TabPane>
        </ProCard>
      </PageContainer>
    </>
  );
};

export default emissaoRaioX;
