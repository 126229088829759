import { Modal } from "antd";
import React from "react";

interface ModalCodImprimir {
  visible: boolean;
  imprimirCod: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const ControlePedido: React.FC<ModalCodImprimir> = (props) => {
  return (
    <>
      <Modal
        title={"Impressão código de barras"}
        width={"30%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
        okText="Imprimir"
        cancelText="Fechar"
      >
      </Modal>
    </>
  );
};

export default ControlePedido;
