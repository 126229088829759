import { ProFormSelect } from '@ant-design/pro-form';
import axios from "axios";
import React, { useEffect, useState } from "react";

import api from "../../../services/api";
// import { ContaPagarTipo } from '../types';


function ContaPagarTipo(props: any){

    const [contaPagarTipo, setContaPagarTipo]       = useState();
    const [loadContaPagarTipo, setLoadContaPagarTipo] = useState(false);

    const listaContaPagarTipo = async () => {
        
        setLoadContaPagarTipo(true);
        let lista: any = [];

        try {
            const { data } = await api.get("financeiro/list-conta-pagar-tipo", {});
            
            data.map(function (result: any, index: number) {
                lista[index] = { value: result.idContaPagarTipo, label: result.nomeTipo }; 
            });

            setContaPagarTipo(lista);
            setLoadContaPagarTipo(false);
            
        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        listaContaPagarTipo();
      }, []);

    return (

        <ProFormSelect
            fieldProps={{
                ...props,
                loading: loadContaPagarTipo,
            }}
            options={contaPagarTipo}
            showSearch={true}
        />
            
    )    

}
export default ContaPagarTipo;
