import { DeleteOutlined, MonitorOutlined } from "@ant-design/icons";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Dropdown, Menu, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";

import ModalVisualizarControle from "./ModalVisualizarControle";
import { SelecioneOS } from "./types";

interface ModalSelecioneOS {
  visible: boolean;
  selecioneOs: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const menuAcoes = (key: any) => {
  return (
    <Menu>
      <Menu.Item key="1" danger icon={<DeleteOutlined />}>
        <Popconfirm
          title="Esta certo disso ?"
          onConfirm={() => {}}
          onCancel={() => {}}
          placement="top"
        >
          Deletar
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
};

const Gerenciar: React.FC<ModalSelecioneOS> = (props) => {
  const [dados, setDados] = useState<SelecioneOS[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: SelecioneOS[] = [];

    for (let i = 0; i < 10; i += 1) {
      tableList.push({
        clinica: "Londrina - Av. Bandeirantes	",
        nroOS: "688100",
        paciente: "Eliana Rodrigues De Paula",
        especialista: "Ariadne De Gaspari Maziero	",
        dataCriacao: "10/01/2022",
        dataEntrega: "Solicitada:	10/01/2022 17:00",
        proximaConsulta: "01/04/2022",
        statusAtual: "Enviado ao LAB EXTERNO	",
      });
    }
    setDados(tableList);
  }, []);

  const columns: ProColumns<SelecioneOS>[] = [
    {
      title: "Clínica",
      dataIndex: "clinica",
    },
    {
      title: "Nro. OS",
      dataIndex: "nroOS",
    },
    {
      title: "Paciente",
      dataIndex: "paciente",
    },
    {
      title: "Especialista",
      dataIndex: "especialista",
    },
    {
      title: "Data de Criação	",
      dataIndex: "dataCriacao",
    },
    {
      title: "Data de Entrega",
      dataIndex: "dataEntrega",
    },
    {
      title: "Próxima Consulta",
      dataIndex: "proximaConsulta",
    },
    {
      title: "Status Atual",
      dataIndex: "statusAtual",
    },
    {
      title: "Ações",
      key: "action",
      render: (dom, row) => (
        <span>
          <Dropdown.Button
            onClick={() => OpenModal(row)}
            overlay={menuAcoes(row)}
            trigger={["click"]}
          >
            <MonitorOutlined />
            Visualizar
          </Dropdown.Button>{" "}
        </span>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [controleOS, setControleOS] = useState(false);

  async function OpenModal(row: any) {
    if (row) {
      setControleOS(row);
    } else {
      setControleOS(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
      {controleOS && (
        <ModalVisualizarControle
          visible={isModalVisible}
          controleOS={controleOS}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}

      <ProTable<SelecioneOS>
        columns={columns}
        request={(params, sorter, filter) => {
          return Promise.resolve({
            data: tableList,
            success: true,
          });
        }}
        rowKey="key"
        pagination={{
          showQuickJumper: true,
        }}
        search={false}
        dateFormatter="string"
      />
    </>
  );
};

export default Gerenciar;
