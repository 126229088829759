import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import ProForm, { ProFormDateRangePicker, ProFormSelect, ProFormText, QueryFilter } from '@ant-design/pro-form';
import React, { useState } from 'react';

import { BancosSaida, GrupoContas, SelectFornecedor, SelectUsuario, StatusPagamento } from '../../components';
import { SelectPaciente } from '../../components/SelectPaciente';
import { ContaPagarTipo, InputMoney } from "./components";
import { FilterAPagarProps } from './types';

const FilterAPagar: React.FC<FilterAPagarProps> = (props) => {

    const [displayTipo, setDisplayTipo]               = useState<number>();
    const [labelFiltro, setLabelFiltro]               = useState<string>();
    const [displayFiltro, setDisplayFiltro]           = useState(true);
    const [displayValor, setDisplayValor]             = useState(true);
    const [displayBancosSaida, setDisplayBancosSaida] = useState(true);
    
    let optionsFiltros = [
        {
            value: 0,
            label: "Nenhum",
        },
        {
            value: 1,
            label: "Banco Saída",
        },
        {
            value: 2,
            label: "Contrato",
        },
        {
            value: 3,
            label: "Contrato Financeira",
        },
        {
            value: 4,
            label: "Descrição",
        },
        {
            value: 5,
            label: "Documento",
        },
        {
            value: 6,
            label: "Financeira",
        },
        {
            value: 7,
            label: "Valor Parcela",
        }
    ];

    const SetInput = (value: number) => {  
        setDisplayTipo(value);
    }

    const ShowInputs = (value: number) => {

        setDisplayFiltro(true);
        setDisplayBancosSaida(true);
        setDisplayValor(true);

        if (value > 1 && value < 7) {

            if (value == 2)
                setLabelFiltro('Contrato');
            else if (value == 3)
                setLabelFiltro('Contrato Financeira');
            else if (value == 4)
                setLabelFiltro('Descrição');
            else if (value == 5)
                setLabelFiltro('Documento');
            else if (value == 6)
                setLabelFiltro('Financeira');

            setDisplayFiltro(false);

        } else if(value == 1){
            setDisplayBancosSaida(false);
        } else if(value == 7)
            setDisplayValor(false);

    }

    const ContasGrupos = (childs: []) => {

        let lista: any = [];

        childs.map(function (result: any, index: number) {
            lista[lista.length] = { 
                value: result.idContaGrupo, 
                title: result.nomeContaGrupo, 
                children: (result.childs.length)?ContasGrupos(result.childs):[]
            };
        });

        return lista;

    }

    return <QueryFilter
        defaultColsNumber={10}
        layout="vertical"
        submitter={{
            searchConfig: { submitText: 'Pesquisar' },
            submitButtonProps: { icon: <SearchOutlined /> },
            resetButtonProps:  { icon: <ClearOutlined />},
        }}
        onFinish={ async (values: any) => {
                
                props.loadPage(values);

            }
        }
        onReset={() => {
            props.loadPage([])
        }}
    >
        <ProFormSelect
            options={[
                {
                    value: 1,
                    label: "Data Pagamento",
                },
                {
                    value: 2,
                    label: "Data Vencimento",
                },
                {
                    value: 3,
                    label: "Data Emissão",
                },
            ]}
            name="tipoIntervalo"
            label="Tipo Intervalo"
        />
        <ProFormDateRangePicker label="Data Inicial e Final" fieldProps={{ format: 'DD/MM/YYYY' }} name="intervalo"/>

        <ProForm.Item name="contaPagarTipo" label="Tipo">
            <ContaPagarTipo onChange={SetInput} />
        </ProForm.Item>
        {
            displayTipo === 1 && 
            <ProForm.Item name="fornecedor" label="Fornecedor">
                <SelectFornecedor />
            </ProForm.Item>
        }
        {
            displayTipo === 5 && 
            <ProForm.Item name="usuario" label="Funcionário">
                <SelectUsuario/>
            </ProForm.Item>
        }
        {
            displayTipo === 2 && 
            <ProForm.Item name="paciente" label="Paciente">
                <SelectPaciente />
            </ProForm.Item>
        }
        

        {/* FILTRO */}
        <ProFormSelect
            options={optionsFiltros}
            name="filtro"
            label="Filtro"
            fieldProps={{
                onChange: (value: number) => { ShowInputs(value); }
            }}
        />

        <ProForm.Item name="bancosSaida" label="Bancos" hidden={displayBancosSaida}>
            <BancosSaida name="BancosSaida" />
        </ProForm.Item>
        
        <ProFormText label={labelFiltro} hidden={displayFiltro} name="valorFiltro" />
        
        <div hidden={displayValor} className="ant-col ant-col-24" >
            <div className="ant-col ant-col-11" style={{float: "left"}} >
                <ProForm.Item name="valorFrom" label="De">
                    <InputMoney />
                </ProForm.Item>
            </div>
            <div className="ant-col ant-col-11" style={{float: "right"}} >
                <ProForm.Item name="valorTo" label="Até">
                    <InputMoney />
                </ProForm.Item>  
            </div>
        </div>
        <div className="ant-col ant-col-24" >
            <ProForm.Item name="contaGrupos" label="Conta Grupos">
                <GrupoContas name="contaGrupos" multiple={true} />
            </ProForm.Item>
        </div>
        <div className="ant-col ant-col-24" >
            <ProForm.Item name="statusPagamento" label="Status Pagamento">
                <StatusPagamento name="statusPagamento"/>
            </ProForm.Item>
        </div>
       
    </QueryFilter>;
}

export default FilterAPagar;