import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button } from "antd";
import React, { useEffect, useState } from "react";

import EvolucaoDados from "./Evolucao";
import ModalContrato from "./ModalContrato";
import { PlanejPendentes } from "./types";

const PlanejamentoTratamento: React.FC = () => {
  const [isModalVisibleContrato, setIsModalVisibleContrato] = useState(false);
  const [contrato, setContrato] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [evolucao, setEvolucao] = useState(false);

  const [dados, setDados] = useState<PlanejPendentes[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: any[] = [];

    for (let i = 0; i < 100; i += 1) {
      tableList.push({
        paciente: "Eronilde De Oliveira Silva (C+p)	",
        contrato: "71512 v.1",
        data_contrato: "07/03/2018",
        valor_contrato: "R$ 600,00",
        qtd_procedimentos: "1",
      });
    }
    setDados(tableList);
  }, []);

  const onFinish = (values: any) => {
    if (values) {
      setEvolucao(true);
    } else {
      setEvolucao(false);
    }
    console.log("Nome do Paciente: ", values);
  };

  async function getDetalhesVersao(row: any) {
    if (row) {
      setContrato(row);
    } else {
      setContrato(false);
    }
    setIsModalVisibleContrato(true);
  }

  const columns: ProColumns[] = [
    {
      title: "Paciente",
      dataIndex: "paciente",
    },
    {
      title: "Contrato e Versão",
      dataIndex: "contrato",
      render: () => [
        <Button type="link" onClick={getDetalhesVersao}>
          71512 v.1
          <img src="https://hmldh.oralsin.com.br/img/info_30.png" alt="" />
        </Button>,
      ],
    },
    {
      title: "Data Contrato",
      dataIndex: "data_contrato",
    },
    {
      title: "Valor Contrato",
      dataIndex: "valor_contrato",
    },
    {
      title: "Qtd. de Procedimentos",
      dataIndex: "qtd_procedimentos",
    },
    {
      title: "Opção",
      key: "option",
      valueType: "option",
      render: () => [
        <Button type="primary" htmlType="submit" onClick={onFinish}>
          Planejar
        </Button>,
      ],
    },
  ];
  return (
    <>
      <br />
      {contrato && (
        <ModalContrato
          visible={isModalVisibleContrato}
          contratoDetail={contrato}
          onOk={() => setIsModalVisibleContrato(false)}
          onCancel={() => setIsModalVisibleContrato(false)}
        />
      )}
      <br />
      {evolucao === true && (
        <EvolucaoDados
          visible={isVisible}
          evolucao={evolucao}
          onOk={() => setIsVisible(false)}
          onCancel={() => setIsVisible(false)}
        />
      )}
      <div style={{ textAlign: "center" }}>
        <h3>Pacientes com pendências de planejamentos</h3>
      </div>
      <br />
      {evolucao === false && (
        <ProTable
          columns={columns}
          request={(params, sorter, filter) => {
            return Promise.resolve({
              data: tableList,
              success: true,
            });
          }}
          rowKey="key"
          pagination={{
            showQuickJumper: true,
          }}
          search={false}
          dateFormatter="string"
        />
      )}
    </>
  );
};

export default PlanejamentoTratamento;
