import { Col, Empty, Row } from "antd";
import Text from "antd/es/typography/Text";
import React, { useEffect, useState } from 'react';

import { OrcamentoVisualizarProps } from "../types";

const OrcamentoVisualizar: React.FC<OrcamentoVisualizarProps> = (props) => {
    const [oracamento, setOrcamento] = useState<any | null>(null);
    function initializeContract(){
        if(props.id){
            const newOrcamento: any = {
                oracamento_id: props.id,
                usuario: "WILLIAN OLIVEIRA MENDES DE SOUZA",
                data: '\t25/08/2021',
                banco: "CAIXA ECONOMICA FEDERAL",
                cod: 630390,
                nsu: 740289,
                ud: 2916,
                adm: "Elo Debito",
            }

            setOrcamento(newOrcamento)
        } else{
            setOrcamento(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <div>
            {oracamento ?
                <div className="oracamentoDetalhes" >
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {oracamento.banco}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Banco:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {oracamento.cod}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Cód. Transação:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {oracamento.nsu}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>NSU:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {oracamento.ud}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Últimos dígitos:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {oracamento.adm}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Administradora:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {oracamento.data}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Data formalização:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {oracamento.usuario}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Usuário:</Text>
                        </Col>
                    </Row>
                </div>
                :<Empty />}
        </div>
    );
};

export default OrcamentoVisualizar