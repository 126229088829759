import "./index.scss";

import ProCard from "@ant-design/pro-card";
import ProForm, {
  ProFormDatePicker,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTimePicker,
  StepsForm,
} from "@ant-design/pro-form";
import { Button, Form, message } from "antd";
import React, { useRef, useState } from "react";

import AddServico from "./addServico";
import Material from "./Material";
import SelecioneDentes from "./SelecioneDentes";

const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

const OrdemServico: React.FC = () => {
  const formRef = useRef<ProFormInstance>();
  const [isVisible, setIsVisible] = useState(false);
  const [addServico, setAddServico] = useState(false);
  const [selecioneDentes] = useState(false);

  async function Open(row: any) {
    if (row) {
      setAddServico(row);
    } else {
      setAddServico(false);
    }
  }


  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields(["name"]);
  };

  return (
    <>
      <ProCard>
        <StepsForm<{
          name: string;
        }>
          formRef={formRef}
          onFinish={async () => {
            await waitTime(1000);
            message.success({
              content: "Sucesso",
              style: {
                marginTop: "20vh",
              },
            });
          }}
          formProps={{
            validateMessages: {
              required: "Campos Obrigatorios",
            },
          }}
        >
          <StepsForm.StepForm<{
            name: string;
          }>
            name="cadastro"
            title="Cadastro Geral"
            onFinish={async () => {
              console.log(formRef.current?.getFieldsValue());
              await waitTime(2000);
              return true;
            }}
          >
            <ProFormText
              name="dataEntregaOS"
              label="Data de cadastro da OS:"
              width="md"
              placeholder="21/02/2022"
            />
            <ProForm.Group>
              <ProFormText
                name="unidade"
                label="Unidade:"
                width="md"
                placeholder="Londrina - Zona Norte"
              />
              <ProFormSelect
                label="Dentista"
                name="dentista"
                width="md"
                rules={[
                  {
                    required: true,
                  },
                ]}
                options={[
                  {
                    value: "1",
                    label: "Dr. Paulo",
                  },
                  { value: "2", label: "Dr. Carlos" },
                ]}
              />
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText
                name="nomePaciente"
                label="Nome do Paciente:"
                width="md"
                rules={[{ required: true }]}
              />
              <ProFormText name="prontuario" label="Prontuário:	" width="md" />
            </ProForm.Group>
            <ProForm.Group>
              <ProFormDatePicker
                name="dataEntrega"
                label="Data de Entrega: "
                fieldProps={{
                  format: "DD/MM/YYYY",
                }}
                rules={[{ required: true }]}
              />

              <ProFormTimePicker
                name="hora"
                label="Hora:"
                fieldProps={{
                  format: "HH:mm",
                }}
                rules={[{ required: true }]}
              />
            </ProForm.Group>
            <p>
              O serviço deve retornar:{" "}
              <Button type="primary" value="Para Prova">
                Para Prova
              </Button>{" "}
              <Button type="primary" value="Pronto">
                Pronto
              </Button>{" "}
              clique sobre a opção desejada para selecionar
            </p>
            <b>Prioridade:</b>

            <p>
              Urgência: <Button type="default" ghost> Sim </Button>{" "}
              <Button type="default" ghost> Não </Button>
            </p>
            <p>
              Carga Imediata: <Button type="default" ghost> Sim </Button>{" "}
              <Button type="default" ghost> Não </Button>
            </p>
          </StepsForm.StepForm>
          <StepsForm.StepForm<{
            name: string;
          }>
            name="detalhes"
            title="Detalhes da OS"
          >
            <Material />
          </StepsForm.StepForm>
          <StepsForm.StepForm name="servico" title="Serviços">
            <table>
              <thead>
                <tr>
                  <td align="left">
                    <b>Adicionar serviço:</b>
                  </td>
                </tr>
                <ProForm.Group>
                  <ProFormSelect
                    label="Setor"
                    name="setor"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    options={[
                      {
                        value: "1",
                        label: "CAD / CAM",
                      },
                    ]}
                  />
                  <ProFormSelect
                    label="Serviço"
                    name="servico"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    options={[
                      {
                        value: "1",
                        label: "ENCERAMENTO DE DIAGNOSTICO POR ELEMENTO",
                      },
                    ]}
                  />
                </ProForm.Group>
                <b>Selecione Dentes:</b>
                <br />
                <SelecioneDentes visible={false} selecioneDentes={false} />
                <br />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={Open}>
                    Adicionar{" "}
                  </Button>{" "}
                  <Button type="primary" htmlType="button" onClick={onReset}>
                    Limpar{" "}
                  </Button>{" "}
                </div>

                {selecioneDentes && (
                  <SelecioneDentes
                    visible={isVisible}
                    selecioneDentes={selecioneDentes}
                    onOk={() => setIsVisible(false)}
                    onCancel={() => setIsVisible(false)}
                  />
                )}
                <b style={{ lineHeight: "100px" }}> Serviços Adiconados: </b>

                {addServico && (
                  <AddServico
                    visible={isVisible}
                    addServico={addServico}
                    onOk={() => setIsVisible(false)}
                    onCancel={() => setIsVisible(false)}
                  />
                )}
                <p style={{ fontWeight: "bold" }}>Observações:</p>
                <ProFormTextArea></ProFormTextArea>
              </thead>
            </table>
          </StepsForm.StepForm>
        </StepsForm>
      </ProCard>
    </>
  );
};

export default OrdemServico;
