import { EyeOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Table, Tooltip } from 'antd';
import React, { useState } from "react";

import ContratoModal from "./ContratoModal";

const { Column, ColumnGroup } = Table;

const data: any[] = [];

for (let i = 0; i <= 30; i++) data.push({
        clinica: "Londrina - Zona Norte",
        n_contrato: "1234432",
        varsao: i+1,
        paciente: "Alex Teixeira da Silva",
        cpf: "837.389.331-98",
        data: "27/01/2021",
        valor_final: "R$ 10.773,00",
        status: "Condição Especial Solicitada",
        pr: "50%"
    })

const OracamentoList: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalBody, setModalBody] = useState<number>(1);
    const [CotratoSelected, setCotratoSelected] = useState(null);

    async function OpenModal(id: any, key: number) {
        setCotratoSelected(id)
        setModalBody(key)
        setIsModalVisible(true);
    }
    const CloseModal = () => {
        setModalBody(1)
        setCotratoSelected(null)
        setIsModalVisible(false);
    };
    const submenu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="#">
                    Contrato Clínico Geral
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="#">
                    Termo para Orto
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="#">
                    Termo para Implante
                </a>
            </Menu.Item>
        </Menu>
    );
    const menu = (row: any) =>  (
        <Menu onClick={(data) => OpenModal(row, parseInt(data.key))}>
            <Menu.Item key="3">Adicionar Observaçao</Menu.Item>
            <Menu.Item key="7">Reverter contrato</Menu.Item>
            <Menu.Item key="6">
                <Dropdown overlay={submenu} placement="bottomRight">
                    <Button
                        style={{border: "none", margin: 0, padding: 0,
                            background: "transparent", width: "100%", textAlign: "left"}}>
                        Imprimir
                    </Button>
                </Dropdown>
            </Menu.Item>
        </Menu>
    );
    return <>
        <ContratoModal visible={isModalVisible} id={CotratoSelected} body={modalBody} onClose={CloseModal} />
        <div style={{display: "flex", justifyContent: "right", padding: "10px"}}>
            <Tooltip title="Adicionar novo orcamento">
                <Button onClick={() => 1}
                        type="default"
                        style={{ backgroundColor: "#85a5ff", color: "white" }}
                        size={"large"}  icon={<SyncOutlined />}>
                    Todos Contratos Pendentes
                </Button>
            </Tooltip>
        </div>
        <Table dataSource={data}>
            <Column title="Clinica" dataIndex="clinica" key="clinica" />
            <Column title="Nro. Contrato" dataIndex="n_contrato" key="n_contrato" />
            <Column title="Versão" dataIndex="varsao" key="varsao" />
            <Column title="Nome Paciente" dataIndex="paciente" key="paciente" />
            <Column title="CPF" dataIndex="cpf" key="cpf" />
            <Column title="Data do Contrato" dataIndex="data" key="data" />
            <Column title="Valor Final" dataIndex="valor_final" key="valor_final" />
            <Column title="Status" dataIndex="status" key="status" />
            <Column title="" dataIndex="pr" key="pr" />
            <Column
                title=""
                dataIndex="n_contrato"
                key="n_contrato"
                render={row => (
                    <>
                        <Dropdown.Button onClick={() => OpenModal(row, 1)} overlay={menu.bind(null, row)}>
                            <EyeOutlined />
                        </Dropdown.Button>
                    </>
                )}
            />
        </Table>
    </>
}
export default OracamentoList
