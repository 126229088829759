/* eslint-disable react-hooks/rules-of-hooks */
import "./index.scss";

import TabPane from "@ant-design/pro-card/es/components/TabPane";
import ProForm, { ProFormSelect } from "@ant-design/pro-form";
import { Button, Modal, Tabs } from "antd";
import React, { useState } from "react";

import ModalNovoPaganate from "./ModalNovoPagante";

interface ModalPagante {
  visible: boolean;
  pagante: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const emissaoRaioX: React.FC<ModalPagante> = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [novoPagante, setNovoPagante] = useState(false);

  async function OpenModalNovoPagante(row: any) {
    if (row) {
      setNovoPagante(row);
    } else {
      setNovoPagante(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
      <Modal
        title={"Pagante"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
         {novoPagante && (
            <ModalNovoPaganate
              visible={isModalVisible}
              novoPagante={novoPagante}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
            />
          )}
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Selecione o Pagante" key="1">
          <ProForm.Group>
          <Button type="primary">
              Pagante
            </Button>
            <Button type="primary" onClick={OpenModalNovoPagante}>
              Novo Pagante
            </Button>
            <ProFormSelect 
              name="pagante"
              placeholder="Selecione um Pagante Cadastrado"
              options={[
                {
                  value: "ROBSON ASSIS",
                  label: "ROBSON ASSIS",
                },
              ]}
            />
          </ProForm.Group>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default emissaoRaioX;
