import api from "../api";
import {
  AgendaCompromissoModel,
  AgendaModel,
  DentistaModel,
  EspecialidadeModel,
  HorarioModel,
  PacienteModel,
} from "../models";
import { AgendaUsuarioModel } from "../models/AgendaUsuario";
import { AgendaUsuarioCompromissoModel } from "../models/AgendaUsuarioCompromisso";
import {
  AgendaUsuarioHorarioLiberadoModel,
  AgendaUsuarioHorarioModel,
} from "../models/AgendaUsuarioHorario";
import { OrcamentosModel } from "../models/Orcamentos";
import { UsuarioModel } from "../models/Usuario";
import {
  PaginatedRequest,
  PaginatedResponse,
  SingleResponse,
} from "../utilities";

export function getAll(
  options: {
    comEspecialidade?: number;
    dataFinal?: string;
    dataInicial?: string;
    idAgendaTipo?: number[];
    idCaptacaoPaciente?: number;
    idClinica?: number;
    idDentista?: number[];
    idPaciente?: number;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaModel>>("agenda/agendamentos", {
    params: options,
  });
}

export function getAllAgendamentosUsuarios(
  options: {
    dataFinal?: string;
    dataInicial?: string;
    idClinica?: number;
    idUsuario?: number[];
    idPaciente?: number;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaUsuarioModel>>(
    "agenda/agenda-lista-agendamentos",
    {
      params: options,
    }
  );
}

export function getAgenda(options: { idAgenda: number }) {
  return api.get<AgendaModel>(`agenda/agendamentos/${options.idAgenda}`);
}

export function getCompromissos(
  options: {
    dataFinal?: string;
    dataInicio?: string;
    idClinica?: number;
    idClinicaDentista?: number[];
    idDentista?: number[];
  } = {}
) {
  return api.get<PaginatedResponse<AgendaCompromissoModel>>(
    "agenda/compromissos",
    {
      params: options,
    }
  );
}

export function getAgendaUsuarioCompromissos(
  options: {
    dataFinal?: string;
    dataInicio?: string;
    idClinica?: number;
    idUsuario?: number[];
  } = {}
) {
  return api.get<PaginatedResponse<AgendaUsuarioCompromissoModel>>(
    "agenda/agenda-lista-compromisso",
    {
      params: options,
    }
  );
}

export function getTableUsuarioCompromissos(
  options: {
    params?: PaginatedRequest<{
      dataFinal?: string;
      dataInicio?: string;
      idClinica?: number;
      idUsuario?: number[];
      idCompromisso?: number;
      idAgendaCompromisso?: number;
    }>;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaUsuarioCompromissoModel>>(
    "agenda/agenda-lista-usuario-compromisso",
    options
  );
}

export function getDentistas(
  options: {
    idClinica?: number;
  } = {}
) {
  return api.get<PaginatedResponse<DentistaModel>>("autocomplete/dentista", {
    params: options,
  });
}

export function getListOrcamentos(
  options: {
    idPaciente?: number;
  } = {}
) {
  return api.get<PaginatedResponse<OrcamentosModel>>("agenda/orcamentos", {
    params: options,
  });
}

export function getUsuarios(options: { idClinica: number; tipo: number }) {
  return api.get<PaginatedResponse<UsuarioModel>>(
    `agenda/agenda-usuario-lista/${options.idClinica}/${options.tipo}`
  );
}

export function getHorarios(
  options: {
    idClinica?: number;
    dataInicial?: string;
    dataFinal?: string;
    idDentista?: number[];
  } = {}
) {
  return api.get<PaginatedResponse<HorarioModel>>("agenda/horarios", {
    params: options,
  });
}
export function getListaHorarios(
  options: {
    params?: PaginatedRequest<{
      idClinica?: number;
      dataInicial?: string;
      dataFinal?: string;
      idUsuario?: number[];
      tipo?: "clinica" | "dentista" | "global";
    }>;
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaUsuarioHorarioModel>>(
    "agenda/agenda-usuarios-lista-horarios",
    options
  );
}
export function getAgendaHorarios(options: {
  idClinica: number;
  dataInicial: string;
  dataFinal: string;
  idUsuario: number[];
  signal?: AbortSignal;
}) {
  return api.get<PaginatedResponse<AgendaUsuarioHorarioModel>>(
    `agenda/agenda-lista-horario/${options.idClinica}/${options.dataInicial}/${options.dataFinal}/${options.idUsuario}`,
    {
      params: options,
    }
  );
}

export function addHorarioLiberado(options: {
  idUsuario: number;
  inicioPrimeiroTurno: string;
  finalPrimeiroTurno: string;
  inicioSegundoTurno?: string;
  finalSegundoTurno?: string;
  dataAbertura: string;
  dataFechamento: string;
}) {
  return api.get<PaginatedResponse<AgendaUsuarioHorarioModel>>(
    `agenda/agenda-adiciona-horario`,
    {
      params: options,
    }
  );
}

export function getAgendaHorariosLiberados(
  options: {
    idClinica?: number;
    data?: string;
    idUsuario?: number[];
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaUsuarioHorarioLiberadoModel>>(
    `agenda/agenda-lista-horario-liberado/`,
    {
      params: options,
    }
  );
}

export function apiAgendaDeletaHorario(options: {
  idAgendaUsuarioHorario: number;
  signal?: AbortSignal;
}) {
  return api.post(`agenda/agenda-delete-horario`, options);
}

export function buscaEspecialidades(
  options: {
    params?: {
      dia?: string;
      idClinica?: number;
      idDentista?: number;
      incluirIdEspecialidade?: number[];
    };
    signal?: AbortSignal;
  } = {}
) {
  return api.post<EspecialidadeModel[]>("agenda/busca-especialidades", options);
}

export function buscaHorario(
  options: {
    dia?: string;
    duracao?: number;
    idClinica?: number;
    idDentista?: number;
    signal?: AbortSignal;
  } = {}
) {
  return api.get<any[]>("agenda/busca-horario", {
    params: options,
    signal: options.signal,
  });
}

export function addObservacao(options: {
  idAgenda: number;
  obsMotivo: string;
}) {
  return api.patch<any>(
    `agenda/agendamentos/${options.idAgenda}/addObservacao`,
    {
      obsMotivo: options.obsMotivo,
    }
  );
}

export function addObservacaoAgendaUsuario(options: {
  idAgendaUsuario: number;
  obsMotivo: string;
}) {
  return api.get<any>(`agenda/agenda-usuario-addobservacao`, {
    params: options,
  });
}

export function editAgendaUsuarioStatus(
  data: {
    idAgendaUsuario: number;
    obsMotivo: string;
    status: number;
  },
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.post<AgendaModel>(
    `agenda/agenda-usuario-edit-status`,
    data,
    options
  );
}

export function listAgendaUsuarioStatus(
  data: {
    tipo: number;
  },
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.post<any>(`agenda/agenda-usuario-lista-status`, data, options);
}

export function setEnvioRaioX(options: {
  idAgenda: number;
  enviado: boolean;
  pago: boolean;
}) {
  return api.patch<any>(
    `agenda/agendamentos/${options.idAgenda}/setEnvioRaioX`,
    {
      enviado: options.enviado ? 1 : 0,
      pago: options.pago ? 1 : 0,
    }
  );
}

export function setStatus(options: {
  idAgenda: number;
  status: string;
  idAgendaMotivo?: number;
  obsMotivo?: string;
}) {
  return api.patch<any>(
    `agenda/agendamentos/${options.idAgenda}/setStatus/${options.status}`,
    {
      idAgendaMotivo: options.idAgendaMotivo,
      obsMotivo: options.obsMotivo,
    }
  );
}

export function novoAgendamento(
  options: {
    data?: any;
    signal?: AbortSignal;
  } = {}
) {
  return api.post<AgendaModel>("agenda/agendamentos", options.data, options);
}

export function novoAgendamentoUsuario(
  options: {
    data?: any;
    signal?: AbortSignal;
  } = {}
) {
  return api.post<AgendaModel>(
    "agenda/agenda-usuario-adicionar",
    options.data,
    options
  );
}

export function novoUsuarioCompromisso(
  data: {
    dataInicio: string;
    dataFinal: string;
    horaInicio: string;
    horaFim: string;
    idUsuario: number;
    idCompromisso: number;
    idClinica: number;
  },
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.post<AgendaUsuarioCompromissoModel>(
    "agenda/agenda-adiciona-compromisso",
    data,
    options
  );
}

export function excluirCompromisso(
  idAgendaCompromisso: number,
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.delete<SingleResponse<AgendaUsuarioCompromissoModel>>(
    `agenda/agenda-compromisso/${idAgendaCompromisso}`,
    options
  );
}

export function updateAgendamento(
  idAgenda: number,
  data: {
    data: string;
    horaInicio: string;
    horaFim: string;
    idEspecialidade: string;
  },
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.patch<AgendaModel>(
    `agenda/agendamentos/${idAgenda}`,
    data,
    options
  );
}
export function updateAgendamentoUsuario(
  data: {
    idAgendaUsuario: number;
    data: string;
    horaInicio: string;
    horaFim: string;
  },
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.post<AgendaModel>(
    "agenda/agenda-usuario-adicionar",
    data,
    options
  );
}

export function remanejarPacientes(
  data: {
    idClinica: number;
    idDentistaOrigem: number;
    idDentistaDestino: number;
    dataInicial: string;
    dataFinal: string;
    observacao?: string;
  },
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.post<SingleResponse<{ total: number }>>(
    "agenda/agendamentos/remanejar-pacientes",
    data,
    options
  );
}

export function validarPaciente(
  params: {
    idPaciente: number;
  },
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.get<
    SingleResponse<{
      paciente: PacienteModel;
      permiteAgendamentoAvaliacao: boolean;
      permiteAgendamentoPlanejamento: boolean;
      permiteAgendamentoProducao: boolean;
      possuiInadimplencia: boolean;
      possuiContratoPrevisto: boolean;
      limiteConsultasAtingido: boolean;
      qtdeAgendasFuturas: number;
      idAgendaTipoPermitido: number[];
      mensagens: {
        tipo: "secondary" | "success" | "warning" | "danger";
        mensagem: string;
      }[];
    }>
  >("agenda/validar-paciente", {
    params,
    ...options,
  });
}
