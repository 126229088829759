import { ProFormTreeSelect } from '@ant-design/pro-form';
import axios from "axios";
import { useState } from "react";

import { useClinica } from "../contexts";
import api from "../services/api";

function StatusPagamento(props: {name: string}){

    const { clinicaAtual }                      = useClinica();
    const [statusPagamento, setStatusPagamento] = useState<[]>([]);

    const listaStatusPagamento = async () => {
        
        if(statusPagamento.length)
            return statusPagamento;

        let lista: any = [];

        try {
            const { data } = await api.get("financeiro/list-status-pagamento", { params: { idClinica: clinicaAtual.id } });
            
            data.map(function(result: any, index: number) {
                lista[lista.length] = { 
                    value: result.idStatusPagamento,
                    title: result.statusPagamento+' / '+result.siglaStatusPagamento,
                }; 
                
            });
            setStatusPagamento(lista);
            return lista;


        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
    };

    return (

        <ProFormTreeSelect
            request={listaStatusPagamento}
            name={props.name}
            fieldProps={{
                showArrow: true,
                filterTreeNode: true,
                showSearch: true,
                dropdownMatchSelectWidth: true,
                labelInValue: true,
                autoClearSearchValue: true,
                multiple: true,
                treeNodeFilterProp: 'title',
                fieldNames: {
                    label: 'title',
                },
            }}
        />
            
    )    

}
export default StatusPagamento;