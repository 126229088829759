import { AutoComplete } from 'antd';
import { SelectProps } from 'antd/es/select';
import React, { useState } from 'react';

function getRandomInt(max: number, min: number = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
}

const searchResult = (query: string) =>
    new Array(getRandomInt(5))
        .join('.')
        .split('.')
        .map((_, idx) => {
            const category = `${query}${idx}`;
            return {
                value: category,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
            <span>
              Found {query} on{' '}
                <a
                    href={`https://s.taobao.com/search?q=${query}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                {category}
              </a>
            </span>
                        <span>{getRandomInt(200, 100)} results</span>
                    </div>
                ),
            };
        });

const OrcamentoNovo: React.FC = () => {
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);

    const handleSearch = (value: string) => {
        setOptions(value ? searchResult(value) : []);
    };

    const onSelect = (value: string) => {
        console.log('onSelect', value);
    };

    function onChange(value: any) {
        console.log(`selected ${value}`);
    }

    function onSearch(val: any) {
        console.log('search:', val);
    }

    return (
        <div className="filters" style={{backgroundColor: "white"}}>
            <label>
                Paciente/Prontuário:

                <AutoComplete
                    showSearch={false}
                    dropdownMatchSelectWidth={252}
                    style={{ width: 300 }}
                    options={options}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                >
                </AutoComplete>
            </label>
            <label>
                CPF:
                <AutoComplete
                    showSearch={false}
                    style={{ width: 300 }}
                >
                </AutoComplete>
            </label>
        </div>
    );
};

export default OrcamentoNovo