import ProForm from "@ant-design/pro-form";
import { Button } from "antd";
import React, { useState } from "react";

import ModalDestinacoes from "./ModalDestinacoes";

const Gerenciar: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [destinacoes, setDestinacoes] = useState(false);

  async function OpenModal(row: any) {
    if (row) {
      setDestinacoes(row);
    } else {
      setDestinacoes(false);
    }
    setIsModalVisible(true);
  }
  return (
    <>
      {destinacoes && (
        <ModalDestinacoes
          visible={isModalVisible}
          destinacoes={destinacoes}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      <ProForm submitter={false}>
      <div style={{lineHeight: "50px"}}>
        
        <ProForm.Group>
            Dinheiro:
            <Button type="primary" onClick={OpenModal}>
              
              Destinações
            </Button>
          </ProForm.Group>
          <ProForm.Group>
            Cartão de Credito:
            <Button type="primary" onClick={OpenModal}>
              
              Destinações
            </Button>
          </ProForm.Group>
          <ProForm.Group>
            Cheque:
            <Button type="primary" onClick={OpenModal}>
              
              Destinações
            </Button>
          </ProForm.Group>
          <ProForm.Group>
            Boleto Bancário:
            <Button type="primary" onClick={OpenModal}>
              Destinações
            </Button>
          </ProForm.Group>
          <ProForm.Group>
            Cartão de Debito:
            <Button type="primary" onClick={OpenModal}>
              Destinações
            </Button>
          </ProForm.Group>
          <ProForm.Group>
            TED / DOC:
            <Button type="primary" onClick={OpenModal}>
              Destinações
            </Button>
          </ProForm.Group>
          <ProForm.Group>
            Pix:
            <Button type="primary" onClick={OpenModal}>
              Destinações
            </Button>
          </ProForm.Group>
        </div>
      </ProForm>
    </>
  );
};

export default Gerenciar;
