import { Select, Skeleton } from "antd";
import { RefSelectProps, SelectProps } from "antd/es/select";
import { SkeletonInputProps } from "antd/es/skeleton/Input";
import React, { useEffect, useState } from "react";

import { apiAutoComplete } from "../services/apis";
import { ClinicaModel } from "../services/models";

type SelectClinicaValueType = number;
type SelectClinicaOptionType = ClinicaModel;

export interface SelectClinicaProps
  extends SelectProps<
    SelectClinicaValueType | SelectClinicaValueType[],
    SelectClinicaOptionType
  > {
  loadingProps?: SkeletonInputProps;
}

/**
 * Cache interno para evitar rechamadas em todos locais que usam esse componente
 */
let CACHE: ClinicaModel[] | null = null;

export const SelectClinica = React.forwardRef<
  RefSelectProps,
  SelectClinicaProps
>((props, ref) => {
  const { loadingProps, ...rest } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [clinicas, setClinicas] = useState<ClinicaModel[]>([]);

  /**
   * Inicializa a lista de clínicas
   */
  useEffect(() => {
    /**
     * Caso tiver cache, use o cache para definir a lista
     */
    if (CACHE) {
      setClinicas(CACHE);
      return;
    }

    setIsLoading(true);

    apiAutoComplete
      .clinicas()
      .then((response) => {
        const { data } = response.data;
        CACHE = data;
        setClinicas(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Skeleton.Input {...loadingProps} />;
  }

  return (
    <Select<
      SelectClinicaValueType | SelectClinicaValueType[],
      SelectClinicaOptionType
    >
      showSearch
      dropdownMatchSelectWidth={300}
      optionFilterProp={"text"}
      options={clinicas.map((c) => ({
        ...c,
        value: c.idClinica,
        text: `${c.nomeClinica} - ${c.estado}`,
        label: (
          <>
            {c.nomeClinica} - {c.estado}
          </>
        ),
      }))}
      status={error ? "error" : undefined}
      {...rest}
      style={{
        minWidth: "100px",
        ...rest.style,
      }}
      ref={ref}
    />
  );
});
