import "./index.scss";

import { ProFormUploadButton } from "@ant-design/pro-form";
import { Modal, Tabs } from "antd";
import React from "react";

const { TabPane } = Tabs;

interface ModalPagoImg {
  visible: boolean;
  imagem: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const emissaoRaioX: React.FC<ModalPagoImg> = (props) => {
  return (
    <>
      <Modal
        title={"Imagens de Raio-X"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Paciente" key="1">
            <ProFormUploadButton
              label="CPF do Paciente"
              name="file"
              title="Adicionar Nova Imagens"
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default emissaoRaioX;
