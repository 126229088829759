import { ProFormInstance } from "@ant-design/pro-form";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Modal } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import api from "../../services/api";
import {
  AhistoricoParcela,
  ModalHistoricoParcela,
} from "./types";

const ModalParcela: React.FC<ModalHistoricoParcela> = (props) => {
  const [load, setLoad] = useState(false);
  const [parcela, setHistorico] = useState<AhistoricoParcela[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formRef = useRef<ProFormInstance<{}>>();

  useEffect(() => {
    const VM = props.visible;

    if (VM == true) {
      if (props.historicoParcela) {
        GetHistoricoParcela();
      }
    } 
  });


  const GetHistoricoParcela = async () => {
    try {
      const { data } = await api.get(`financeiro/historico-parcela/${props.historicoParcela}`);
      setHistorico(data.data);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };


  const HistoricoParcela: ProColumns<AhistoricoParcela>[] = [
    {
      title: "Vencimento Parcela",
      dataIndex: "dataVencimento",
    },
    {
      title: "Data Baixa",
      dataIndex: "dataBaixa",
    },
    {
      title: "Status Parcela",
      dataIndex: "nomeParcelaStatus",
    },
    {
      title: "Usuario Recebeu",
      dataIndex: "usuarioRecebeu",
    },
    {
      title: "Data Recebeu", 
      dataIndex: "dataHoraRecebeu",
    },
    {
      title: "Usuario Alteração",
      dataIndex: "usuarioAlteracao",
    },
    {
      title: "Data Alteração",
      dataIndex: "dataHoraAlterou",
    },
    {
      title: "Status Financeiro",
      dataIndex: "nomeStatusFinanceiro",
    },
  ];

  return (
    <>
      <Modal
        title="Historio Status Parcela"
        open={props.visible}
        onCancel={async (e) => {
          formRef.current?.resetFields();
          props.onCancel();
          setConfirmLoading(false);
        }}
        onOk={async (e) => {
          formRef.current?.submit();
        }}
        width="60%"
        style={{ maxWidth: "1280px" }}
        footer={false}
        confirmLoading={confirmLoading}
      >
        <ProTable<AhistoricoParcela>
          columns={HistoricoParcela}
          rowKey="key"
          options={false}
          search={false}
          dateFormatter="string"
          loading={load}
          dataSource={parcela}
        ></ProTable>
      </Modal>
    </>
  );
};

export default ModalParcela;
