import ProForm, { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Modal } from "antd";
import React from "react";

interface ModalAddLab {
  visible: boolean;
  addLab: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const Gerenciar: React.FC<ModalAddLab> = (props) => {
  return (
    <>
      <Modal
        title={"Adicionar Laboratorio"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
     <ProForm submitter={false}>
        <ProForm.Group>
          <ProFormText name="nome" label="Nome laboratorio"></ProFormText>
          <ProFormSelect
            options={[
              {
                value: "1",
                label: "Externo",
              },
              {
                value: "2",
                label: "Interno",
              },
            ]}
            name="lab"
            label="Laboratorio"
            width="sm"
          ></ProFormSelect>
          <ProFormText name="nome" label="Documento" width="sm"></ProFormText>

        </ProForm.Group>
      </ProForm>
      </Modal>
    </>
  );
};

export default Gerenciar;
