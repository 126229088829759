import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Col, Empty,Modal, Row, Tabs } from "antd";
import Text from "antd/es/typography/Text";
import React, { useEffect, useState } from "react";

import OdontogramaLab from "./OdontogramaLab";
import { ServicosSolicitados } from "./types";

const { TabPane } = Tabs;

interface ModalVisualizarOS {
  visible: boolean;
  visualizarOS: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const OrdemServico: React.FC<ModalVisualizarOS> = (props) => {
  const [dados, setDados] = useState<ServicosSolicitados[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: ServicosSolicitados[] = [];

    for (let i = 0; i < 1; i += 1) {
      tableList.push({
        setor: "Resina",
        servico: "MONTAGEM DE DENTES SOBRE BARRA",
        dente: "Arcada Superior",
      });
    }
    setDados(tableList);
  }, []);

  const columns: ProColumns<ServicosSolicitados>[] = [
    {
      title: "Setor",
      dataIndex: "setor",
    },
    {
      title: "Serviço",
      dataIndex: "servico",
    },
    {
      title: "Dente(s)",
      dataIndex: "dente",
    },
  ];

  const [materiais] = useState<any | null>({
    Moldagem: "2",
  });
  return (
    <>
      <Modal
        title={"Visualização do Pedido"}
        width={"60%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
        okText="Imprimir"
        cancelText="Fechar"
      >
        <img src="https://hmldh.oralsin.com.br/img/logo.png" alt="" />
        <div className="dadosClinica">
          <span>Av Bandeirantes 777</span>
          <br />
          <span>Londrina / PR</span>
          <br />
          Tel:&nbsp;<span>(43) 3325-3003</span>
          <br />
        </div>
        <div className="titlePage">
          <span>Ordens de Serviço</span>
        </div>
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Dados do Paciente" key="1">
            <div style={{ width: "100%" }}>
              <span id="nomeDentista" style={{ float: "left", width: "75%" }}>
                <b>Dr.(a):&nbsp;&nbsp;</b>Ariadne de Gaspari Maziero
              </span>
              <span id="telDentista" style={{ float: "right", width: "auto" }}>
                <b>Tel:&nbsp;&nbsp;</b>(43) 9698-5098
              </span>
            </div>
            <div style={{ width: "100%" }}>
              <span id="paciente" style={{ float: "left", width: "75%" }}>
                <b>PACIENTE:&nbsp;&nbsp;</b>ROSA HYLARIO TANGERINO DOS SANTOS
              </span>
              <span id="idade" style={{ float: "right", width: "auto" }}>
                <b>Idade:&nbsp;&nbsp;</b>54 anos
              </span>
              <span id="sexo" style={{ float: "right", width: "auto" }}>
                <b>Sexo:&nbsp;&nbsp;</b>F&nbsp;&nbsp;
              </span>
            </div>
            <div style={{ width: "70%" }}>
              <span id="prontuario" style={{ float: "left", width: "75%" }}>
                <b>Prontuário:&nbsp;&nbsp;</b>BAN-0002032-BAN
              </span>
            </div>
          </TabPane>
        </Tabs>
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Detalhes da OS" key="2">
            <span id="servico" style={{ float: "left", width: "75%" }}>
              <b>O serviço deve retornar:&nbsp;&nbsp;</b>Para Prova
            </span>
            <span id="urgencia" style={{ float: "left", width: "75%" }}>
              <b>Urgência:&nbsp;&nbsp;</b>Sim
            </span>
            <span id="cargaImediata" style={{ float: "left", width: "75%" }}>
              <b>Carga Imediata:&nbsp;&nbsp;</b>Não
            </span>
            <span id="obs" style={{ float: "left", width: "75%" }}>
              <b>Observações:&nbsp;&nbsp;</b>fazer ajustes que expliquei no
              whatsApp
            </span>
          </TabPane>
        </Tabs>
        <Tabs defaultActiveKey="1" size="middle" centered>
          <TabPane tab="Serviços Solicitados" key="3">
            <OdontogramaLab />
            <ProTable<ServicosSolicitados>
              columns={columns}
              request={(params, sorter, filter) => {
                return Promise.resolve({
                  data: tableList,
                  success: true,
                });
              }}
              rowKey="key"
              pagination={false}
              search={false}
              dateFormatter="string"
            />
          </TabPane>
        </Tabs>
        <Tabs defaultActiveKey="1" size="middle" centered>
          <TabPane tab="Materiais" key="3">
            {materiais ? (
              <div className="materiais">
                <Row>
                  <Col span={17} push={7}>
                    <Text type="secondary">{materiais.Moldagem}</Text>
                  </Col>
                  <Col span={7} pull={17}>
                    <Text>Moldagem:</Text>
                  </Col>
                </Row>
              </div>
            ) : (
              <Empty />
            )}
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default OrdemServico;
