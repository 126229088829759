import "./index.scss";

import React, { useState } from "react";

const ControlePedido: React.FC = () => {
  const [activeTeethClass] = useState<any>([]);

  function getDenteUrl(dente: number, active: boolean = false) {
    if (active) {
      return `https://hmldh.oralsin.com.br/img/dentes_numerados/green_${dente}.png`;
    } else {
      return `https://hmldh.oralsin.com.br/img/dentes_numerados/gray_${dente}.png`;
    }
  }

  function getTeethClass(dente: number) {
    if (activeTeethClass.indexOf(dente) !== -1) {
      return "active";
    }
    return void 1;
  }

  const denteImg = (dente: number) => (
    <img
      key={dente}
      className={getTeethClass(dente)}
      src={getDenteUrl(dente)}
      id={dente.toString()}
      alt={dente.toString()}
    />
  );

  return (
    <>
      <div className="arcadas">
        <div className={`dentes`}>
          <div className="superior linha">
            <div className="bloco bloco-1">
              {[18, 17, 16, 15, 14, 13, 12, 11].map(denteImg)}
            </div>
            <div className="bloco bloco-2">
              {[21, 22, 23, 24, 25, 26, 27, 28].map(denteImg)}
            </div>
          </div>
          <div className="inferior linha">
            <div className="bloco bloco-1">
              {[48, 47, 46, 45, 44, 43, 42, 41].map(denteImg)}
            </div>
            <div className="bloco bloco-2">
              {[31, 32, 33, 34, 35, 36, 37, 38].map(denteImg)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ControlePedido;
