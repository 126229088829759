import "./fontawesome";
import "./styles/index.scss";

import { ConfigProvider } from "antd";
import antd_ptBr from "antd/locale/pt_BR";
import dayjs from "dayjs";
import dayjs_ptBr from "dayjs/locale/pt-br";
import dayjs_customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs_isBetween from "dayjs/plugin/isBetween";
import dayjs_isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjs_isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjs_localeData from "dayjs/plugin/localeData";
import dayjs_localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs_minMax from "dayjs/plugin/minMax";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ServiceWorkerUpdater from "./components/ServiceWorkerUpdater";
import { AuthProvider, ClinicaProvider } from "./contexts";
import reportWebVitals from "./reportWebVitals";

dayjs.locale(dayjs_ptBr);
dayjs.extend(dayjs_customParseFormat);
dayjs.extend(dayjs_customParseFormat);
dayjs.extend(dayjs_isBetween);
dayjs.extend(dayjs_isSameOrAfter);
dayjs.extend(dayjs_isSameOrBefore);
dayjs.extend(dayjs_localeData);
dayjs.extend(dayjs_localizedFormat);
dayjs.extend(dayjs_minMax);

function camelize(str: string) {
  return str
    .replace(/_+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

const StrictMode: React.FC<React.PropsWithChildren> = ({ children }) => {
  if (process.env.REACT_APP_STRICT === "true") {
    return <React.StrictMode>{children}</React.StrictMode>;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

let basename = process.env.PUBLIC_URL || "";

if (/^https?:/.test(basename)) {
  const url = new URL(basename);
  basename = url.pathname;
}

const env = process.env;

// Remove as cores que não tem valor, deixando apenas as cores com valores
const colors = Object.keys(env).reduce((obj, key) => {
  const part = key.replace("REACT_APP_STYLE_", "");

  if (part === key || !env[key]) {
    return obj;
  }

  const name = camelize(part.toLocaleLowerCase());

  obj[name] = env[key]!;

  return obj;
}, {} as { [key: string]: string });

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <BrowserRouter basename={basename.replace(/\/$/, "")}>
      <ClinicaProvider>
        <AuthProvider>
          <ConfigProvider
            locale={antd_ptBr}
            theme={{
              token: {
                ...colors,
              },
            }}
          >
            <ServiceWorkerUpdater />
            <App />
          </ConfigProvider>
        </AuthProvider>
      </ClinicaProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
