import { CloudDownloadOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";

import * as serviceWorkerRegistration from "../serviceWorkerRegistration";

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    // serviceWorkerRegistration.unregister();
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const checkIsReady = () => {
    if (waitingWorker?.state === "activated") {
      setIsLoading(false);
      setShowReload(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    waitingWorker?.addEventListener("statechange", () => {
      checkIsReady();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingWorker]);

  const reloadPage = () => {
    setIsLoading(true);
    checkIsReady();

    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
  };

  if (!showReload) {
    return null;
  }

  return (
    <PageHeader
      onBack={reloadPage}
      backIcon={<CloudDownloadOutlined />}
      className="site-page-header"
      title="Atualização disponível"
      subTitle="Finalize a atividade atual e clique em atualizar"
      style={{ zIndex: 1 }}
      extra={[
        <Button
          key="update"
          type="primary"
          onClick={reloadPage}
          loading={isLoading}
        >
          Atualizar
        </Button>,
      ]}
    />
  );
};

export default ServiceWorkerWrapper;
