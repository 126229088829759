import {Cascader, DatePicker, InputNumber, Select} from "antd";
import React from "react";

import {paymentsOptions} from "../types"

const FinaceiroParcela: React.FC = () => {
    function onChange(value: any) {
        console.log(value);
    }
    return <>
        <tr>
            <td>
                <Cascader
                    options={paymentsOptions}
                    onChange={onChange}
                    placeholder="Selecione" />
            </td>
            <td>
                <InputNumber
                    style={{width: 120}}
                    defaultValue={1000}
                    formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value:any) => value.replace(/\R$\s?|(,*)/g, '')}
                    onChange={ () => {} }
                />
            </td>
            <td>
                <Select defaultValue="1" style={{ width: 120 }} onChange={ () => {} }>
                    <Select.Option value="1">1x Sem jurus</Select.Option>
                    <Select.Option value="2">2x Sem jurus</Select.Option>
                    <Select.Option value="3">3x Sem jurus</Select.Option>
                    <Select.Option value="4">4x Sem jurus</Select.Option>
                </Select>
            </td>
            <td>
                <DatePicker onChange={ () => {} } />
            </td>
            <td>
                <p>
                    R$ 181,53
                </p>
            </td>
        </tr>
    </>
}

export default FinaceiroParcela