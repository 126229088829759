import { ProFormSelect } from '@ant-design/pro-form';

import { useClinica } from "../../../contexts";
import api from "../../../services/api";

function SelectForncedor(props: any){

    const {clinicaAtual} = useClinica();

    return (

        <ProFormSelect
            name="idFornecedor"
            {...props}
            showSearch
            request={async ({ keyWords = '' }) => {
                const { data } = await api.get("autocomplete/fornecedor", { params: { searchText: keyWords, idClinica: clinicaAtual.id } });
                let res: any = [];       
                data.data.map(function (result: any, index: number) {
                    res[res.length] = { label: result.nomeFornecedor, value: result.idFornecedor };
                });
                return res;
              }}
        />
            
    );

}
export default SelectForncedor;