import { ArrowDownOutlined, CheckOutlined, DeleteOutlined, EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { PageContainer } from "@ant-design/pro-layout";
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import { Card, Dropdown, Menu, Popconfirm } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from 'react';

import { useClinica } from "../../contexts";
import api from "../../services/api";
import FilterAPagar from './FilterAPagar';
import ModalAPagarAgendado from './ModalAPagarAgendado';
import { APagarAjax, APagarLista, Pagination } from './types';

const menuAcoes = (key: any) => {
    return (
        <Menu>
            <Menu.Item key="1" icon={<CheckOutlined />}>
                <Popconfirm
                    title     = "Confirma a baixa dessa parcela ?"
                    onConfirm = {() => {}}
                    onCancel  = {() => {}}
                    placement = "top"
                >
                    Baixar
                </Popconfirm>
            </Menu.Item>
            <Menu.Item key="1" danger icon={<DeleteOutlined />}>
                <Popconfirm
                    title     = "Esta certo disso ?"
                    onConfirm = {() => {}}
                    onCancel  = {() => {}}
                    placement = "top"
                >
                    Deletar
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )
};

const menuBaixado = () => {
    return (
        <Menu>
            <Menu.Item key="1" icon={<ArrowDownOutlined />}>
                Baixado
            </Menu.Item>
        </Menu>
    )
};

const columns: ProColumns<APagarLista>[] = [
    {
        dataIndex: 'key',
    },
    {
        title    : 'ID',
        dataIndex: 'idContaPagar',
    },
    {
        title    : 'Doc.',
        dataIndex: 'documento',
    },
    {
        title    : 'Data Vencimento',
        dataIndex: 'dataVencimento',
        valueType: 'date',
    },
    {
        title    : 'Data Pagamento',
        dataIndex: 'dataPagamento',
        valueType: 'date',
    },
    {
        title    : "Banco",
        dataIndex: ['conta_capital', 'contaCapital'],
    },
    {
        title    : "Clínica",
        dataIndex: ['clinica', 'nomeClinica'],
    },
    {
        title    : 'Descrição',
        dataIndex: 'descricao',
    },
    {
        title    : 'Fornecedor',
        dataIndex: ['fornecedor', 'nomeFornecedor'],
    },
    {
        title    : 'Ações',
        valueType: 'option',
        render: (Dom, row) => [
            <Dropdown.Button onClick={() => {}} overlay={menuAcoes(row)} trigger={['click']}>
                <EditOutlined />
                Alterar
            </Dropdown.Button>
                 
        ],
    },
];

const APagar: React.FC = () => {

    const { clinicaAtual } = useClinica();

    const [load, setLoad] = useState(false);
    const [lista, setLista] = useState<APagarLista[]>([]);
    const [pagina, setPagina] = useState<Pagination>({ current: 1, total: 0, pageSize: 15 });
    const [isModalAgendadoVisible, setIsModalAgendadoVisible] = useState(false);
    const [post, setPost] = useState<[]>([]);

    
    const LoadData = async (params: [], page = 1, pageSize = 15) => {
        setLoad(true);
        
        if(!params)
            params = post;

        try {
            const { data } = await api.get<APagarAjax>("financeiro/conta-pagar", { params: { page: page, per_page: pageSize, idClinica: clinicaAtual.id, params: params } });
            setLista(data.data);
            setPagina({ ...pagina, current: page, total: data.meta.total, pageSize: pageSize });
        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
        setLoad(false);
    }
    
    useEffect(() => {
        LoadData([]);
    }, []);

    const CloseModalAgendado = () => {
        setIsModalAgendadoVisible(false);
    };

    async function OpenModalAgendado(row: any) {
        setIsModalAgendadoVisible(true);
    }

    const SetParamsSearch = (params: any) => {
        setPost(params);
        console.log(params);
        LoadData(params);
    }

    return <> <ModalAPagarAgendado visible={isModalAgendadoVisible} onCancel={CloseModalAgendado} />
    <PageContainer extra={[
        <Dropdown.Button onClick={OpenModalAgendado} overlay={menuBaixado} trigger={['click']} type="primary">
            <FileAddOutlined />
            Agendado
        </Dropdown.Button>
    ]}>
        <Card>
            <FilterAPagar loadPage={SetParamsSearch}></FilterAPagar>
        </Card>
        <ProTable<APagarLista>
            columns       = {columns}
            rowKey        = "idContaPagar"
            search        = {false}
            dateFormatter = "string"
            loading       = {load}
            dataSource    = {lista}
            pagination    = {{
                current: pagina.current, total: pagina.total, pageSize: pagina.pageSize, onChange: (page, pageSize) => {
                    LoadData(post, page, pageSize);
                }
            }}
        />
    </PageContainer>
    </>;
};

export default APagar;