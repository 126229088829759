import TabPane from "@ant-design/pro-card/es/components/TabPane";
import { Card, Col, Collapse, Empty, Modal, Row, Table, Tabs } from 'antd';
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from 'react';

import { IContrato, ModalContratosProps } from './../types';


const ModalContrato: React.FC<ModalContratosProps> = (props) => {

    const [contrato, setContrato] = useState<IContrato | null>(null);

    function initializeContract(){
        if(props.contratoId){

            const newContrato: IContrato = {

                contrato_id: props.contratoId,
                paciente: "Aarao Lyra",
                prontuario: "NAT-0000858-NAT",
                versoes:[]
            }

            for (let v = 0; v < 2; v += 1){
                newContrato.versoes.push({
                    versao: v+1,
                    parcelas: [],
                    procedimentos: [],
                    final_value: "5.000,00",
                    status: "Finalizado",
                    avaliacao: "Dr Afonso",
                    financeiro: "Dayanne Sudimilla Celestino",
                    criado_em: "21/01/2019",
                    faturado_em: "21/01/2019",
                    aprovado_por: "Edson Marostega",
                    codicoes_especiais: true,
                    observacao_especial: "Informado por DAYANNE SUDIMILLA CELESTINO em 21/01/2019 às 15:35:15: 16,53% DESC PROF E EXO",
                    status_especial: "Condição Especial Aprovada",
                    aprovacao_em: "21/01/2019 15:36:09",
                    observacao_impressao: "solicitar panoramica",
                })
                for (let i = 0; i < 5; i += 1)
                {
                    newContrato.versoes[v].parcelas.push({
                        id: (444+i).toString(),
                        data_vencimento: "22/01/2019",
                        valor_parcela: "R$ 1.000,00",
                        forma_pagamento: "Cartão de Débito (Visa Electron)",
                        status: "ativo",
                        status_financeiro: "Compensado",
                        formalizado: "Sim",
                        parcela_negociacao: "Nao",
                        orcamento_anterior: v == 1 && i > 2
                    })
                    newContrato.versoes[v].procedimentos.push({
                        procedimento: "Coroa Metalo Cerâmica Sobre Dente",
                        localizacao: "Dentes: 35",
                        valor_venda: "R$ 1.001,68",
                        valor_tabela: "R$ 1.200,00",
                        status: "Executado"
                    })
                }
            }



            setContrato(newContrato)

        } else{
            setContrato(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contratoId]);
    return <Modal
        title="Histórico Alterações Contrato"
        open={props.visible}
        cancelText="Fechar"
        okText="Imprimir"
        width="60vw"
        style={{ top: 20 }}
        onCancel={async (e) => {
            props.onClose();
        }}
    >
        {contrato ?
            <div className="contrato">
                <Row>
                    <Col span={18} push={6}>
                        <Text type="secondary">
                            <strong>{contrato.contrato_id}</strong>
                            (Orçamento Ref: 277322)
                        </Text>
                    </Col>
                    <Col span={6} pull={18}>
                        <Text>Contrado:</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={18} push={6}>
                        <Text type="secondary">
                            {contrato.paciente}
                        </Text>
                    </Col>
                    <Col span={6} pull={18}>
                        <Text>Paciente:</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={18} push={6}>
                        <Text type="secondary">
                            {contrato.prontuario}
                        </Text>
                    </Col>
                    <Col span={6} pull={18}>
                        <Text>Prontuário:</Text>
                    </Col>
                </Row>
                <div style={{margin: "20px 0"}} className="versao-card-border-less-wrapper">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {contrato.versoes.map((versao) =>
                            <Col style={{margin: "10px 0"}} className="gutter-row" span={7}>
                                <Card title={"Versão " + versao.versao} bordered={false}
                                      style={{ width: 200, textAlign: "center" }}>
                                    <p>{versao.criado_em}</p>
                                    <p style={{margin: 0}}>
                                        <span>Procedimentos</span>
                                        {versao.procedimentos.length}
                                    </p>
                                    <p style={{margin: 0}}>
                                        <span>Parcelas</span>
                                        {versao.parcelas.length}
                                    </p>
                                    <p style={{margin: 0}}>
                                        <span>Valor Final</span>
                                        {versao.final_value}
                                    </p>
                                    <p style={{margin: 0}}>
                                        <span>Status</span>
                                        {versao.status}
                                    </p>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <Text type="secondary">Veja os detalhes das alterações abaixo.</Text>
                </div>
                <Tabs defaultActiveKey={(contrato.versoes.length-1).toString()} onChange={callback}>
                    { contrato.versoes.map( versao =>
                        <TabPane tab={"Versão" + versao.versao} key={versao.versao.toString()}>
                            <div className="contrato-versao-visualizar">
                                <p style={{margin: 0}}>
                                    <span>Valor Final</span>
                                    <span>{versao.final_value}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Avaliação:</span>
                                    <span>{versao.avaliacao}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Financeiro:</span>
                                    <span>{versao.financeiro}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Criado em:</span>
                                    <span>{versao.criado_em}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Status:</span>
                                    <span>{versao.status}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Faturado em:</span>
                                    <span>{versao.faturado_em}</span>
                                </p>
                                <Title level={5}>Condição Especial</Title>
                                <p style={{margin: 0}}>
                                    <span>Status Condição Especial:</span>
                                    <span>{versao.status_especial}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Observação Condição Especial:</span>
                                    <span>{versao.observacao_especial}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Aprovado por: </span>
                                    <span>{versao.aprovado_por}</span>
                                </p>
                                <p style={{margin: 0}}>
                                    <span>Aprovado em: </span>
                                    <span>{versao.aprovado_por}</span>
                                </p>
                                <Collapse className="site-collapse-custom-collapse" onChange={callback}>
                                    <Collapse.Panel className="site-collapse-custom-panel"
                                                    header="Observações Impressão" key="observacao">
                                        <p>{versao.observacao_impressao}</p>
                                    </Collapse.Panel>
                                </Collapse>
                                <Title level={5}>Procedimentos</Title>
                                <Table pagination={false} dataSource={versao.procedimentos} columns={[
                                    {
                                        title: 'Procedimento',
                                        dataIndex: 'procedimento',
                                        key: 'procedimento',
                                    },
                                    {
                                        title: 'Localização',
                                        dataIndex: 'localizacao',
                                        key: 'localizacao',
                                    },
                                    {
                                        title: 'Valor Venda',
                                        dataIndex: 'valor_venda',
                                        key: 'valor_venda',
                                    },
                                    {
                                        title: 'Valor Tabela',
                                        dataIndex: 'valor_tabela',
                                        key: 'valor_tabela',
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'status',
                                        key: 'status',
                                    }
                                ]} />

                                <Title level={5}>Parcelas</Title>
                                <Table
                                    rowClassName={(record, index) => (record.orcamento_anterior ? "bg-cyan-2" : "")}
                                    pagination={false} dataSource={versao.parcelas} columns={[
                                    {
                                        title: 'ID',
                                        dataIndex: 'id',
                                        key: 'id',
                                    },
                                    {
                                        title: 'Data Vencimento',
                                        dataIndex: 'data_vencimento',
                                        key: 'data_vencimento',
                                    },
                                    {
                                        title: 'Valor Parcela',
                                        dataIndex: 'valor_parcela',
                                        key: 'valor_parcela',
                                    },
                                    {
                                        title: 'Forma Pagamento',
                                        dataIndex: 'forma_pagamento',
                                        key: 'forma_pagamento',
                                    },
                                    {
                                        title: 'Status Financeiro',
                                        dataIndex: 'status_financeiro',
                                        key: 'status_financeiro',
                                    },
                                    {
                                        title: 'Formalizado',
                                        dataIndex: 'formalizado',
                                        key: 'formalizado',
                                    },
                                    {
                                        title: 'Parcela de Negociação',
                                        dataIndex: 'parcela_negociacao',
                                        key: 'parcela_negociacao',
                                    }
                                ]} />
                            </div>
                        </TabPane>
                    )}
                </Tabs>
            </div>
            :<Empty />}
    </Modal>;
}

export default ModalContrato;