import { ProFormSelect } from "@ant-design/pro-form";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import React, { useEffect, useState } from "react";

import { Especialidades } from "./types";

const PlanejamentoTratamento: React.FC = () => {
  const [dados, setDados] = useState<Especialidades[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: Especialidades[] = [];

    for (let i = 0; i < 1; i += 1) {
      tableList.push({
        descricao: "CARDIOLOGISTA",
        consultasNesc: "",
      });
    }
    setDados(tableList);
  }, []);

  const columns: ProColumns[] = [
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Consultas Necessárias",
      key: "consultasNesc",
      valueType: "option",
      render: () => [
        <ProFormSelect
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "2",
            },
            {
              value: "3",
              label: "3",
            },
            {
              value: "4",
              label: "4",
            },
            {
              value: "5",
              label: "5",
            },
          ]}
          name="consultasNesc"
          placeholder="Selecione..."
        ></ProFormSelect>,
      ],
    },
  ];
  return (
    <>
      <p className="title" style={{ marginBottom: 10, marginTop: 0 }}>
        ESPECIALIDADES
      </p>

      <ProTable
        columns={columns}
        request={(params, sorter, filter) => {
          return Promise.resolve({
            data: tableList,
            success: true,
          });
        }}
        rowKey="key"
        pagination={{
          showQuickJumper: true,
        }}
        search={false}
        dateFormatter="string"
      />
      <br />
    </>
  );
};

export default PlanejamentoTratamento;
