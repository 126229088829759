import { Select, Skeleton } from "antd";
import { RefSelectProps, SelectProps } from "antd/es/select";
import React, { useEffect, useState } from "react";

import { useClinica } from "../contexts";
import { apiAgenda } from "../services/apis";
import { DentistaModel } from "../services/models";

export interface RefPacienteSelectProps extends RefSelectProps {
  reset: () => void;
}

interface SelectDentistaProps<ValueType = number>
  extends SelectProps<ValueType, DentistaModel> {
  idClinica?: number;
  apenasAtivos?: boolean;
  idAgendaTipo?: number;
}

export const SelectDentista = React.forwardRef<
  RefPacienteSelectProps,
  SelectDentistaProps
>((props, ref) => {
  const { idClinica, apenasAtivos, idAgendaTipo, ...rest } = props;

  const clinica = useClinica();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [cacheDentista, setCacheDentistas] = useState<DentistaModel[]>([]);
  const [dentistas, setDentistas] = useState<DentistaModel[]>([]);

  useEffect(() => {
    setIsLoading(true);

    apiAgenda
      .getDentistas({ idClinica: idClinica || clinica.clinicaAtual.id })
      .then((response) => {
        const { data } = response.data;
        setCacheDentistas(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let lista = cacheDentista;

    if (apenasAtivos === true) {
      lista = lista.filter((d) => d.horariosAbertos > 0);
    }

    if (idAgendaTipo) {
      lista = lista.filter((d) => {
        switch (idAgendaTipo) {
          case 1:
            return d.producao;
          case 2:
            return d.planejamento;
          case 3:
            return d.avaliacao;
        }

        return true;
      });
    }

    setDentistas(lista);
  }, [cacheDentista, apenasAtivos, idAgendaTipo]);

  if (isLoading) {
    return <Skeleton.Input active block />;
  }

  return (
    <Select<number, DentistaModel>
      allowClear
      placeholder={"Digite o nome do paciente"}
      showAction={["click", "focus"]}
      options={dentistas}
      optionFilterProp={"apelidoDentista"}
      fieldNames={{
        label: "apelidoDentista",
        value: "idDentista",
      }}
      status={error ? "error" : undefined}
      {...rest}
      ref={ref}
    />
  );
});
