import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, Dropdown, Menu, Popconfirm } from "antd";
import React, { useEffect,useState } from "react";

import ModalGerenciadorClinica from "./ModalGerenciadorClinica";
import { GerenciarClinica } from "./types";

const menuAcoes = (key: any) => {
  return (
    <Menu>
      <Menu.Item key="1" danger icon={<DeleteOutlined />}>
        <Popconfirm
          title="Esta certo disso ?"
          onConfirm={() => {}}
          onCancel={() => {}}
          placement="top"
        >
          Deletar
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
};

const Gerenciar: React.FC = () => {
  const [dados, setDados] = useState<GerenciarClinica[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: GerenciarClinica[] = [];

    for (let i = 0; i < 100; i += 1) {
      tableList.push({
        key: "1",
        sigla: "LZN",
        clinica: "Londrina - Zona Norte",
        cidade: "Londrina",
        estado: "PR",
        telefone: "(43) 3325-3004",
        status: "Ativo",
      });
    }
    setDados(tableList);
  }, []);

  const columns: ProColumns<GerenciarClinica>[] = [
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: "Sigla",
      dataIndex: "sigla",
    },
    {
      title: "Clínica",
      dataIndex: "clinica",
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
    },
    {
      title: "Estado",
      dataIndex: "estado",
    },
    {
      title: "Telefone",
      dataIndex: "telefone",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Ações",
      key: "action",
      render: (dom, row) => (
        <span>
          <Dropdown.Button
            onClick={() => OpenModal(row)}
            overlay={menuAcoes(row)}
            trigger={["click"]}
          >
            <EditOutlined />
            Alterar
          </Dropdown.Button>{" "}
        </span>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clinica, setClinica] = useState(false);

  async function OpenModal(row: any) {
    if (row) {
      setClinica(row);
    } else {
      setClinica(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
      {clinica && (
        <ModalGerenciadorClinica
          visible={isModalVisible}
          clinica={clinica}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      <PageContainer
        extra={[
          <Button key="1" type="primary" onClick={OpenModal}>
            Nova Clinica
          </Button>
        ]}
      >
        <ProTable<GerenciarClinica>
          columns={columns}
          request={(params, sorter, filter) => {
            return Promise.resolve({
              data: tableList,
              success: true,
            });
          }}
          rowKey="key"
          pagination={{
            showQuickJumper: true,
          }}
          search={false}
          dateFormatter="string"
        />
      </PageContainer>
    </>
  );
};

export default Gerenciar;
