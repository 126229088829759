import ProCard from "@ant-design/pro-card";
import { Button, Popover, Steps } from "antd";
import React, { useState } from "react";

import ModalCodImprimir from "./ModalCodImprimir";
import ModalVisualizarOS from "./ModalVisualizarOS";

const { Step } = Steps;

const customDot = (dot: boolean, { status, index }: any) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);

const OrdemServico: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [VisualizarOS, setVisualizarOS] = useState(false);
  const [imprimirCod, setImprimirCod] = useState(false);

  async function OpenModal(row: any) {
    if (row) {
      setVisualizarOS(row);
    } else {
      setVisualizarOS(false);
    }
    setIsModalVisible(true);
  }

  async function OpenModalImprimir(row: any) {
    if (row) {
      setImprimirCod(row);
    } else {
      setImprimirCod(false);
    }
    setIsModalVisible(true);
  }
  return (
    <>
      <ProCard
        tabs={{
          type: "card",
        }}
      >
        <ProCard.TabPane key="0" tab="Progresso">
          <div>
            <Steps current={3} progressDot={customDot}>
              <Step title="Cadastro" description="05/01/2022 10:22" />
              <Step title="Enviado ao Apoio" description="05/01/2022 10:48" />
              <Step title="Recebido Apoio" description="05/01/2022 10:48" />
              <Step
                title="Enviado ao LAB EXTERNO"
                description="05/01/2022 10:49"
              />
              <Step title="Recebido LAB EXTERNO" description="" />
              <Step title="Enviado ao dentista" description="" />
            </Steps>
          </div>
        </ProCard.TabPane>
      </ProCard>
      <div style={{ textAlign: "center" }}>
        <Button type="primary" onClick={OpenModalImprimir}>Imprimir Código de Barras </Button>{" "}
        <Button type="primary" onClick={OpenModal}>
          Visualizar OS{" "}
        </Button>{" "}
      </div>
      {VisualizarOS && (
        <ModalVisualizarOS
          visible={isModalVisible}
          visualizarOS={VisualizarOS}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}

      {imprimirCod && (
        <ModalCodImprimir
          visible={isModalVisible}
          imprimirCod={imprimirCod}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};

export default OrdemServico;
