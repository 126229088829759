import TabPane from "@ant-design/pro-card/es/components/TabPane";
import ProForm, {
  ProFormInstance,
  ProFormSelect,
  ProFormTextArea,
} from "@ant-design/pro-form";
import { Button, message, Modal, Tabs } from "antd";
import React, { useRef } from "react";

import ControleStatusOS from "./ControleStatusOS";
import DadosOs from "./DadosOS";
import HistoricoAlteracao from "./HistoricoAlteracao";

interface ModalVisualizarControle {
  visible: boolean;
  controleOS: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

const OrdemServico: React.FC<ModalVisualizarControle> = (props) => {
  const formRef = useRef<ProFormInstance>();

  return (
    <>
      <Modal
        title={"Controle OS"}
        width={"70%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Controle de status da OS:" key="1">
            <ControleStatusOS />
          </TabPane>
        </Tabs>
        <div>
          <Tabs defaultActiveKey="1" size="middle">
            <TabPane tab="Dados" key="2">
              <DadosOs />
            </TabPane>
          </Tabs>
        </div>
        <div>
          <Tabs defaultActiveKey="1" size="middle">
            <TabPane tab="Status" key="3">
              <ProForm submitter={false}>
                <span id="statusAtual">
                  <b>Status Atual:&nbsp;&nbsp;</b>Enviado ao LAB EXTERNO em 10/01/2022
                </span>
                <ProFormSelect
                  label="Alterar Status:"
                  width="sm"
                  valueEnum={{
                    Recebido: 'Recebido lab externo',
                  }}
                ></ProFormSelect>
                <ProFormTextArea
                  label="Observações"
                  width="md"
                ></ProFormTextArea>
              </ProForm>
              <div>
                <Button type="primary">Salvar </Button>{" "}
              </div>
            </TabPane>
          </Tabs>
        </div>
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Observação Interna" key="4">
            <ProForm
              submitter={false}
              formRef={formRef}
              onFinish={async () => {
                await waitTime(1000);
                message.success({
                  content: "Sucesso",
                  style: {
                    marginTop: "20vh",
                  },
                });
              }}
            >
              <ProFormTextArea></ProFormTextArea>
              <div style={{ textAlign: "center" }}>
                <Button type="primary">Salvar Observação Interna </Button>{" "}
              </div>
            </ProForm>
          </TabPane>
        </Tabs>
        <Tabs defaultActiveKey="1" size="middle" centered>
          <TabPane tab="Histórico Alteração" key="5">
            <HistoricoAlteracao />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default OrdemServico;
