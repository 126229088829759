import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, Form, Input,Tabs } from "antd";
import React, { useState } from "react";

import EvolucaoDados from "./Evolucao";
import PlanejamentosPendentes from "./PlanejamentosPendentes";

const { TabPane } = Tabs;

const PlanejamentoTratamento: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [evolucao, setEvolucao] = useState(false);

  const onFinish = (values: any) => {
    if (values) {
      setEvolucao(true);
    } else {
      setEvolucao(false);
    }
    console.log("Nome do Paciente: ", values);
  };

  return (
    <>
      <PageContainer>
        <ProCard
          style={{ width: "500px", marginLeft: "auto", marginRight: "auto" }}
        >
          <Tabs defaultActiveKey="1" size="middle">
            <TabPane tab="PLANEJAMENTO PACIENTE" key="1">
              <Form onFinish={onFinish} layout="vertical">
                <Form.Item
                  label="Paciente/Prontuário:"
                  name="pacienteProntuario"
                >
                  <Input />
                </Form.Item>{" "}
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit">
                    Procurar{" "}
                  </Button>{" "}
                </div>
              </Form>
            </TabPane>
          </Tabs>
        </ProCard>
        {evolucao === true && (
          <EvolucaoDados
            visible={isVisible}
            evolucao={evolucao}
            onOk={() => setIsVisible(false)}
            onCancel={() => setIsVisible(false)}
          />
        )}
        {evolucao === false && <PlanejamentosPendentes />}
      </PageContainer>
    </>
  );
};

export default PlanejamentoTratamento;
