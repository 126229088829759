import { ProFormSelect } from '@ant-design/pro-form';
import axios from "axios";
import React, { useEffect,useState} from "react";

import api from "../services/api";

function FormaPagamento(props: any){

    const [formaPagamento, setFormaPagamento]       = useState();
    const [loadFormaPagamento, setLoadFormaPagamento] = useState(false);

    const listaFormaPagamento = async () => {
        
        setLoadFormaPagamento(true);
        let lista: any = [];

        try {
            const { data } = await api.get("financeiro/list-formas-pagamento", {});
            
            data.map(function (result: any, index: number) {
                lista[index] = { value: result.idFormaPagamento, label: result.nomeFormaPagamento }; 
            });

            setFormaPagamento(lista);
            setLoadFormaPagamento(false);
            
        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        listaFormaPagamento();
      }, []);

    return (

        <ProFormSelect
            options={formaPagamento}
            name="formaPagamento"
            showSearch={true}
            fieldProps={{
                loading: loadFormaPagamento
            }}
        />
            
    )    

}
export default FormaPagamento;
