import React, { createContext, useContext, useEffect,useState } from "react";

import api from "../services/api";

export interface Clinica {
  id: number;
  nome: string;
  sigla: string;
  uf: string;
  ativo: boolean;
}

interface ClinicaContextData {
  clinicaPrincipal: Clinica;
  clinicaAtual: Clinica;
  clinicas: Clinica[];
  idClinicaPrincipal: number;
  idClinicaAtual: number;
  setClinicaAtual(id: number): void;
  setClinicaPrincipal(id: number): void;
  setClinicas(clinicas: Clinica[]): void;
}

export const ClinicaContext = createContext<ClinicaContextData>(
  {} as ClinicaContextData
);

export const ClinicaProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [clinicas, setClinicas] = useState<Clinica[]>([]);
  const [idClinicaAtual, setClinicaAtual] = useState<number>(0);
  const [idClinicaPrincipal, setClinicaPrincipal] = useState<number>(0);

  const clinicaAtual = clinicas.find((c) => c.id === idClinicaAtual)!;
  const clinicaPrincipal = clinicas.find((c) => c.id === idClinicaPrincipal)!;

  useEffect(() => {
    if (idClinicaAtual) {
      api.defaults.headers.common["idClinica"] = String(idClinicaAtual);
    } else {
      delete api.defaults.headers.common["idClinica"];
    }
  }, [idClinicaAtual]);

  return (
    <ClinicaContext.Provider
      value={{
        clinicaAtual,
        clinicaPrincipal,
        clinicas,
        idClinicaAtual,
        idClinicaPrincipal,
        setClinicaAtual,
        setClinicaPrincipal,
        setClinicas,
      }}
    >
      {children}
    </ClinicaContext.Provider>
  );
};

export function useClinica() {
  const context = useContext(ClinicaContext);

  if (!context) {
    throw new Error("useClinica must be used within an ClinicaProvider.");
  }

  return context;
}
