export interface ModalProps {
    visible: boolean,
    id: null|string,
    body: number,
    onClose: () => void,
}

export interface OrcamentoVisualizarProps {
    id: null|string,
}
export interface ParcelasVisualizarProps {
    id: null|string,
}


export const paymentsOptions = [
    {
        "value":"0_0",
        "label":"Boleto Bancário",
        "children":[
            {"value":"0_0","label":"Banco Bradesco  "},
            {"value":"0_1","label":"Banco do Brasil  "},
            {"value":"0_2","label":"Banco Santander  "},
            {"value":"0_3","label":"Banco Sicredi  "},
            {"value":"0_4","label":"Banco Unicred  "},
            {"value":"0_5","label":"Federal Invest  "}
        ]
    },
    {
        "value":"0_1",
        "label":"Cartão de Crédito",
        "children":[
            {"value":"1_0","label":"American Express  "},
            {"value":"1_1","label":"Diners Club  "},
            {"value":"1_2","label":"Elo  "},
            {"value":"1_3","label":"Hipercard  "},
            {"value":"1_4","label":"MasterCard  "},
            {"value":"1_5","label":"Visa  "}
        ]
    },
    {
        "value":"0_2",
        "label":"Cartão de Débito",
        "children":[
            {"value":"2_0","label":"Elo Debito  "},
            {"value":"2_1","label":"MasterCard Debito  "},
            {"value":"2_2","label":"Visa Electron  "}
        ]
    },
    {
        "value":"0_3",
        "label":"Carteira",
        "children":[
            {"value":"3_0","label":"Carnê  "},
            {"value":"3_1","label":"Nota Promissória  "}
        ]
    },
    {
        "value":"0_4",
        "label":"Cheque",
        "children":[
            {"value":"4_0","label":"Conta Pessoa Física  "},
            {"value":"4_1","label":"Conta Pessoa Jurídica  "}
        ]
    },
    {
        "value":"0_5",
        "label":"Dinheiro",
        "children":[
            {"value":"5_0","label":"Reais  "}
        ]
    },
    {
        "value":"0_6",
        "label":"PIX",
        "children":[
            {"value":"6_0","label":"Banco Bradesco  "},
            {"value":"6_1","label":"Banco do Brasil  "},
            {"value":"6_2","label":"Banco Santander  "},
            {"value":"6_3","label":"Banco Sicredi  "},
            {"value":"6_4","label":"Banco Unicred  "}
        ]
    },
    {
        "value":"0_7",
        "label":"Ted / Doc",
        "children":[
            {"value":"7_0","label":"Banco Bradesco  "},
            {"value":"7_1","label":"Banco do Brasil  "},
            {"value":"7_2","label":"Banco Santander  "},
            {"value":"7_3","label":"Banco Sicredi  "},
            {"value":"7_4","label":"Banco Unicred  "}
        ]
    }]