import {Button, Card, Empty, Table} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useState} from 'react';

import {ParcelasVisualizarProps} from "../types";
import FinaceiroParcela from "./FinanceiroParcela";

const Financeiro: React.FC<ParcelasVisualizarProps> = (props) => {
    const [parcelas, setParcelas] = useState<any | null>(null);
    const [tipoJuros, setTipoJuros] = useState<string>("2");
    const [qtdPagamentos, setQtdPagamentos] = useState<number>(1);
    function initializeContract(){
        if(props.id){
            const newParcelas: any = {
                id: props.id,
                paciente: "Aarao Lyra",
                list:[]
            }
            for (let v = 0; v < 2; v += 1){
                newParcelas.list.push({
                    id: (444+v).toString(),
                    paciente: 'Edson Correa',
                    tipo: 'Tipo',
                    propostas: 'Proposta 1 - 1.410,00 ',
                    valor: 'R$ 1.410,00',
                })
            }

            setParcelas(newParcelas)
        } else{
            setParcelas(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    function handleChange(value: string) {
        setTipoJuros(value)
    }
    return (
        <div>
            {parcelas ?
                <div className="parcelasDetalhes" >
                    <p>Selecione a(s) proposta(s):</p>
                    <Table
                        pagination={false} dataSource={parcelas.list} columns={[
                        {
                            title: 'Paciente',
                            dataIndex: 'paciente',
                            key: 'paciente',
                        },
                        {
                            title: 'Tipo',
                            dataIndex: 'tipo',
                            key: 'tipo',
                        },
                        {
                            title: 'Propostas',
                            dataIndex: 'propostas',
                            key: 'propostas',
                        },
                        {
                            title: 'Valor',
                            dataIndex: 'valor',
                            key: 'valor',
                        },
                    ]} />
                    <div className="site-card-border-less-wrapper">
                       <p><strong>Total: </strong>R$ 2.820,00</p>
                    </div>
                    <p>Formas de Pagamento</p>
                    <div className="formas-pagmentos">
                        <Card size="small">
                            <table>
                                <tr>
                                    <th colSpan={5}>
                                       <p className="title" style={{marginBottom: 10, marginTop: 0}}>
                                           Selecione a quantidade de formas de pagamento:
                                       </p>
                                    </th>
                                </tr>
                                <tr>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(1)} shape="circle">1</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(2)} shape="circle">2</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(3)} shape="circle">3</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(4)} shape="circle">4</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(5)} shape="circle">5</Button> </td>
                                </tr>
                            </table>
                            <table className="table-payment" style={{marginTop: 20}}>
                                <tr>
                                    <th>Forma Pgto.</th>
                                    <th>Valor</th>
                                    <th>Nro Parcelas</th>
                                    <th>Vencimento 1ª</th>
                                    <th>Valor Parcelaª</th>
                                </tr>
                                {Array.from({ length: qtdPagamentos }).map(() => {
                                    return  <FinaceiroParcela />;
                                })}
                            </table>
                            <TextArea style={{marginTop: 20}}
                                placeholder="Observação"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Card>
                    </div>
                </div>
                :<Empty />}
        </div>
    );
};

export default Financeiro