import ProForm, { ProFormText } from "@ant-design/pro-form";
import { Modal } from "antd";
import React from "react";

interface ModalAddDestincoes{
    visible: boolean;
    addDestinacao: boolean;
    onOk?: () => void;
    onCancel?: () => void;
}

const Gerenciar: React.FC<ModalAddDestincoes> = (props) => {
    
  return (
    <>
      <Modal
        title={"Destinações"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <ProForm submitter={false}>
          <ProForm.Group>
            <ProFormText
              name="nome"
              label="Digite o nome da nova financeira:"
            ></ProFormText>
          </ProForm.Group>
        </ProForm>
      </Modal>
    </>
  );
};

export default Gerenciar;
