import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, Dropdown, Menu, message, Popconfirm,Tag } from "antd";
import axios from "axios";
import React, { useEffect,useState } from "react";

import api from "../../../services/api";
import FiltroProfissao from "./FiltroProfissao";
import IncluirProfissao from "./IncluirProfissao";
import { nomeProfissao, Pagination, ProfissaoAjax, ProfissaoGetAjax } from "./types";

const Profissao: React.FC = () => {
  
  const columns: ProColumns<nomeProfissao>[] = [
    {
      title: "Nome",
      dataIndex: "profissaoDescricao",
      key: "Descricao",
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.profissaoDescricao.localeCompare(b.profissaoDescricao),
    },
    {
      title: "Status",
      dataIndex: "profissaoStatus",
      key: "Status",
      renderText: (isActive: boolean) => (
        <>
          {isActive ? (
            <Tag color="#00cc00">Ativo</Tag>
          ) : (
            <Tag color="#ff0000">Inativo</Tag>
          )}
        </>
      ),  
    },
    {
      title: "Ações",
      key: "action",
      render: (Dom, row) => (
        <span>
          <Dropdown.Button
            onClick={() => OpenModal(row)}
            overlay={menuAcoes(row)}
            trigger={["click"]}
          >
            <EditOutlined />
            Alterar
          </Dropdown.Button>{" "}
        </span>
      ),
    },
  ];

  const [table, setTable]   = useState<nomeProfissao[]>();
  const [pagina, setPagina] = useState<Pagination>({
    current: 1,
    total: 0,
    pageSize: 15,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setDeleteVisible]         = useState(false);
  const [profissao, setProfissao]           = useState(false);
  const [load, setLoad]                     = useState(false);

  const LoadData = async (page = 1, pageSize = 15) => {
    setLoad(true);
    try {
      const { data } = await api.get<ProfissaoAjax>(`cadastros/profissoes`, {
        params: { page: page, per_page: pageSize },
      });
      setPagina({
        ...pagina,
        current: page,
        total: data.meta.total,
        pageSize: pageSize,
      });
      setTable(data.data);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
    setLoad(false);
  };

  useEffect(() => {
    LoadData();
  }, []);

  const menuAcoes = (key: any) => {
    return (
      <Menu>
        <Menu.Item key="1" danger icon={<DeleteOutlined />}>
          <Popconfirm
            title="Esta certo disso ?"
            onConfirm={() => deleteOk(key)}
            onCancel={deleteCancel}
            placement="top"
          >
            Deletar
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
  };

  async function deleteOk(row: nomeProfissao) {
    await api.delete<ProfissaoGetAjax>(`cadastros/profissoes/${row.idProfissao}`);
    message.success("Profissão deletada");
    RelaodList();
  }

  const deleteCancel = () => {
    setDeleteVisible(false);
  };

  async function OpenModal(row: any) {
    if (row.idProfissao) {
      setProfissao(row.idProfissao);
    } else {
      setProfissao(false);
    }
    setIsModalVisible(true);
  }

  const CloseModal = () => {
    setIsModalVisible(false);
  };

  const RelaodList = () => {
    LoadData(pagina.current, pagina.pageSize);
  };

  return (
    <>
      <IncluirProfissao
        profissao={profissao}
        visible={isModalVisible}
        onCancel={CloseModal}
        loadPage={RelaodList}
      />
        <PageContainer extra={[
          <Button key="1" type="primary" onClick={OpenModal}>
            Nova Profissão
          </Button>
        ]}> 
        
        <FiltroProfissao />

        <ProTable<nomeProfissao>
          search={false}
          loading={load}
          onRow={(record: any) => {
            return {
                onClick: () => {
                  console.log(record);
                },
            };
          }}
          columns={columns}
          request={(params = {}, sorter, filter) => {
            console.log(params, sorter, filter);
            return Promise.resolve({
                data: table,
                success: true,
            });
          }}
          rowKey="key"
          dataSource={table}
          pagination={{
            current: pagina.current,
            total: pagina.total,
            pageSize: pagina.pageSize,
            onChange: (page, pageSize) => {
              LoadData(page, pageSize);
            },
          }}
        />
      </PageContainer>
    </>
  );
};

export default Profissao;
