import { Button, DatePicker, Empty } from "antd";
import React, { useEffect, useState } from 'react';

import { OrcamentoVisualizarProps } from "../types";

const OrcamentoVisualizar: React.FC<OrcamentoVisualizarProps> = (props) => {
    const [oracamento, setOrcamento] = useState<any | null>(null);
    function initializeContract(){
        if(props.id){
            const newOrcamento: any = {
                oracamento_id: props.id,
                usuario: "WILLIAN OLIVEIRA MENDES DE SOUZA",
                data: '\t25/08/2021'
            }

            setOrcamento(newOrcamento)
        } else{
            setOrcamento(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <div>
            {oracamento ?
                <div className="oracamentoDetalhes" >
                    <p>Quantidade de Parcelas que serão alteradas: <strong>1</strong> </p>
                    <div className="filters">
                        <label>
                            Data da primeira parcela:

                            <DatePicker format={'DD/MM/YYYY'} />

                        </label>
                        <Button type="primary" size="large">
                            Alterar
                        </Button>
                    </div>
                    <br/>
                    <br/>
                    <p>Obs.: Não é possível alterar datas de vencimento das parcelas já formalizadas.</p>
                </div>
                :<Empty />}
        </div>
    );
};

export default OrcamentoVisualizar