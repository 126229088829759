import { SearchOutlined } from "@ant-design/icons";
import ProForm, { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Button, Form } from "antd";
import React from "react";

const Gerenciar: React.FC = () => {
  return (
    <>
      <div>
        <Form>
          <ProFormText width="lg" name="name" label="Nome Clínica" />
          <ProFormText width="lg" name="razaoSocial" label="Razão Social" />

          <ProForm.Group>
            <ProFormText width="sm" name="CEP" label="CEP" />
            <Button type="primary" icon={<SearchOutlined />}>
              Buscar CEP
            </Button>
          </ProForm.Group>
          <ProForm.Group>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Paraná",
                },
                {
                  value: "2",
                  label: "São Paulo",
                },
              ]}
              name="estado"
              label="Estado"
              width="sm"
            ></ProFormSelect>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Londrina",
                },
                {
                  value: "2",
                  label: "Cambé",
                },
              ]}
              width="sm"
              name="cidade"
              label="Cidade"
            />
          </ProForm.Group>
          <ProFormText width="lg" name="bairro" label="Bairro" />
          <ProFormText width="lg" name="logradouro" label="Logradouro" />
          <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Oeste",
                },
                {
                  value: "2",
                  label: "Leste",
                },
                {
                  value: "3",
                  label: "Norte",
                },
                {
                  value: "4",
                  label: "Sul",
                },
              ]}
              width="sm"
              name="regiao"
              label="Região"
            />
          <ProFormText width="lg" name="tel1" label="Telefone 1" />
          <ProFormText width="lg" name="tel2" label="Telefone 2" />
          <ProFormText width="lg" name="inscricao" label="Inscrição Municipal" />
          <ProFormText width="lg" name="CNPJ" label="CNPJ" />
          <ProFormSelect
                options={[
                  {
                    value: "1",
                    label: "America / São Paulo",
                  },
                  {
                    value: "2",
                    label: "America / Santarem",
                  },
                ]}
              width="sm"
              name="fuso"
              label="Fuso Horário	"
            />
              <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Renan",
                },
                {
                  value: "2",
                  label: "Thiago",
                },
              ]}
              width="sm"
              name="consultorDesenvolvimento"
              label="Consultor Desenvolvimento	"
            />
              <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Renan",
                },
                {
                  value: "2",
                  label: "Thiago",
                },
              ]}
              width="sm"
              name="consultorComercial"
              label="Consultor Comercial	"
            />
              <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Sim",
                },
                {
                  value: "2",
                  label: "Não",
                },
              ]}
              width="sm"
              name="chatBot"
              label="Permite Agendamento Via ChatBot	"
            />
              <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Não",
                },
                {
                  value: "2",
                  label: "Sim",
                },
              ]}
              width="sm"
              name="aplicativo"
              label="Permite Agendamento Via Aplicativo	"
            />
            <ProFormText width="lg" name="safra" label="Data de safra" />
          <ProFormText width="lg" name="urlProprio" label="URL Landpage Próprio	" />
          <ProFormText width="lg" name="urlOralSin" label="URL Landpage Oral Sin	" />
          <ProFormText width="lg" name="urlFacebook" label="URL Facebook	" />
          <ProFormText width="lg" name="chatFacebook" label="URL Chat Facebook	" />
          <ProFormText width="lg" name="whatsapp" label="URL Whatsapp	" />
          <ProFormText width="lg" name="email" label="E-mails para envio de lead (separado por ;)	" />
        </Form>
      </div>
    </>
  );
};

export default Gerenciar;
