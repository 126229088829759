import { Modal, Tabs } from "antd";
import React from "react";

const { TabPane } = Tabs;

interface ModalContrato {
  visible: boolean;
  contratoDetail: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const PlanejamentoTratamento: React.FC<ModalContrato> = (props) => {
  return (
    <>
      <Modal
        title={"Detalhes Contrato"}
        width={"60%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
        okText="Salvar"
      >
        <Tabs defaultActiveKey="1" size="middle">
            <TabPane tab="VERSÃO 1" key="1">

            </TabPane>
          </Tabs>
      </Modal>
    </>
  );
};

export default PlanejamentoTratamento;
