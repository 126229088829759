import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";

import ClinicaHeaderDropdown from "./ClinicaHeaderDropdown";
import styles from "./index.module.scss";
import Avatar from "./UserHeaderDropdown";

export type SiderTheme = "light" | "dark";

const GlobalHeaderRight: React.FC = () => {
  let className = styles.right;

  return (
    <Space className={className}>
      <span
        className={styles.action}
        onClick={() => {
          window.open("https://oralsin.com.br");
        }}
      >
        <QuestionCircleOutlined />
      </span>
      <ClinicaHeaderDropdown />
      <Avatar menu={true} />
    </Space>
  );
};
export default GlobalHeaderRight;
