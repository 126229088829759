import { ProFormUploadButton } from "@ant-design/pro-form";
import { Modal, Tabs } from "antd";
import React from "react";

const { TabPane } = Tabs;

interface ModalVisualizarImg {
    visible: boolean;
    viImg: boolean;
    onOk?: () => void;
    onCancel?: () => void;
  }

const emissaoRaioX: React.FC<ModalVisualizarImg> = (props) => {
  return (
    <>
      <Modal
        title={"Imagens de Raio-X"}
        width={"30%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
         <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Nome Paciente" key="1">
            Nome Paciente:
            <ProFormUploadButton
              label="CPF do Paciente"
              name="file"
              title="Adicionar Nova Imagens"
            />
            <fieldset className="fieldset-danger">
						<p>Nenhuma imagem cadastrada.</p>
					</fieldset>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default emissaoRaioX;
