import { Checkbox, Col, Divider, Form, Input, Radio, Row, Select } from 'antd';
import React from 'react';

const { TextArea } = Input;

const layout = {  };
const DadosPaciente: React.FC = () => {

    const userData = {
        nome: "VALCIONIRA APARECIDA VOLPATO SOARES (V)",
        nome_mae: "",
        nome_pai: "",
        estrangeiro: false,
        titular: 'Proprio',
        conjuge: 'Proprio',
        cpf: '326.743.849-72',
        sexo: 'fem',
        prontuario: '0007022',
        nascimento: "30/10/1957",
        civil: "casado",
        rg: "1.572.830-2",
        cep: "86063-240",
        estado: "1",
        bairro: "JAMAICA",
        numero: "360",
        tipo_logradouro: "1",
        complemnto: "CASA 23",
        logradouro: "BENJAMIN FRANKLIN",
        cidade: "Cidade",
    }

    return (
        <div className="formulario" style={{backgroundColor: "white"}}>
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                layout="horizontal"
                size={'small'}
            >
                <Divider>Dados Pessoais</Divider>
                <Row>
                    <Col span={12}>
                        <Form.Item name="estrangeiro" valuePropName="checked" wrapperCol={layout}>
                            <Checkbox disabled={true}>Estrangeiro</Checkbox>
                        </Form.Item>
                        <Form.Item  label="Titular:" wrapperCol={layout}>
                            <Select value={userData.titular} disabled={true} onChange={()=>{}} >
                                <Select.Option value="Proprio">Proprio</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={layout} label="RG"><Input value={userData.rg} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Nome"><Input value={userData.nome} disabled={true} /></Form.Item>
                        <Form.Item  label="Sexo:" wrapperCol={layout}>
                            <Select value={userData.sexo} disabled={true} onChange={()=>{}} >
                                <Select.Option value="fem">Feminio</Select.Option>
                                <Select.Option value="mas">Masculi</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Aposentado: " name="aposentado">
                            <Radio.Group disabled={true}>
                                <Radio.Button value="sim">Sim</Radio.Button>
                                <Radio.Button value="nao">Não</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item wrapperCol={layout} label="Cônjuge"><Input value={userData.conjuge} disabled={true} /></Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item wrapperCol={layout} label="Nome da Mãe"><Input value={userData.nome_mae} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Nome da Pai"><Input value={userData.nome_pai} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="CPF:"><Input value={userData.cpf} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Prontuário:"><Input value={userData.prontuario} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Nascimento:"><Input value={userData.nascimento} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Estado Civil:">
                            <Select value={userData.civil} disabled={true} onChange={()=>{}} >
                                <Select.Option value="casado">Casado</Select.Option>
                                <Select.Option value="solteiro">Solteiro</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>Endereço</Divider>
                <Row>
                    <Col span={12}>
                        <Form.Item wrapperCol={layout} label="CEP: "><Input value={userData.cep} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Estado:">
                            <Select value={userData.estado} disabled={true} onChange={()=>{}} >
                                <Select.Option value="1">Paraná</Select.Option>
                            </Select>
                        </Form.Item>
                       <Form.Item wrapperCol={layout} label="Bairro: "><Input value={userData.bairro} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Tipo de Logradouro:">
                            <Select value={userData.tipo_logradouro} disabled={true} onChange={()=>{}} >
                                <Select.Option value="1">Rua</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item wrapperCol={layout} label="Cidade: "><Input value={userData.cidade} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Logradouro: "><Input value={userData.logradouro} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Número: "><Input value={userData.numero} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Complemento: "><Input value={userData.complemnto} disabled={true} /></Form.Item>
                    </Col>
                </Row>
                <Divider>Dados Comerciais</Divider>
                <Row>
                    <Col span={12}>
                        <Form.Item wrapperCol={layout} label="Profissão:">
                            <Select value={userData.estado} disabled={true} onChange={()=>{}} >
                                <Select.Option value="1">Aposentado</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={layout} label="Empresa: "><Input disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Função: "><Input disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="CEP: "><Input value={userData.cep} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Estado:">
                            <Select value={userData.estado} disabled={true} onChange={()=>{}} >
                                <Select.Option value="1">Paraná</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item wrapperCol={layout} label="Bairro: "><Input value={userData.bairro} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Tipo de Logradouro:">
                            <Select value={userData.tipo_logradouro} disabled={true} onChange={()=>{}} >
                                <Select.Option value="1">Rua</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item wrapperCol={layout} label="Cidade: "><Input value={userData.cidade} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Logradouro: "><Input value={userData.logradouro} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Número: "><Input value={userData.numero} disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Complemento: "><Input value={userData.complemnto} disabled={true} /></Form.Item>
                    </Col>
                </Row>
                <Divider>Dados de Contato</Divider>
                <Row>
                    <Col span={12}>
                        <Form.Item wrapperCol={layout} label="Telefone Residencia: "><Input disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Telefone Comercial: "><Input disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Telefone de Recado: "><Input disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="E-mail: "><Input disabled={true} /></Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item wrapperCol={layout} label="Telefone Celular: "><Input disabled={true} /></Form.Item>
                        <Form.Item wrapperCol={layout} label="Nome da Pessoa Recado: "><Input disabled={true} /></Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default DadosPaciente