import { Modal } from "antd";
import React from "react";

interface ModalEmitirRaioXGratuito {
  visible: boolean;
  emitirRaioXG: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const emissaoRaioX: React.FC<ModalEmitirRaioXGratuito> = (props) => {
  return (
    <>
      <Modal
        title={"Recibo emissão raio-x"}
        width={"45%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      ></Modal>
    </>
  );
};

export default emissaoRaioX;
