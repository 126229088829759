import ProForm, { ProFormCheckbox } from "@ant-design/pro-form";
import { Button, Modal, Tabs } from "antd";
import React, { useState } from "react";

import ModalAddInstuicao from "./ModalAddInstituicao";

const { TabPane } = Tabs;

interface ModalFormaPagamento {
  visible: boolean;
  pagamento: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const Gerenciar: React.FC<ModalFormaPagamento> = (props) => {

  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addInstituicao, setAddInstituicao] = useState(false);

  async function OpenModalAddIntituicao(row: any) {
    if (row) {
      setAddInstituicao(row);
    } else {
      setAddInstituicao(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
      <Modal
        title={"Forma de Pagamento"}
        width={"40%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
      {addInstituicao && (
        <ModalAddInstuicao
          visible={isModalVisible}
          instituicao={addInstituicao}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Forma de Pagamento" key="1">
            <ProForm.Group>
              <Button type="primary" onClick={OpenModalAddIntituicao}>Cadastrar Nova Instituição</Button>
            </ProForm.Group>
            <ProFormCheckbox>ASAAS</ProFormCheckbox>
            <ProFormCheckbox>Banco Alfa</ProFormCheckbox>
            <ProFormCheckbox>Banco Amazônia</ProFormCheckbox>
            <ProFormCheckbox>Banco Atenas</ProFormCheckbox>
            <ProFormCheckbox>Banco Banpará S/A</ProFormCheckbox>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Gerenciar;