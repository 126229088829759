import ProForm from "@ant-design/pro-form";
import { Button } from "antd";
import React, { useState } from "react";

import ModalCondicoes from "./ModalCondicoes";
import ModalFormaPagamento from "./ModalFormaPagamento";

const Gerenciar: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleCondicoes, setIsModalVisibleCondicoes] = useState(false);
  const [pagamento, setPagamento] = useState(false);
  const [condicoes, setCondicoes] = useState(false);

  async function OpenModal(row: any) {
    if (row) {
      setPagamento(row);
    } else {
      setPagamento(false);
    }
    setIsModalVisible(true);
  }

  async function OpenModalCondicoes(row: any) {
    if (row) {
      setCondicoes(row);
    } else {
      setCondicoes(false);
    }
    setIsModalVisibleCondicoes(true);
  }
  return (
    <>
      {pagamento && (
        <ModalFormaPagamento
          visible={isModalVisible}
          pagamento={pagamento}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      {condicoes && (
        <ModalCondicoes
          visible={isModalVisibleCondicoes}
          condicoes={condicoes}
          onOk={() => setIsModalVisibleCondicoes(false)}
          onCancel={() => setIsModalVisibleCondicoes(false)}
        />
      )}
      <ProForm submitter={false}>
        <div style={{ lineHeight: "50px" }}>
          <ProForm.Group>
            Dinheiro:
            <Button type="primary" onClick={OpenModal}>
              Instituições
            </Button>
            <li id="formaPagamentoInstituicao">
              Banco:
              <ul id="ulCondicoes">
                <ProForm.Group style={{ alignItems: "center" }}>
                  <Button type="primary" onClick={OpenModalCondicoes}>
                    Condições
                  </Button>
                </ProForm.Group>
              </ul>
            </li>
          </ProForm.Group>
          <ProForm.Group>
            Cartão de Credito:
            <Button type="primary" onClick={OpenModal}>
              Instituições
            </Button>
            <li id="formaPagamentoInstituicao">
              Banco:
              <ul id="ulCondicoes">
                <ProForm.Group style={{ alignItems: "center" }}>
                  <Button type="primary" onClick={OpenModalCondicoes}>
                    Condições
                  </Button>
                </ProForm.Group>
              </ul>
            </li>
          </ProForm.Group>
          <ProForm.Group>
            Cheque:
            <Button type="primary" onClick={OpenModal}>
              Instituições
            </Button>
            <li id="formaPagamentoInstituicao">
              Banco:
              <ul id="ulCondicoes">
                <ProForm.Group style={{ alignItems: "center" }}>
                  <Button type="primary" onClick={OpenModalCondicoes}>
                    Condições
                  </Button>
                </ProForm.Group>
              </ul>
            </li>
          </ProForm.Group>
          <ProForm.Group>
            Boleto Bancário:
            <Button type="primary" onClick={OpenModal}>
              Instituições
            </Button>
            <li id="formaPagamentoInstituicao">
              Banco:
              <ul id="ulCondicoes">
                <ProForm.Group style={{ alignItems: "center" }}>
                  <Button type="primary" onClick={OpenModalCondicoes}>
                    Condições
                  </Button>
                </ProForm.Group>
              </ul>
            </li>
          </ProForm.Group>
          <ProForm.Group>
            Cartão de Debito:
            <Button type="primary" onClick={OpenModal}>
              Instituições
            </Button>
            <li id="formaPagamentoInstituicao">
              Banco:
              <ul id="ulCondicoes">
                <ProForm.Group style={{ alignItems: "center" }}>
                  <Button type="primary" onClick={OpenModalCondicoes}>
                    Condições
                  </Button>
                </ProForm.Group>
              </ul>
            </li>
          </ProForm.Group>
          <ProForm.Group>
            TED / DOC:
            <Button type="primary" onClick={OpenModal}>
              Instituições
            </Button>
            <li id="formaPagamentoInstituicao">
              Banco:
              <ul id="ulCondicoes">
                <ProForm.Group style={{ alignItems: "center" }}>
                  <Button type="primary" onClick={OpenModalCondicoes}>
                    Condições
                  </Button>
                </ProForm.Group>
              </ul>
            </li>
          </ProForm.Group>
          <ProForm.Group>
            Pix:
            <Button type="primary" onClick={OpenModal}>
              Instituições
            </Button>
            <li id="formaPagamentoInstituicao">
              Banco:
              <ul id="ulCondicoes">
                <ProForm.Group style={{ alignItems: "center" }}>
                  <Button type="primary" onClick={OpenModalCondicoes}>
                    Condições
                  </Button>
                </ProForm.Group>
              </ul>
            </li>
          </ProForm.Group>
        </div>
      </ProForm>
    </>
  );
};

export default Gerenciar;
