import { LockOutlined,UserOutlined } from "@ant-design/icons";
import ProForm, {
  LoginForm,
  ProFormCheckbox,
  ProFormText,
} from "@ant-design/pro-form";
import { message } from "antd";
import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../contexts";
import logoRetangular from "../media/logo-s.png";

const Login: React.FC = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [form] = ProForm.useForm();

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  let state = location.state as { from: Location };
  let from = state ? state.from.pathname : "/";

  const handleSubmit = async (formData: any) => {
    setSubmitting(true);

    const result = await auth.login({
      username: formData.username,
      password: formData.password,
      remember: formData.remember,
    });

    setSubmitting(false);

    if (result.isSuccess) {
      // Redireciona para a ultima URL chamada
      return navigate(from, { replace: true });
    }

    // Limpa o campo de senha
    form.setFields([{ name: "password", value: "" }]);

    // Pega os erros de validação do retorno
    if (result.errors) {
      for (const name in result.errors) {
        const errors = (result.errors as any)[name] || [];
        form.setFields([{ name, errors }]);
      }
      return;
    }

    if (result.message) {
      message.error(result.message);
      return;
    }

    message.error("Erro desconhecido");
  };

  if (auth.authenticated) {
    return <Navigate to={from} />;
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <LoginForm
        form={form}
        logo={logoRetangular}
        title=""
        subTitle="Autenticação"
        submitter={{
          searchConfig: {
            submitText: "Entrar",
          },
          submitButtonProps: {
            loading: submitting,
            size: "large",
            style: {
              width: "100%",
            },
          },
          resetButtonProps: {
            style: {
              // Hide the reset button
              display: "none",
            },
          },
        }}
        onFinish={handleSubmit}
      >
        <ProFormText
          name="username"
          fieldProps={{
            size: "large",
            prefix: <UserOutlined className={"prefixIcon"} />,
            autoFocus: true,
          }}
          placeholder={"Usuário / CPF"}
          rules={[
            {
              required: true,
              message: "Por favor insira o nome de usuário!",
            },
          ]}
        />
        <ProFormText.Password
          name="password"
          fieldProps={{
            size: "large",
            prefix: <LockOutlined className={"prefixIcon"} />,
          }}
          placeholder={"Senha"}
          rules={[
            {
              required: true,
              message: "Por favor insira a senha!",
            },
          ]}
        />
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <ProFormCheckbox noStyle name="remember" initialValue={true}>
            login automático
          </ProFormCheckbox>
          <a
            href="#recover"
            style={{
              float: "right",
            }}
            onClick={(e) => {
              e.preventDefault();
              message.info("Foi enviado um e-mail para recuperação de senha");
            }}
          >
            esqueceu a senha
          </a>
        </div>
      </LoginForm>
    </div>
  );
};

export default Login;
