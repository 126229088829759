import {Col, Collapse, Empty, Row, Table} from "antd";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";
import React, {useEffect, useState} from 'react';

import {OrcamentoVisualizarProps} from "../types";

const OrcamentoVisualizar: React.FC<OrcamentoVisualizarProps> = (props) => {
    const [oracamento, setOrcamento] = useState<any | null>(null);
    function initializeContract(){
        if(props.id){
            const newOrcamento: any = {
                oracamento_id: props.id,
                paciente: "Aarao Lyra",
                prontuario: "NAT-0000858-NAT",
                profissao: "Do Lar",
                midia: "COMUNICAÇÃO VISUAL EXTERNA - FACHADA - PASSOU EM FRENTE",
                valor_final: "R$ 156,18",
                avaliacao: "Dra Paula em 27/01/2022",
                procedimentos:[],
                observacao: "iente que dente ficará áspero, realizado por outro profissional.",
                parcelas:[]
            }
            for (let v = 0; v < 2; v += 1){
                newOrcamento.parcelas.push({
                    id: (444+v).toString(),
                    data_vencimento: "22/01/2019",
                    valor_parcela: "R$ 1.000,00",
                    forma_pagamento: "Cartão de Débito (Visa Electron)",
                })
                newOrcamento.procedimentos.push({
                    procedimento: "Coroa Metalo Cerâmica Sobre Dente",
                    localizacao: "Dentes: 35",
                    valor_venda: "R$ 1.001,68",
                    valor_tabela: "R$ 1.200,00",
                })
            }

            setOrcamento(newOrcamento)
        } else{
            setOrcamento(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <div>
            {oracamento ?
                <div className="oracamentoDetalhes" >
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                <strong>{oracamento.oracamento_id}</strong>
                                (Orçamento Ref: 277322)
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Contrado:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.paciente}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Paciente:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.prontuario}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Prontuário:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.profissao}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Profissão:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.midia}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Mídia Captação:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.valor_final}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Valor Final:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.avaliacao}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Avaliação:</Text>
                        </Col>
                    </Row>
                    <Collapse className="site-collapse-custom-collapse" onChange={callback}>
                        <Collapse.Panel className="site-collapse-custom-panel"
                                        header="Observações Impressão" key="observacao">
                            <p>{oracamento.observacao}</p>
                        </Collapse.Panel>
                    </Collapse>
                    <Title style={{marginTop: "20px"}} level={5}>Procedimentos</Title>
                    <Table pagination={false} dataSource={oracamento.procedimentos} columns={[
                        {
                            title: 'Procedimento',
                            dataIndex: 'procedimento',
                            key: 'procedimento',
                        },
                        {
                            title: 'Localização',
                            dataIndex: 'localizacao',
                            key: 'localizacao',
                        },
                        {
                            title: 'Valor Venda',
                            dataIndex: 'valor_venda',
                            key: 'valor_venda',
                        },
                        {
                            title: 'Valor Tabela',
                            dataIndex: 'valor_tabela',
                            key: 'valor_tabela',
                        }
                    ]} />

                    <Title style={{marginTop: "20px"}} level={5}>Parcelas</Title>
                    <Table
                        rowClassName={(record, index) => (record.orcamento_anterior ? "bg-cyan-2" : "")}
                        pagination={false} dataSource={oracamento.parcelas} columns={[
                        {
                            title: 'Data Vencimento',
                            dataIndex: 'data_vencimento',
                            key: 'data_vencimento',
                        },
                        {
                            title: 'Valor Parcela',
                            dataIndex: 'valor_parcela',
                            key: 'valor_parcela',
                        },
                        {
                            title: 'Forma Pagamento',
                            dataIndex: 'forma_pagamento',
                            key: 'forma_pagamento',
                        }
                    ]} />
                </div>
                :<Empty />}
        </div>
    );
};

export default OrcamentoVisualizar