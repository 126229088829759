import { Button, Table } from 'antd';
import { useState } from "react";

import NegociacaoModal from "./NegociacaoModal";

const { Column, ColumnGroup } = Table;

const data: any[] = [];

for (let i = 0; i <= 30; i++) data.push({
        oracamento: 3,
        n_contrato: "1234432"+i,
        paciente: "Alex Teixeira da Silva",
        data: "27/01/2021",
        valor_final: "R$ 10.773,00",
    })

const OracamentoList: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalBody, setModalBody] = useState<number>(1);
    const [NegociacaoSelected, setNegociacaoSelected] = useState(null);

    async function OpenModal(id: any, key: number) {
        setNegociacaoSelected(id)
        setModalBody(key)
        setIsModalVisible(true);
    }

    const CloseModal = () => {
        setModalBody(1)
        setNegociacaoSelected(null)
        setIsModalVisible(false);
    };
    return <>
        <NegociacaoModal body={modalBody} visible={isModalVisible} onClose={CloseModal} id={NegociacaoSelected} />
        <Table dataSource={data}>
            <Column title="Orçanentos" dataIndex="oracamento" key="oracamento" />
            <Column title="Nro. Contrato" dataIndex="n_contrato" key="n_contrato" />
            <Column title="Nome Paciente" dataIndex="paciente" key="paciente" />
            <Column title="Data" dataIndex="data" key="data" />
            <Column title="Valor Final" dataIndex="valor_final" key="valor_final" />
            <Column
                title=""
                dataIndex="n_contrato"
                key="n_contrato"
                filters={[
                    { text: 'Parcelas Devidas', value: '1' },
                    { text: 'Parcelas Futuras', value: '2' },
                    { text: 'Parcelas Carteira', value: '3' },
                ]}
                render={row => (
                    <>
                        <Button onClick={() => OpenModal(row, 1)} type="default" title="Ver Parcelas">Ver Parcelas</Button>
                    </>
                )}
            />

        </Table>
    </>

}

export default OracamentoList