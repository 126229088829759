import { Col, Empty, Row, Select, Skeleton, Spin } from "antd";
import { RefSelectProps, SelectProps } from "antd/es/select";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";

import { useClinica } from "../contexts";
import { useDebounce } from "../hooks/useDebounce";
import { apiAutoComplete } from "../services/apis";
import { PacienteModel } from "../services/models";
import { formatCpfCnpj } from "../utils/formatCpfCnpj";

export interface RefPacienteSelectProps extends RefSelectProps {
  reset: () => void;
}

interface SelectPacienteProps<ValueType = number>
  extends SelectProps<ValueType, PacienteModel> {
  idClinica?: number;
  comercial?: boolean;
}

export const SelectPaciente = React.forwardRef<
  RefPacienteSelectProps,
  SelectPacienteProps
>((props, ref) => {
  const clinica = useClinica();

  /**
   * Usando esse ref para garantir que apenas o último ajax
   * vai parar de mostrar o loading
   */
  const fetchRef = useRef(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<any>(null);
  const [pacientes, setPacientes] = useState<PacienteModel[]>([]);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState<any>(null);

  /**
   * debounce para evitar de cada alteração realizar uma chamada ajax
   */
  const dSearchText = useDebounce(searchText);

  /**
   * Realiza a busca de pacientes na API.
   */
  useEffect(() => {
    fetchRef.current++;
    const fetchId = fetchRef.current;

    setError(null);
    setPacientes([]);

    /**
     * Não pesquisar sem nome e esconder o loading
     */
    if (!dSearchText) {
      setIsSearching(false);
      return;
    }

    setIsSearching(true);

    /**
     * AbortController é usado para cancelar a requisição anterior
     */
    const abortController = new AbortController();

    apiAutoComplete
      .paciente({
        params: {
          idClinica: clinica.clinicaAtual.id,
          searchText: dSearchText,
          comercial: props.comercial
        },
        signal: abortController.signal,
      })
      .then((response) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        const { data } = response.data;
        setPacientes(data);
      })
      .catch((e) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setError(e);
      })
      .finally(() => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setIsSearching(false);
      });

    /**
     * Retornar uma função para o useEffect para
     * cancelar a requisição anterior caso
     * o valor altere
     */
    return function cancel() {
      abortController.abort();
    };
  }, [clinica.clinicaAtual.id, dSearchText]);

  useEffect(() => {
    setSelected(props.value);
    if (!props.value) {
      return;
    }

    if (typeof props.value === "number") {
      setIsLoading(true);
      apiAutoComplete
        .paciente({
          params: {
            idClinica: clinica.clinicaAtual.id,
            idPaciente: props.value,
          },
        })
        .then((response) => {
          const { data } = response.data;
          if (data.length) {
            setSelected({
              label: data[0].nomePaciente,
              value: data[0].idPaciente,
            });
            // props.onChange?.(data[0].idPaciente, data[0]);
          }
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  /**
   * Adiciona o método reset para permitir limpar o campo
   */
  const selectRef = useRef<RefSelectProps>(null);

  useImperativeHandle(ref, () => ({
    blur: () => selectRef.current?.blur(),
    focus: () => selectRef.current?.focus(),
    scrollTo: (arg) => selectRef.current?.scrollTo(arg),
    reset: () => {
      setSelected(null);
      setSearchText("");
      setPacientes([]);
    },
  }));

  const formatted = pacientes.map((p) => {
    let telefones: any[] = [];

    if (p.cadastro?.telefoneCelular || p.cadastro?.telefoneResidencial) {
      if (p.cadastro?.telefoneCelular) {
        telefones.push(
          <>
            <b> Celular:</b> {p.cadastro?.telefoneCelular}
          </>
        );
      }

      if (p.cadastro?.telefoneResidencial) {
        telefones.push(
          <>
            <b> Telefone:</b> {p.cadastro?.telefoneResidencial}
          </>
        );
      }
    }
    return {
      ...p,
      children: (
        <Row align="middle" className="select-pacientes-item">
          <Col flex={"auto"}>
            <div>{p.nomePaciente}</div>
            {telefones.length > 0 && <small>{telefones}</small>}
          </Col>
          <Col flex={"none"} className="direita">
            <div>
              <b>{p.numeroProntuario}</b>
            </div>
            <div>
              <b>CPF:</b> {formatCpfCnpj(p.cpfPaciente)}
            </div>
          </Col>
        </Row>
      ),
    };
  });

  if (isLoading) {
    return <Skeleton.Input active block />;
  }

  return (
    <Select<number, PacienteModel>
      allowClear
      placeholder={"Digite o nome do paciente"}
      showAction={["click", "focus"]}
      options={formatted}
      fieldNames={{
        label: "children",
        value: "idPaciente",
      }}
      status={error ? "error" : undefined}
      dropdownMatchSelectWidth={false}
      {...props}
      value={selected}
      ref={selectRef}
      filterOption={false}
      notFoundContent={
        isSearching ? (
          <Spin size="small" />
        ) : !dSearchText ? (
          <span>Digite pelo menos uma letra</span>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onChange={(value, option) => {
        setSelected(value);
        props.onChange?.(value, option);
      }}
      onClear={() => {
        setSelected(null);
        setSearchText("");
        props.onClear?.();
      }}
      onSearch={(value) => {
        setSearchText(value);
        props.onSearch?.(value);
      }}
      showSearch
      style={{
        minWidth: "100px",
        ...props.style,
      }}
    />
  );
});
