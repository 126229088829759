import { SearchOutlined } from "@ant-design/icons";
import React from 'react';

import { useCheckout } from './../Context/CheckoutContext';

const procedimentos: any = {
    1: [
        {name: 'APARELHO AUTOLIGADO', amount: 100, region: 'boca' },
        {name: 'APLICAÇÃO DE HIALURONIDASE', amount: 278, region: 'boca' },
        {name: 'CLAREAMENTO A LASER', amount: 278, region: 'boca' },
    ],
    2: [
        {name: 'APLICAÇÃO DE HIALURONIDASE', amount: 278, region: 'arcada' },
        {name: 'BIOESTIMULADOR DE COLÁGENO', amount: 777, region: 'arcada' },
    ],
    3: [
        {name: 'COMPOSTO CIRÚRGICO 1', amount: 223, region: 'quadrante' },
        {name: 'COMPOSTO CIRÚRGICO 3', amount: 223, region: 'quadrante' },
        {name: 'COMPOSTO CIRÚRGICO 2', amount: 221, region: 'quadrante' },
    ],
    4: [
        {name: 'APLICAÇÃO DE HIALURONIDASE', amount: 278, region: 'dente' },
        {name: 'BIOESTIMULADOR DE COLÁGENO', amount: 777, region: 'dente' },
        {name: 'COMPOSTO CIRÚRGICO 1', amount: 223, region: 'dente' },
        {name: 'COMPOSTO CIRÚRGICO 3', amount: 223, region: 'dente' },
        {name: 'COMPOSTO CIRÚRGICO 2', amount: 221, region: 'dente' },
        {name: 'APARELHO AUTOLIGADO', amount: 100, region: 'dente' },
        {name: 'APLICAÇÃO DE HIALURONIDASE', amount: 278, region: 'dente' },
        {name: 'CLAREAMENTO A LASER', amount: 278, region: 'dente' },
    ]
}

const Procedimentos: React.FC = () => {
    const {
        optionFaceSelected,
        optionsFace
    } = useCheckout()

    return <>
        <div className="procedimentos">
            <div className="header">
                Procedimentos Disponíveis
            </div>
            <div className="search">
                <div className="search-box">
                    <input className="search-input" type="text" name="" placeholder="Pesquise" />
                    <a href="#" className="search-btn">
                        <SearchOutlined />
                    </a>
                </div>
            </div>
            <div className="list">
                <strong><small>Procedimentos para {optionsFace[optionFaceSelected]}</small></strong>
                <ul>
                    { procedimentos[optionFaceSelected].map( (p:any, key: number) => <li key={key} className="ui-state-default">{p.name}</li> ) }
                </ul>
            </div>
        </div>
    </>;
};

export default Procedimentos