import { InputNumber } from "antd";
import { InputNumberProps } from "antd/es/input-number";
import React from "react";

const InputMoney = React.forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {

    const locale = "pt-BR";
    const currencyFormatter = (selectedCurrOpt:any) => (value:any) => {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: 'BRL',
        }).format(value);
    };

    const currencyParser = (val: any) => {

        try {
            var group                     = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
            var decimal                   = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
            var reversedVal               = val.replace(new RegExp("\\" + group, "g"), "");
            reversedVal                   = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
            reversedVal                   = reversedVal.replace(/[^0-9.]/g, "");
            const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
            const needsDigitsAppended     = digitsAfterDecimalCount > 2;
            
            if (needsDigitsAppended) {
                reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
            }

            return Number.isNaN(reversedVal) ? 0 : reversedVal;

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <InputNumber
            style={{width: '100%'}}
            {...props}
            ref = {ref}
            formatter={currencyFormatter(React.useState('Brazilian::Real'))}
            parser={currencyParser}
        />
    )
});
export default InputMoney;