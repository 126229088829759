import { ClockCircleOutlined, EyeOutlined, FileTextOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Spin, Table } from 'antd';
import React, { useState } from "react";

import Modal from "./ModalL";

const {Column} = Table;

const data: any[] = [];

for (let i = 0; i <= 3; i++) data.push({
    id: i,
    data: "27/01/2021",
    inicio: "15:53",
    fim: "17:53",
    paciente: "Valcionira Aparecida Volpato Soares (V)",
    dentista: "Dr Lucio",
    Prontuário: "BAN-0007022-BAN",
    status: {
        comparecimento: "15:53",
        espera: "4:3",
        adiantado: parseInt(String(Math.random() * (10 - 0))) % 2 === 0,
    },
})

const ConsultasRealizadasList: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalBody, setModalBody] = useState<number>(1);
    const [ContractSelected, setContractSelected] = useState(null);

    async function OpenModal(id: any, key: number) {
        setContractSelected(id)
        setModalBody(key)
        setIsModalVisible(true);
    }

    const CloseModal = () => {
        setModalBody(1)
        setContractSelected(null)
        setIsModalVisible(false);
    };

    const menu = (row: any) => (
        <Menu onClick={(data) => OpenModal(row, parseInt(data.key))}>
            <Menu.Item key="1">Adicionar Observaçao</Menu.Item>
        </Menu>
    );

    const statusComponent = (status: any) => (
        <div style={{display: "flex", flexDirection: "column" }}>
            <label>
                <ClockCircleOutlined />
            </label>
            <label>Comparecimento: <strong>{status.comparecimento}</strong></label>
            <label style={{marginTop: 10}}>Espera:
                { status.adiantado
                    ? <strong> Adiantado <Spin /></strong>
                    :  <strong> {status.espera}</strong>
                }
            </label>
        </div>
    )

    // @ts-ignore
    return <div id="ConsultasRealizadas">
        <Modal body={modalBody} visible={isModalVisible} onClose={CloseModal} id={ContractSelected}/>

        <Table dataSource={data}>
            <Column title="Data" dataIndex="data" key="data"/>
            <Column title="Início" dataIndex="inicio" key="inicio"/>
            <Column title="Fim" dataIndex="fim" key="fim"/>
            <Column title="Paciente" dataIndex="paciente" key="paciente"
                    render={(row: string, record: any) => (
                        <>
                            <Space size="middle">
                                <a onClick={() => OpenModal(record.id, 2)}><FileTextOutlined /> {row}</a>
                            </Space>
                        </>
                    )}
            />
            <Column title="Dentista" dataIndex="dentista" key="dentista"/>
            <Column title="Prontuário" dataIndex="Prontuário" key="Prontuário"/>
            <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={row => statusComponent(row)}
            />
            <Column
                title=""
                dataIndex="id"
                key="id"
                render={row => (
                    <>
                        <Dropdown.Button onClick={() => OpenModal(row, 3)} overlay={menu.bind(null, row)}>
                            <EyeOutlined/>
                        </Dropdown.Button>
                    </>
                )}
            />
        </Table>
    </div>
}

export default ConsultasRealizadasList

