import "./index.scss";

import {EyeOutlined} from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import {Button, Dropdown, Menu} from 'antd';
import React, {useState} from "react";

import ModalContrato from "./Components/ModalContrato";

export type ContratoItem = {
  key: string;
  clinica: string;
  contrato: string;
  versao: string;
  status: string;
  data: string;
  paciente: string;
  valor_final: string;
};
const tableListDataSource: ContratoItem[] = [];
for (let i = 0; i < 77; i += 1) {
  tableListDataSource.push({
    key: Math.random().toString(16).slice(2)+Math.random().toString(16).slice(2),
    clinica: 'Natal',
    contrato:  Math.floor(Math.random() * (10000 - 1000 + 1) + 1000).toString(),
    versao: '2',
    status: 'finalizado',
    data: '21/01/2019',
    valor_final: 'R$ 5.000,00',
    paciente: 'Franquadora Oral Sin',
  });
}

const Contratos: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ContractSelected, setContractSelected] = useState(null);

  async function OpenModal(row: any) {
    setContractSelected(row.contrato)
    setIsModalVisible(true);
  }

  const CloseModal = () => {
    setContractSelected(null)
    setIsModalVisible(false);
  };

  const menuAcoes = (row: any) => (
      <Menu>
        <Menu.Item key={Math.random().toString(16).slice(2)}>
          <Button type="primary" size="small" style={{width: '100%'}}>Alterar</Button>
        </Menu.Item>
        <Menu.Item key={Math.random().toString(16).slice(2)}>
          <Button type="primary" size="small" style={{width: '100%'}} danger>Cancelar</Button>
        </Menu.Item>
      </Menu>
  );
  const columns: ProColumns<ContratoItem>[] = [
    {
      title: 'Clinica',
      dataIndex: 'clinica',
      valueType: 'select',
      valueEnum: {
        value:"label",

      },
    },
    {
      title: 'Contrato',
      dataIndex: 'contrato',
    },
    {
      hideInSearch: true,
      title: 'Versao',
      dataIndex: 'versao',
    },
    {
      hideInSearch: true,
      title: 'Status',
      dataIndex: 'status',
    },
    {
      hideInSearch: true,
      title: 'Data',
      dataIndex: 'data',
    },
    {
      hideInSearch: true,
      title: 'Valor Final',
      dataIndex: 'valor_final',
    },
    {
      hideInSearch: true,
      title: 'Paciente',
      dataIndex: 'paciente',
    },
    {
      title: 'Nome ou Prontuário',
      hideInDescriptions: true,
      hideInTable: true,
      dataIndex: 'paciente',
    },
    {
      hideInSearch: true,
      title: 'Ações',
      valueType: 'option',
      render: (Dom, row: any) => [
        <Dropdown.Button onClick={() => OpenModal(row)} overlay={menuAcoes.bind(null, row)}>
          <EyeOutlined />
        </Dropdown.Button>
      ],
    },
  ];
  return <PageContainer>
    <ModalContrato visible={isModalVisible} onClose={CloseModal} contratoId={ContractSelected} />
    <ProTable<ContratoItem>
        columns={columns}
        request={(params, sorter, filter) => {
          return Promise.resolve({
            data: tableListDataSource,
            success: true,
          });
        }}
        rowKey="key"
        pagination={{
          showQuickJumper: true,
        }}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        dateFormatter="string"
    />
  </PageContainer>;
};

export default Contratos;