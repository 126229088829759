import { Empty, Tabs } from 'antd';
import React from 'react';

import AprovacaoOrcamento from "./AprovacaoOrcamento";

const { TabPane } = Tabs;
const Aprovacao: React.FC = () => {

    return <>
        <Tabs tabPosition="left" style={{backgroundColor: "white", padding: 10, }}>
            <TabPane tab="ORÇAMENTOS" key="1">
                <AprovacaoOrcamento />
            </TabPane>
            <TabPane tab="CONTRATOS" key="2">
                <Empty style={{height: 300, marginTop: 50}} description="NÃO EXISTEM CONTRATOS PARA ESTA DATA"/>
            </TabPane>
        </Tabs>
    </>;
};

export default Aprovacao