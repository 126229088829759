import { PageContainer, PageLoading } from "@ant-design/pro-layout";
import { Button, Result } from "antd";
import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { useAuth, useClinica } from "./contexts";
import GerenciadorClinica from "./pages/Admin/GerenciadorClinica";
import Profissao from "./pages/Admin/Profissao";
import { AgendaCompromisso } from "./pages/AgendaCompromisso";
import { AgendaUsuarioCompromisso } from "./pages/AgendaUsuarioCompromisso";
import { AgendaUsuarioHorario } from "./pages/AgendaUsuarioHorario";
import APagar from "./pages/APagar";
import AReceber from "./pages/AReceber";
import Bancos from "./pages/Bancos";
import Consultas from "./pages/consultas";
import Contratos from "./pages/Contratos";
import Home from "./pages/Home";
import ControlePedido from "./pages/Laboratorio/ControlePedido";
import OrdemServico from "./pages/Laboratorio/OrdemServico";
import { Legado } from "./pages/Legado";
import Login from "./pages/Login";
import Orcamentos from "./pages/Orcamentos";
import NovoOrcamento from "./pages/Orcamentos/create";
import EmissaoRaioX from "./pages/Paciente/EmissaoRaioX";
import PlanejamentoTratamento from "./pages/Paciente/PlanejamentoTratamento";
import PageLayout from "./pages/PageLayout";

const Agenda = React.lazy(() => import("./pages/Agenda"));

const RequireAuth: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};

const RedirectPaginaPadrao: React.FC = () => {
  const clinica = useClinica();

  const sigla = clinica.clinicaPrincipal.sigla;

  return <Navigate to={`/${sigla}/home`} replace={true} />;
};

const SetClinicaFromURL: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasClinica, setHasClinica] = useState(false);

  const { clinicas, setClinicaAtual, clinicaPrincipal } = useClinica();
  const { siglaClinica } = useParams();
  const navigate = useNavigate();

  const verificaClinica = () => {
    const clinica = clinicas.find((c) => c.sigla === siglaClinica);

    if (clinica) {
      setHasClinica(true);
      setClinicaAtual(clinica.id!);
    } else {
      setHasClinica(false);
      setClinicaAtual(clinicaPrincipal.id);
    }
    setIsLoading(false);
  };

  useEffect(verificaClinica, [
    clinicas,
    clinicaPrincipal.id,
    siglaClinica,
    setClinicaAtual,
    navigate,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(verificaClinica, []);

  if (isLoading) {
    return <PageLoading size="large" />;
  }

  if (hasClinica) {
    return <Outlet />;
  }

  return (
    <PageLayout>
      <PageContainer>
        <Result
          status="404"
          title="404"
          subTitle="Clínica não encontrada."
          extra={
            <Link to="/">
              <Button type="primary">Voltar para a página inicial</Button>
            </Link>
          }
        />
      </PageContainer>
    </PageLayout>
  );
};

const NotFound: React.FC = () => (
  <Result
    status="404"
    title="404"
    subTitle="Desculpe, a página que você visitou não existe."
    extra={
      <Link to="/">
        <Button type="primary">Voltar para a página inicial</Button>
      </Link>
    }
  />
);

const App: React.FC = () => {
  const auth = useAuth();

  if (auth.isCheckingAuthenticated) {
    return <PageLoading size="large" />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<RequireAuth />}>
        <Route index element={<RedirectPaginaPadrao />} />
        <Route path=":siglaClinica" element={<SetClinicaFromURL />}>
          <Route index element={<Navigate to="home" replace={true} />} />
          <Route element={<PageLayout />}>
            <Route path="legado" element={<Legado />} />
            <Route path="home" element={<Home />} />
            <Route path="consultas" element={<Consultas />} />
            <Route path="contratos" element={<Contratos />} />
            <Route path="orcamentos" element={<Orcamentos />} />
            <Route path="orcamentos/novo" element={<NovoOrcamento />} />
            <Route path="agenda">
              <Route path="compromissos" element={<AgendaCompromisso />} />
              <Route path="agenda-compromissos" element={<AgendaUsuarioCompromisso />} />
              <Route index element={<Agenda />} />
              <Route path="agenda-usuarios-horarios" element={<AgendaUsuarioHorario />} />
            </Route>
            <Route path="financeiro">
              <Route path="a-pagar" element={<APagar />} />
              <Route path="a-receber" element={<AReceber />} />
              <Route path="bancos" element={<Bancos />} />
            </Route>
            {/* end-financeiro */}
            <Route path="administracao">
              <Route path="profissao" element={<Profissao />} />
              <Route path="gerenciar" element={<GerenciadorClinica />} />
            </Route>
            {/* end-administracao */}
            <Route path="paciente">
              <Route path="raio-x" element={<EmissaoRaioX />} />
              <Route path="planejamento" element={<PlanejamentoTratamento />} />
            </Route>
            {/* end-paciente */}
            <Route path="laboratorio">
              <Route path="controle-pedido" element={<ControlePedido />} />
              <Route path="ordem-servico" element={<OrdemServico />} />
            </Route>
            {/* end-laboratorio */}
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* end-PageLayout */}
          {/* <Route path="/emissao" element={<Emissao />} /> */}
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
