import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { ModalProps } from './../types';
import ContratoParcelas from "./ContratoParcelas";
import OrcamentoObservacao from "./OrcamentoObservacao";

const ContratoModal: React.FC<ModalProps> = (props) => {
    const titles: any = {
        1: {
            title: "Parcelas",
            cancelText: "Fechar",
            okText: "Formalizar"
        },
        3: {
            title: "Observação",
            cancelText: "Cancelar",
            okText: "Ok"
        }
    }
    const [id, setId] = useState<any | null>(null),
          [body, setBody] = useState<number | null>(null),
          [modalConf, setModalConf] = useState<any | null>(titles[1])

    function initializeContract(){
        setId(props.id)
        setBody(props.body)

        if(props.body){
            setModalConf(titles[props.body])
        } else {
            setModalConf(titles[1])
        }
    }

    function callback(key: any) {
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.body]);

    return <Modal
        title={modalConf.title}
        open={props.visible}
        cancelText={modalConf.cancelText}
        okText={modalConf.okText}
        footer={false}
        width="1200px"
        onCancel={async (e) => {
            props.onClose();
        }}
    >
        {
            body === 3 && <OrcamentoObservacao />
        }
        {
            body === 1 && <ContratoParcelas id={id} />
        }
    </Modal>;
}

export default ContratoModal;