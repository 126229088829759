import ProCard from "@ant-design/pro-card";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, FormInstance } from "antd";
import React, { useEffect, useState } from "react";

import Comparativos from "./Comparativos";
import ModalEspecialidades from "./Especialidades";
import { Evolucao } from "./types";

interface EvolucaoDados {
  visible: boolean;
  evolucao: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const PlanejamentoTratamento: React.FC<EvolucaoDados> = () => {
  const formRef = React.createRef<FormInstance>();


  const onReset = () => {
     formRef.current!.resetFields();
  };
  
  const [dados, setDados] = useState<Evolucao[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: Evolucao[] = [];

    for (let i = 0; i < 1; i += 1) {
      tableList.push({
        procedimento: "COROA METALO CERÂMICA SOBRE IMPLANTE",
        qtdPV: "9",
        consultas: "0",
      });
    }
    setDados(tableList);
  }, []);
  const columns: ProColumns[] = [
    {
      title: "Procedimento",
      dataIndex: "procedimento",
    },
    {
      title: "Quantidade De Procedimentos Vendidos",
      dataIndex: "qtdPV",
    },
    {
      title: "Consultas Necessárias por Procedimento",
      dataIndex: "consultas",
    },
  ];

  return (
    <>
      <br />
      <div style={{ textAlign: "center" }}>
        <h3>Evolução</h3>
      </div>
      <br />
      <ProCard>
        <ProTable<Evolucao>
          columns={columns}
          request={(params, sorter, filter) => {
            return Promise.resolve({
              data: tableList,
              success: true,
            });
          }}
          rowKey="key"
          pagination={{
            showQuickJumper: true,
          }}
          search={false}
          dateFormatter="string"
        />
        <ModalEspecialidades />
        <Comparativos />
        <br />
        <div style={{ textAlign: "center" }}>
        <Button type="primary" htmlType="button" onClick={onReset}>
            salvar
          </Button>{" "}
        </div>
      </ProCard>
    </>
  );
};

export default PlanejamentoTratamento;
