import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ProForm, {
  ProFormDatePicker,
  ProFormSelect,
} from "@ant-design/pro-form";
import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { InputMoney } from "./components";

export type ParcelasLista = {
  key: number;
  parcela: number;
  vParcela: string;
  vencimento: dayjs.Dayjs;
  datavenc: string;
  emissao: string;
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: ParcelasLista;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputMoney />
    ) : (
      <ProFormDatePicker
        fieldProps={{ format: "DD/MM/YYYY" }}
        formItemProps={{ className: "MarginBottom0FormInput" }}
      />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export function ModalParcelamento(props: any) {
  const [form] = Form.useForm();
  const [parcelas, setParcelas] = useState<ParcelasLista[]>([]);
  const [totalParcelas, setTotalParcelas] = useState<number>(0);
  const [editingKey, setEditingKey] = useState<number>(0);
  const [diaVencimento, setDiaVencimento] = useState<number>(0);

  const isEditing = (record: ParcelasLista) => record.key === editingKey;

  const edit = (row: Partial<ParcelasLista> & { key: React.Key }) => {
    form.setFieldsValue({
      parcela: "",
      vParcela: "",
      vencimento: "",
      emissao: "",
      ...row,
    });
    setEditingKey(row.key);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as ParcelasLista;
      const newData = [...parcelas];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        let dataV = dayjs(row.vencimento);

        newData[index]["vParcela"] = row.vParcela;
        newData[index]["vencimento"] = dataV;
        newData[index]["datavenc"] = dataV.format("DD/MM/YYYY");

        setParcelas(newData);
        setEditingKey(0);
      } else {
        newData.push(row);
        setParcelas(newData);
        setEditingKey(0);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Parcela",
      dataIndex: "parcela",
      key: "parcela",
      width: 80,
    },
    {
      title: "Valor Parcela",
      dataIndex: "vParcela",
      key: "vParcela",
      width: 100,
      editable: true,
    },
    {
      title: "Data Vencimento",
      dataIndex: "vencimento",
      key: "vencimento",
      width: 100,
      editable: true,
      render: (row: any, record: ParcelasLista) => {
        return record.datavenc;
      },
    },
    {
      title: "Data Emissão",
      dataIndex: "emissao",
      key: "emissao",
      width: 100,
    },
    {
      title: "",
      key: "action",
      width: 50,
      render: (row: any, record: ParcelasLista) => {
        const editable = isEditing(row);

        return editable ? (
          <span>
            <Button type="link" size="small" onClick={() => save(row.key)}>
              <SaveOutlined />
            </Button>
            <Button
              type="link"
              size="small"
              className="txt-danger"
              onClick={cancel}
            >
              <CloseCircleOutlined />
            </Button>
          </span>
        ) : (
          <Button
            type="link"
            size="small"
            disabled={editingKey !== 0}
            onClick={() => edit(row)}
          >
            <EditOutlined />
          </Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ParcelasLista) => ({
        record,
        inputType: col.dataIndex === "vParcela" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const ListaParcelas = (value: number) => {
    setParcelas([]);
    setTotalParcelas(totalParcelas);

    let data = [];
    let vP = props.valorParcela / value;
    let vD = vP.toFixed(2);
    let vencInicial = dayjs(props.dataVencimento);
    let venc = vencInicial;
    let emis = props.dataEmissao;

    data[0] = {
      key: 1,
      parcela: 1,
      vParcela: vD,
      vencimento: vencInicial,
      datavenc: vencInicial.format("DD/MM/YYYY"),
      emissao: emis,
    };

    if (value > 1) {
      for (var i = 1; i < value; i++) {
        venc = vencInicial.add(i, "month");

        data[i] = {
          key: i + 1,
          parcela: i + 1,
          vParcela: vD,
          vencimento: venc,
          datavenc: venc.format("DD/MM/YYYY"),
          emissao: emis,
        };
      }

      let diff = props.valorParcela - value * Number(vD);
      let nVD = Number(vD) + Number(diff);

      data[value - 1]["vParcela"] = nVD.toFixed(2);
    }

    setParcelas(data);
    setTotalParcelas(props.valorParcela);
  };

  const NovoVencimento = (value: number) => {
    setDiaVencimento(value);
  };

  const AtualizaVencimento = () => {
    let data = parcelas;

    for (var i = 0; i < data.length; i++) {
      let vencimento = data[i]["vencimento"];
      let testando = vencimento.set("date", diaVencimento);

      data[i]["vencimento"] = vencimento;
      data[i]["datavenc"] = testando.format("DD/MM/YYYY");
    }

    setParcelas(data);
    let newData = parcelas[0]["vencimento"];
    props.setDataVencimento(newData.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    ListaParcelas(1);
  }, []);

  return (
    <Modal
      title="Parcelamento"
      open={props.visible}
      onCancel={async (e) => {
        props.onClose();
      }}
      onOk={async (e) => {}}
      width={800}
      centered
      footer={false}
    >
      <Row gutter={24}>
        <Col span={12}>
          Valor Parcela: <b>R$ {props.valorParcela}</b>
        </Col>
        <Col span={12}>
          Qtde. Parcelas:{" "}
          <InputNumber
            min={1}
            max={99}
            defaultValue={1}
            onChange={(value) => {
              ListaParcelas(value as number);
            }}
            disabled={editingKey !== 0}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Card
            size="small"
            className="listaParcelas"
            title="Lista de Parcelas"
          >
            <Form form={form} component={false}>
              <Table
                pagination={false}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={parcelas}
                columns={mergedColumns}
                rowClassName="editable-row"
                scroll={{ y: 140 }}
                size="small"
                style={{
                  minHeight: "220px",
                }}
                // footer={() => 'Total a pagar: R$ '+totalParcelas}
              />
            </Form>
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={14}>
          <ProForm.Item name="diaFixo" label="Fixar dia do vencimento:">
            <Space>
              <InputNumber
                min={1}
                max={31}
                value={diaVencimento}
                onChange={(value: number | null) =>
                  NovoVencimento(value as number)
                }
              />
              <Button
                size="middle"
                icon={<SyncOutlined />}
                type="primary"
                onClick={AtualizaVencimento}
              >
                Atualizar
              </Button>
            </Space>
          </ProForm.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={14}>
          <ProForm.Item name="mesInicio" label="Iníco para o mes:">
            <Space className="MarginBottom0FormInput">
              <ProFormSelect />
              <Button size="middle" icon={<SyncOutlined />} type="primary">
                Atualizar
              </Button>
            </Space>
          </ProForm.Item>
        </Col>
      </Row>
    </Modal>
  );
}
