import ProList from "@ant-design/pro-list";
import React from "react";

const PlanejamentoTratamento: React.FC = () => {
  const dataSource = [
    {
      key: 1,
      comparativo: "Consultas pré-definidas:",
      desc: "2",
    },
    {
      key: 2,
      comparativo: "Consultas selecionadas:",
      desc: "0",
    },
  ];

  return (
    <>
      <p className="title" style={{ marginBottom: 10, marginTop: 0 }}>
        COMPARATIVO
      </p>
      <ProList
        rowKey="key"
        dataSource={dataSource}
        metas={{
          title: {
            dataIndex: "comparativo",
          },
          subTitle: {
            dataIndex: "desc",
          },
        }}
      />
    </>
  );
};

export default PlanejamentoTratamento;
