import { PageHeader } from '@ant-design/pro-layout';
import {Button, Card, Empty, InputNumber, Select, Table} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useState} from 'react';

import {ParcelasVisualizarProps} from "../types";
import NegociacaoFParcela from "./NegociacaoFParcela";

const ParcelasVisualizar: React.FC<ParcelasVisualizarProps> = (props) => {
    const [parcelas, setParcelas] = useState<any | null>(null);
    const [tipoJuros, setTipoJuros] = useState<string>("2");
    const [qtdPagamentos, setQtdPagamentos] = useState<number>(1);
    function initializeContract(){
        if(props.id){
            const newParcelas: any = {
                id: props.id,
                paciente: "Aarao Lyra",
                list:[]
            }
            for (let v = 0; v < 2; v += 1){
                newParcelas.list.push({
                    id: (444+v).toString(),
                    parcela: v,
                    status: "Não Compensado",
                    data: "22/01/2019",
                    data_vencimento: "22/01/2019",
                    valor_parcela: "R$ 1.000,00",
                    forma_pagamento: "Boleto Bancário(Banco Sicredi)",
                })
            }

            setParcelas(newParcelas)
        } else{
            setParcelas(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    function handleChange(value: string) {
        setTipoJuros(value)
    }
    return (
        <div>
            {parcelas ?
                <div className="parcelasDetalhes" >
                    <PageHeader
                        className="site-page-header"
                        title="Paciente"
                        subTitle={parcelas.paciente}
                    />
                    <p>Pendências</p>
                    <Table
                        pagination={false} dataSource={parcelas.list} columns={[
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            key: 'id',
                        },
                        {
                            title: 'Parcelas',
                            dataIndex: 'parcelas',
                            key: 'parcelas',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                        },
                        {
                            title: 'Data',
                            dataIndex: 'data',
                            key: 'data',
                        },
                        {
                            title: 'Data Vencimento',
                            dataIndex: 'data_vencimento',
                            key: 'data_vencimento',
                        },
                        {
                            title: 'Valor Parcela',
                            dataIndex: 'valor_parcela',
                            key: 'valor_parcela',
                        },
                        {
                            title: 'Forma Pagamento',
                            dataIndex: 'forma_pagamento',
                            key: 'forma_pagamento',
                        }
                    ]} />
                    <div className="site-card-border-less-wrapper">
                        <Card size="small" title="Multa/Juros Padrão">
                            <table>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Multa { tipoJuros === "2" ? "(%)":"(R$)" }</th>
                                    <th>Juros { tipoJuros === "2" ? "(%)":"(R$)" }</th>
                                </tr>
                                <tr>
                                    <td>
                                        <Select defaultValue={tipoJuros} style={{ width: 200 }} onChange={handleChange}>
                                            <Select.Option value="1">Valor Total(R$)</Select.Option>
                                            <Select.Option value="2">Porcentual(%)</Select.Option>
                                        </Select>
                                    </td>
                                    <td>
                                        { tipoJuros === "2" && <InputNumber
                                            defaultValue={4}
                                            min={0}
                                            max={100}
                                            formatter={value => `${value}%`}
                                            parser={(value:any) => value.replace('%', '')}
                                        /> }
                                        {
                                            tipoJuros === "1" && <InputNumber
                                                defaultValue={0}
                                                formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value:any) => value.replace(/\R$\s?|(,*)/g, '')}
                                            />
                                        }
                                    </td>
                                    <td>
                                        { tipoJuros === "2" && <InputNumber
                                            defaultValue={.4}
                                            min={0}
                                            max={100}
                                            formatter={value => `${value}%`}
                                            parser={(value:any) => value.replace('%', '')}
                                        /> }
                                        {
                                            tipoJuros === "1" && <InputNumber
                                                defaultValue={0}
                                                formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value:any) => value.replace(/\R$\s?|(,*)/g, '')}
                                            />
                                        }
                                    </td>
                                </tr>
                            </table>
                        </Card>
                    </div>
                    <p>Parcelas a Formalizar</p>
                    <div className="formas-pagmentos">
                        <Card size="small">
                            <table>
                                <tr>
                                    <th colSpan={5}>
                                       <p className="title" style={{marginBottom: 10, marginTop: 0}}>
                                           Selecione a quantidade de formas de pagamento:
                                       </p>
                                    </th>
                                </tr>
                                <tr>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(1)} shape="circle">1</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(2)} shape="circle">2</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(3)} shape="circle">3</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(4)} shape="circle">4</Button> </td>
                                    <td><Button type="primary" onClick={() => setQtdPagamentos(5)} shape="circle">5</Button> </td>
                                </tr>
                            </table>
                            <table className="table-payment" style={{marginTop: 20}}>
                                <tr>
                                    <th>Forma Pgto.</th>
                                    <th>Valor</th>
                                    <th>Nro Parcelas</th>
                                    <th>Vencimento 1ª</th>
                                    <th>Valor Parcelaª</th>
                                </tr>
                                {Array.from({ length: qtdPagamentos }).map(() => {
                                    return  <NegociacaoFParcela />;
                                })}
                            </table>
                            <TextArea style={{marginTop: 20}}
                                placeholder="Observação"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Card>
                    </div>
                </div>
                :<Empty />}
        </div>
    );
};

export default ParcelasVisualizar