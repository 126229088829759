import { DeleteOutlined, MonitorOutlined } from "@ant-design/icons";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Dropdown, Menu, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";

import ModalSelecioneAdd from "./ModalSelecioneAdd";
import { AddServico } from "./typex";

interface addServico {
  visible: boolean;
  addServico: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const menuAcoes = (key: any) => {
  return (
    <Menu>
      <Menu.Item key="1" danger icon={<DeleteOutlined />}>
        <Popconfirm
          title="Esta certo disso ?"
          onConfirm={() => {}}
          onCancel={() => {}}
          placement="top"
        >
          Deletar
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
};

const OrdemServico: React.FC<addServico> = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selecioneAdd, setSelecioneAdd] = useState(false);

  async function OpenModal(row: any) {
    if (row) {
      setSelecioneAdd(row);
    } else {
      setSelecioneAdd(false);
    }
    setIsModalVisible(true);
  }
  const [dados, setDados] = useState<AddServico[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: AddServico[] = [];

    for (let i = 0; i < 1; i += 1) {
      tableList.push({
        setor: "CAD/CAM	",
        servico: "ENCERAMENTO DE DIAGNOSTICO POR ELEMENTO",
        dente: "Arcada total superior	",
      });
    }
    setDados(tableList);
  }, []);

  const columns: ProColumns<AddServico>[] = [
    {
      title: "Setor",
      dataIndex: "setor",
    },
    {
      title: "Serviço",
      dataIndex: "servico",
    },
    {
      title: "Dente(s)",
      dataIndex: "dente",
    },
    {
      title: "Ações",
      key: "action",
      render: (dom, row) => (
        <span>
          <Dropdown.Button
            onClick={() => OpenModal(row)}
            overlay={menuAcoes(row)}
            trigger={["click"]}
          >
            <MonitorOutlined />
            Visualizar
          </Dropdown.Button>{" "}
        </span>
      ),
    },
  ];

  return (
    <>
      {selecioneAdd && (
        <ModalSelecioneAdd
          visible={isModalVisible}
          selecioneAdd={selecioneAdd}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      <ProTable<AddServico>
        columns={columns}
        request={(params, sorter, filter) => {
          return Promise.resolve({
            data: tableList,
            success: true,
          });
        }}
        rowKey="key"
        pagination={false}
        search={false}
        dateFormatter="string"
      />
    </>
  );
};

export default OrdemServico;
