import "./index.scss";

import TabPane from "@ant-design/pro-card/es/components/TabPane";
import { ProFormUploadButton } from "@ant-design/pro-form";
import { Modal, Tabs } from "antd";
import React from "react";

interface ModalGratuito {
  visible: boolean;
  imagemGratuita: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const emissaoRaioX: React.FC<ModalGratuito> = (props) => {
  return (
    <>
      <Modal
        title={"Imagens de Raio-X"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Nome Paciente" key="1">
            <ProFormUploadButton
              label="CPF"
              name="file"
              title="Adicionar Nova Imagens"
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default emissaoRaioX;
