import ProForm, { ProFormText } from "@ant-design/pro-form";
import { Modal } from "antd";
import React from "react";

interface ModalAddInstuicao {
  visible: boolean;
  instituicao: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const Gerenciar: React.FC<ModalAddInstuicao> = (props) => {


  return (
    <>
      <Modal
        title={"Instituições"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <ProForm submitter={false}>
        <ProForm.Group>
          <ProFormText name="nome" label="Digite o nome da nova instituicao:"></ProFormText>
        </ProForm.Group>
      </ProForm>
      </Modal>
    </>
  );
};

export default Gerenciar;
