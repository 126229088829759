import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { ModalProps } from './../types';
import ContratoParcelaDataVencimento from "./ContratoParcelaDataVencimento";
import ContratoParcelaPagante from "./ContratoParcelaPagante";
import ContratoParcelaPaganteAlterar from "./ContratoParcelaPaganteAlterar";

const ContratoModal: React.FC<ModalProps> = (props) => {
    const titles: any = {
        1: {
            title: "DETALHES DA PARCELA",
            cancelText: "Fechar",
            okText: "Ok"
        },
        3: {
            title: "Alterar pagante",
            cancelText: "Cancelar",
            okText: "Ok"
        },
        2: {
            title: "ALTERAR DATAS DE VENCIMENTO",
            cancelText: "Cancelar",
            okText: "Ok"
        }
    }
    const [id, setId] = useState<any | null>(null),
        [body, setBody] = useState<number | null>(null),
        [modalConf, setModalConf] = useState<any | null>(titles[1])

    function initializeContract(){
        setId(props.id)
        setBody(props.body)

        if(props.body){
            setModalConf(titles[props.body])
        } else {
            setModalConf(titles[1])
        }
    }

    function callback(key: any) {
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.body]);

    return <Modal
        title={modalConf.title}
        open={props.visible}
        footer={false}
        width="700px"
        onCancel={async (e) => {
            props.onClose();
        }}
    >
        {
            body === 3 && <ContratoParcelaPaganteAlterar id={"1"} />
        }
        {
            body === 2 && <ContratoParcelaDataVencimento id="1" />
        }
        {
            body === 1 && <ContratoParcelaPagante id="1" />
        }
    </Modal>;
}

export default ContratoModal;