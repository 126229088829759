import { Col, Empty, Row } from "antd";
import Text from "antd/es/typography/Text";
import React, { useState } from 'react';

const PacienteInfo: React.FC = () => {
    const [usuario] = useState<any | null>({
        nome: "Ayla Amabyla Teixeira Mendonca",
        idade: "28 anos",
        profissao: "Vendedor",
        como_conheceu: "PACIENTE",
        prontuario: "BAN-0009756-BAN",
        estado: "Paraná",
        cidade: "Londrina",
        bairro: "Jd Viena",
        endereco: "Rua Luiz Antonio Mayrink Goes 810",
    });
    return (
        <div>
            {usuario ?
                <div className="usuarioDetalhes" >
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.nome}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Nome:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.idade}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Idade:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.profissao}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Profissão:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.como_conheceu}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Como Conheceu:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.prontuario}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Prontuário:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.estado}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Estado:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.cidade}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Cidade:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.bairro}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Bairro:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.endereco}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Endereço:</Text>
                        </Col>
                    </Row>
                </div>
                :<Empty />}
        </div>
    );
};

export default PacienteInfo