/* eslint-disable no-restricted-globals */
import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Menu, Space, Spin } from "antd";
import React, { useState } from "react";

import { useAuth } from "../../contexts";
import HeaderDropdown from "../HeaderDropdown";
import styles from "./index.module.scss";

export type UserHeaderDropdownProps = {
  menu?: boolean;
};

const UserHeaderDropdown: React.FC<UserHeaderDropdownProps> = ({ menu }) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const auth = useAuth();

  const currentUser = auth.user;

  if (
    !currentUser ||
    auth.isCheckingAuthenticated ||
    auth.isLogouting ||
    auth.isLogging
  ) {
    const loading = (
      <span className={`${styles.action} ${styles.account}`}>
        <Spin
          size="small"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        />
      </span>
    );

    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]}>
      <Menu.Item key="center" className={styles.item} onClick={hide}>
        <Space>
          <UserOutlined />
          Centro pessoal
        </Space>
      </Menu.Item>

      <Menu.Item key="settings" onClick={hide}>
        <Space>
          <SettingOutlined />
          Configurações pessoais
        </Space>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item
        key="logout"
        onClick={() => {
          hide();
          auth.logout();
        }}
      >
        <Space>
          <LogoutOutlined />
          Sair
        </Space>
      </Menu.Item>
    </Menu>
  );

  const parts = (currentUser.nome || currentUser.usuario).split(/\s+/);
  const primeiroNome = parts.shift() || "Usuário";

  return (
    <HeaderDropdown
      content={menuHeaderDropdown}
      placement={"bottomRight"}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar size="small" className={styles.avatar} alt="avatar">
          {currentUser.nome.toUpperCase().substr(0, 3)}
        </Avatar>
        <span className={`${styles.name} anticon`}>{primeiroNome}</span>
        <DownOutlined style={{ marginLeft: "2px" }} />
      </span>
    </HeaderDropdown>
  );
};

export default UserHeaderDropdown;
