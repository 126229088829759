import "./create.scss";

import { DollarCircleOutlined, ForkOutlined, ShoppingCartOutlined, SlackSquareOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import React, { useState } from "react";

import { CheckoutProvider } from './Context/CheckoutContext';
import Checkout from "./CreateComponents/Checkout";
import CModal from "./CreateComponents/CModal";
import Finaceiro from "./CreateComponents/Financeiro";
import Odontograma from "./CreateComponents/Odontograma";
import Procedimentos from "./CreateComponents/Procedimentos";

const { TabPane } = Tabs;
const NovoOrcamento: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalBody, setModalBody] = useState<number>(1);
  const [CotratoSelected, setCotratoSelected] = useState(null);

  async function OpenModal(id: any, key: number) {
    setCotratoSelected(id)
    setModalBody(key)
    setIsModalVisible(true);
  }
  const CloseModal = () => {
    setModalBody(1)
    setCotratoSelected(null)
    setIsModalVisible(false);
  };
  return <>
    <CheckoutProvider>
      <CModal visible={isModalVisible} id={CotratoSelected} body={modalBody} onClose={CloseModal} />
      <div className='header-tab'>
        <Button onClick={()=>OpenModal(1,1)} type="primary" icon={<ForkOutlined />} size='large'>
          Paciente
        </Button>
        <Button onClick={()=>OpenModal(2,2)} type="primary" icon={<SlackSquareOutlined />} size='large'>
          Imagens Raio X
        </Button>
      </div>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab={<span><ShoppingCartOutlined />ORÇAMENTO</span>} key="1">
          <div className={'novo-orcamento'}>
            <Procedimentos />
            <Odontograma />
            <Checkout />
          </div>
        </TabPane>
        <TabPane tab={<span><DollarCircleOutlined />FINANCEIRO</span>} key="2">
          <Finaceiro id='2' />
        </TabPane>
      </Tabs>
    </CheckoutProvider>
  </>;
};

export default NovoOrcamento;