import api from "../../api";
import { PacienteModel } from "../../models";
import { SingleResponse } from "../../utilities";

export function get(idPaciente: number, options: {} = {}) {
  return api.get<SingleResponse<PacienteModel>>(
    `cadastros/pacientes/${idPaciente}`,
    options
  );
}
