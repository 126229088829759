import { ProFormSelect } from '@ant-design/pro-form';
import axios from "axios";
import React, { useEffect,useState} from "react";

import api from "../services/api";

function CentroCusto(props: any){

    const [centroCusto, setCentroCusto]       = useState();
    const [loadCentroCusto, setLoadCentroCusto] = useState(false);

    const listaCentroCusto = async () => {
        
        setLoadCentroCusto(true);
        let lista: any = [];

        try {
            const { data } = await api.get("financeiro/list-centro-custo", {});
            
            data.map(function (result: any, index: number) {
                lista[index] = { value: result.idCentroCusto, label: result.centroCusto }; 
            });

            setCentroCusto(lista);
            setLoadCentroCusto(false);
            
        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        listaCentroCusto();
      }, []);

    return (

        <ProFormSelect
            options={centroCusto}
            name="centroCusto"
            showSearch={true}
            fieldProps={{
                loading: loadCentroCusto
            }}
        />
            
    )    

}
export default CentroCusto;
