/* eslint-disable no-restricted-globals */
import { HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, InputRef,List, Menu, Modal } from "antd";
import { ButtonType } from "antd/es/button";
import Text from "antd/es/typography/Text";
import Fuse from "fuse.js";
import React, { Ref,useEffect, useState  } from "react";
import { Location,useLocation, useNavigate } from "react-router-dom";

import { useClinica } from "../../contexts";
import HeaderDropdown from "../HeaderDropdown";
import styles from "./index.module.scss";

export type ClinicaHeaderDropdownProps = {
  menu?: boolean;
};

const getNewUrl = (location: Location, sigla: string) => {
  const parts = location.pathname.split("/");

  if (/^[A-Z]{3}$/.test(parts[1])) {
    parts[1] = sigla;
  }

  let url = parts.join("/");

  if (location.search) {
    url += location.search;
  }
  if (location.hash) {
    url += location.hash;
  }

  return url;
};

const ClinicasModalMenu = (props: {
  inputRef: Ref<InputRef>;
  onShowModal?: () => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const clinica = useClinica();
  const location = useLocation();
  const navigate = useNavigate();

  const inputRef = React.createRef<InputRef>();

  const showModal = (filtro = "") => {
    setFiltro(filtro);
    setIsModalVisible(true);
    setCurrentPage(1);
    props.onShowModal?.();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClinicaClick = (sigla: string) => {
    const fullPath = getNewUrl(location, sigla);

    navigate(fullPath, {
      state: location.state,
    });
    setIsModalVisible(false);
  };

  const fuse = new Fuse(clinica.clinicas, {
    useExtendedSearch: true,
    keys: ["nome"],
    threshold: 0.3,
  });

  const searchResult = fuse.search(filtro);

  const clinicas = searchResult.map((r) => r.item);

  if (!clinicas.length && !filtro) {
    clinicas.push(...clinica.clinicas);
  }

  return (
    <>
      <Menu.Divider />

      <Menu.Item
        key={"search"}
        onClick={() => showModal("")}
        style={{ padding: 0 }}
      >
        <Input
          ref={props.inputRef}
          size="large"
          placeholder="Procurar"
          prefix={<SearchOutlined />}
          value={""}
          onChange={(e) => showModal(e.target.value)}
        />
      </Menu.Item>

      <Modal
        title="Selecionar uma Clínica"
        style={{ top: 15 }}
        open={isModalVisible}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        modalRender={(node) => {
          inputRef.current?.focus();
          return node;
        }}
      >
        <Input
          ref={inputRef}
          value={filtro}
          placeholder="Digite o nome da unidade"
          autoFocus
          onChange={(e) => setFiltro(e.target.value)}
        />

        <Divider />

        <List
          grid={{
            gutter: 16,
            column: 1,
            // xs: 1,
            // sm: 2,
            // md: 2,
            // lg: 2,
            // xl: 3,
            // xxl: 3,
          }}
          dataSource={clinicas}
          renderItem={(item) => {
            let type: ButtonType = "default";
            let danger = false;

            if (item.id === clinica.clinicaAtual?.id) {
              type = "primary";
            } else if (item.nome.toLowerCase().includes("desativad")) {
              danger = true;
            }

            return (
              <List.Item>
                <Button
                  block
                  type={type}
                  danger={danger}
                  style={{ textAlign: "left", overflow: "hidden" }}
                  onClick={() => handleClinicaClick(item.sigla)}
                >
                  <HomeOutlined />
                  {item.nome} - {item.sigla}
                </Button>
              </List.Item>
            );
          }}
          pagination={{
            current: currentPage,
            onChange(page) {
              setCurrentPage(page);
            },
          }}
        />
      </Modal>
    </>
  );
};

const ClinicaHeaderDropdown: React.FC<ClinicaHeaderDropdownProps> = () => {
  const clinica = useClinica();

  const location = useLocation();
  const navigate = useNavigate();

  const clinicaAtual = clinica.clinicaAtual;

  const clinicas = clinica.clinicas.slice(0, 5);

  const inputRef = React.createRef<InputRef>();

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleClinicaClick = (sigla: string) => {
    hide();

    const fullPath = getNewUrl(location, sigla);

    navigate(fullPath, {
      state: location.state,
    });
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 200);
    }
  }, [inputRef, open]);

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]}>
      {clinicas.map((c) => (
        <Menu.Item key={c.id} onClick={() => handleClinicaClick(c.sigla)}>
          <HomeOutlined />
          {c.nome} - {c.uf}
        </Menu.Item>
      ))}
      <ClinicasModalMenu inputRef={inputRef} onShowModal={hide} />
    </Menu>
  );

  return (
    <HeaderDropdown
      content={menuHeaderDropdown}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button type="primary" style={{ maxWidth: "150px" }}>
        <Text
          ellipsis={{ suffix: "" }}
          style={{ color: "white", width: "100%" }}
        >
          {clinicaAtual?.nome} - {clinicaAtual?.uf}
        </Text>
      </Button>
    </HeaderDropdown>
  );
};

export default ClinicaHeaderDropdown;
