import "./index.scss";

import { PageContainer } from "@ant-design/pro-layout";
import { Tabs } from 'antd';
import React from "react";

import Aprovacao from "./Components/Aprovacao";
import ContratoList from "./Components/ContratoList";
import HeaderFilter from "./Components/HeaderFilter";
import NegociacaoList from "./Components/NegociacaoList";
import OrcamentoList from "./Components/OrcamentoList";

const { TabPane } = Tabs;

const Orcamentos: React.FC = () => {

  return <PageContainer title="Orçamentos/Contratos">
    <HeaderFilter />
    <div className="oracamentoContent">
      <Tabs defaultActiveKey="4" centered>
        <TabPane tab="APROVACÃO" key="4">
          <Aprovacao />
        </TabPane>
        <TabPane tab="ORÇAMENTOS" key="1">
          <OrcamentoList />
        </TabPane>
        <TabPane tab="CONTRATOS" key="2">
          <ContratoList />
        </TabPane>
        <TabPane tab="NEGOCIAÇÃO" key="3">
          <NegociacaoList />
        </TabPane>
      </Tabs>
    </div>
  </PageContainer>;
};

export default Orcamentos;