import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PageContainer } from "@ant-design/pro-layout";
import ProList from '@ant-design/pro-list';
import { Button, Dropdown, Menu, message,Popconfirm } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from 'react';

import api from "../../services/api";
import ModalBancos from './ModalBancos';
import { Banco, BancoAjax, BancoGetAjax,Pagination} from './types';

const Bancos: React.FC = () => {

    const [lista, setLista]                   = useState<Banco[]>([]);
    const [pagina, setPagina]                 = useState<Pagination>({ current: 1, total: 0, pageSize: 15 });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [banco, setBanco]                   = useState(0);
    const [load, setLoad]                     = useState(false);
    
    const LoadData = async (page = 1, pageSize = 15) => {
        setLoad(true);
        try {
            const { data } = await api.get<BancoAjax>("cadastros/bancos", { params: { page: page, per_page: pageSize } });
            setLista(data.data);
            setPagina({ ...pagina, current: page, total: data.meta.total, pageSize: pageSize });
        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
        setLoad(false);
    }

    useEffect(() => {
        LoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menuAcoes = (key: any) => {
        return (
            <Menu>
                <Menu.Item key={key} danger icon={<DeleteOutlined />}>
                    <Popconfirm
                        title         = "Esta certo disso ?"
                        onConfirm     = {() => deleteOk(key)}
                        placement     = "top"
                    >
                        Deletar
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        )
    };

    async function deleteOk(row: Banco){
        await api.delete<BancoGetAjax>(`cadastros/bancos/${row.idBanco}`);
        message.success("Banco Deletado");
        RelaodList();
    }

    async function OpenModal(row: any) {
        if (row.idBanco) {
            setBanco(row.idBanco);
        } else {
            setBanco(0);
        }
        setIsModalVisible(true);
    }

    const RelaodList = () => {
        LoadData(pagina.current, pagina.pageSize);
    }

    const CloseModal = () => {
        setIsModalVisible(false);
    };

    return (<>
        <ModalBancos banco={banco} visible={isModalVisible} onCancel={CloseModal} loadPage={RelaodList} />
        <PageContainer extra={[
            <Button key="1" type="primary" onClick={OpenModal}>
                Novo Banco
            </Button>
        ]}>
            <ProList<Banco>
                loading={load}
                onRow={(record: any) => {
                    return {
                        onClick: () => {
                            console.log(record);
                        },
                    };
                }}
                rowKey={"idBanco"}
                dataSource={lista}
                pagination={{
                    current: pagina.current, total: pagina.total, pageSize: pagina.pageSize, onChange: (page, pageSize) => {
                        LoadData(page, pageSize);
                    }
                }}
                metas={{
                    title: {
                        dataIndex: 'nome',
                    },
                    description: {
                        dataIndex: 'numero',
                    },
                    actions: {
                        render: (Dom, row) => [
                            <Dropdown.Button key={row.idBanco} onClick={() => OpenModal(row)} overlay={menuAcoes(row)} trigger={['click']}>
                                <EditOutlined />
                                Alterar
                            </Dropdown.Button>
                                 
                        ],
                    },
                }}
            />
        </PageContainer>
    </>);
};

export default Bancos;