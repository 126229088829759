import ProForm, { ProFormCheckbox } from "@ant-design/pro-form";
import { Button, Modal, Tabs } from "antd";
import React, { useState } from "react";

import ModalAddDestincoes from "./ModalAddDestinacoes";

const { TabPane } = Tabs;

interface ModalDestinacao {
  visible: boolean;
  destinacoes: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const Gerenciar: React.FC<ModalDestinacao> = (props) => {
  const [isModalVisibleAddD, setIsModalVisibleAddD] = useState(false);
  const [addDestinacao, setAddDestinacao] = useState(false);

    async function OpenModalAdd(row: any) {
        if (row) {
          setAddDestinacao(row);
        } else {
          setAddDestinacao(false);
        }
        setIsModalVisibleAddD(true);
      }
  return (
    <>
      {addDestinacao && (
        <ModalAddDestincoes
          visible={isModalVisibleAddD}
          addDestinacao={addDestinacao}
          onOk={() => setIsModalVisibleAddD(false)}
          onCancel={() => setIsModalVisibleAddD(false)}
        />
      )}
      <Modal
        title={"Destinações"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Destinações" key="1">
            <ProForm.Group>
              <Button type="primary" onClick={OpenModalAdd}>Cadastrar Nova Finaceira</Button>
            </ProForm.Group>
            <ProFormCheckbox>ACQIO</ProFormCheckbox>
            <ProFormCheckbox>Amex</ProFormCheckbox>
            <ProFormCheckbox>ASAAS</ProFormCheckbox>
            <ProFormCheckbox>Banco Alfa</ProFormCheckbox>
            <ProFormCheckbox>Banco Amazônia</ProFormCheckbox>
            <ProFormCheckbox>Banco Atenas</ProFormCheckbox>
            <ProFormCheckbox>Banco Banpará S/A</ProFormCheckbox>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Gerenciar;
