import { SaveOutlined } from "@ant-design/icons";
import ProForm, {
  ProFormInstance,
  ProFormTextArea,
} from "@ant-design/pro-form";
import { Button, Col, message, Modal, Row } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import api from "../../services/api";
import { ModalNaoCompensadoProps } from "./types";

const ModalLancamento: React.FC<ModalNaoCompensadoProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formRef = useRef<
    ProFormInstance<{
      idContaReceber?: number
      observacao: string
    }>
  >();

  useEffect(() => {
    const VM = props.visible;

    if (VM == true) {
      if (props.obs) {
        GetObs();
      } 
    }
  });

  const GetObs = async () => {
    const { data } = await api.get(`financeiro/nao-compensado/${props.obs}`);
    formRef.current?.setFieldsValue(data.data);
  }

  return (
    <>
      <Modal
        title="Não Compensado"
        open={props.visible}
        onCancel={async (e) => {
          formRef.current?.resetFields();
          props.onCancel();
          setConfirmLoading(false);
        }}
        onOk={async (e) => {
          formRef.current?.submit();
        }}
        width="40%"
        style={{ maxWidth: "1280px" }}
        footer={false}
        confirmLoading={confirmLoading}
      >
        <ProForm
          submitter={{
            resetButtonProps: {
              style: {
                display: "none",
              },
            },
            submitButtonProps: {
              icon: <SaveOutlined />,
            },
            render: (props, doms) => {
              return [
                <Row justify="end">
                  <Col>
                    <Button
                      type="primary"
                      icon={<SaveOutlined />}
                      key="submit"
                      onClick={() => props.form?.submit()}
                    >
                      Salvar Dados
                    </Button>
                  </Col>
                </Row>,
              ];
            },
          }}
          formRef={formRef}
          size="middle"
          onFinish={async (e) => {
            setConfirmLoading(true);

            try {
              let data;

              if (props.obs) {
                data = await api.put(
                  `financeiro/nao-compensado/${props.obs}`,
                  formRef.current?.getFieldsValue()
                );
              } else {
                data = await api.post(
                  `financeiro/nao-compensado`,
                  formRef.current?.getFieldsValue()
                );
              }
          

              props.onCancel();

              message.info("Dados salvos!");

              formRef.current?.resetFields();
            } catch (error) {
              if (axios.isAxiosError(error)) {
                if (error.response?.data.errors) {
                  for (const name in error.response?.data.errors) {
                    const errors =
                      (error.response?.data.errors as any)[name] || [];
                    formRef.current?.setFields([{ name, errors }]);
                  }
                }
                if (error.message) {
                  message.error("Verifique os campos.");
                }
              }
            }
            setConfirmLoading(false);
          }}
        >
        <ProFormTextArea 
          name="observacao" 
          label="Observação"
        ></ProFormTextArea>
        </ProForm>
      </Modal>
    </>
  );
};

export default ModalLancamento;
