import {ShoppingCartOutlined, TagOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, { useState } from 'react';

const Checkout: React.FC = () => {
    return <>
        <div className="checkout">
            <div className="total">Valor Total R$: 10.250,00</div>
            <ul>
                <li>
                    <div className="item-icon">
                        <TagOutlined />
                    </div>
                    <div className="item-description">
                        <p><strong>Procedimento: </strong><span>CLAREAMENTO INTERNO</span></p>
                        <p><strong>Região: </strong><span>Dente</span></p>
                        <p><strong>Específico: </strong><span>17-21-33-47</span></p>
                    </div>
                    <div className="item-price">
                        <p><strong>Valor: </strong><span>CR$: 4.800,00</span></p>
                    </div>
                </li>
            </ul>
            <div className="total" >
                <Button type="primary" icon={<ShoppingCartOutlined />}>
                    Finalizar
                </Button>
                Valor Total R$: 10.250,00
            </div>

        </div>
    </>;
};

export default Checkout