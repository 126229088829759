import ProForm from "@ant-design/pro-form";
import { Input } from "antd";
import React from "react";

const OrdemServico: React.FC = () => {
  return (
    <>
      <ProForm submitter={false}>
        <b style={{ textAlign: "left" }}>
          Material - Digite a quantidade dos materiais que acompanham a OS:
        </b>
        <div className="materiais">
          <ProForm.Group>
            <Input size="small" /> Antagonista
            <Input size="small" /> Barra metalica
            <Input size="small" /> Base de Prova
          </ProForm.Group>
          <ProForm.Group>
            <Input size="small" /> Casquete
            <Input size="small" /> Copping
            <Input size="small" /> Coroa
          </ProForm.Group>
          <ProForm.Group>
            <Input size="small" /> Estrutura Parafusada
            <Input size="small" /> Gengiva
            <Input size="small" /> Modelo de estudo
          </ProForm.Group>
          <ProForm.Group>
            <Input size="small" /> Modelo de trabalho
            <Input size="small" /> Moldagem
            <Input size="small" /> Moldeiras Metalica
          </ProForm.Group>
          <ProForm.Group>
            <Input size="small" /> Moldeiras Plastica
            <Input size="small" /> Munhão
            <Input size="small" /> Muralha
          </ProForm.Group>
          <ProForm.Group>
            <Input size="small" /> Nucleo
            <Input size="small" /> O´ring
            <Input size="small" /> Parafuso
          </ProForm.Group>
          <ProForm.Group>
            <Input size="small" /> Protese Parcial
            <Input size="small" /> Protese Total
            <Input size="small" /> Registro de mordida
          </ProForm.Group>
          <ProForm.Group>
            <Input size="small" /> Réplica/ Analogo
            <Input size="small" /> Transfer
            <Input size="small" /> Troquéis
          </ProForm.Group>
         
        </div>
        <ProForm.Group style={{lineHeight: "50px"}}>
            <Input size="small" /> Ucla
          </ProForm.Group>
      </ProForm>
    </>
  );
};

export default OrdemServico;
