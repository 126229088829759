import './index.css';

import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, OrderedListOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import type { ProFormInstance } from '@ant-design/pro-form';
import ProForm, { ProFormDatePicker, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { Button, Card, Col, message, Modal, Popover, Row, Space, Table } from 'antd';
import axios from "axios";
import React, { useRef, useState } from 'react';

import { BancosSaida, CentroCusto, FormaPagamento, GrupoContas, SelectFornecedor, SelectUsuario } from '../../components';
import { SelectClinica } from '../../components/SelectClinica';
import { SelectPaciente } from '../../components/SelectPaciente';
import api from "../../services/api";
import { ContaPagarTipo, InputMoney } from "./components";
import { ModalParcelamento } from './ModalParcelamento';
import { ModalAPagarAgendadoProps } from './types';

export type TableListItem = {
    key       : number;
    pagante   : string;
    nCheque   : number;
    vencimento: string;
    vParcela  : string;
};


const ModalAPagarAgendado: React.FC<ModalAPagarAgendadoProps> = (props) => {

    const dataRes: TableListItem[] = [];
    const data   : TableListItem[] = [];
    const refContaGrupo = useRef();

    const [chequeDisplay, setChequeDisplay] = useState("none");
    const [displayTipo, setDisplayTipo]     = useState<number>();
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const [parcelasVisible, setParcelasVisible] = useState(false);
    const [valorTotal, setValorTotal] = useState(0);
    const [dataVencimento, setDataVencimento] = useState();
    const [dataEmissao, setDataEmissao] = useState();
    
    const columnsRes = [
        {
          title    : 'Pagante',
          dataIndex: 'pagante',
          key      : 'pagante',
        },
        {
          title    : 'N° Cheque',
          dataIndex: 'nCheque',
          key      : 'nCheque',
        },
        {
          title    : 'Vencimento',
          dataIndex: 'vencimento',
          key      : 'vencimento',
        },
        {
          title    : 'Parcela R$',
          dataIndex: 'vParcela',
          key      : 'vParcela',
        },
        {
          title  : '',
          key    : 'action',
          render : () => (
            <Button type="link" size="small"><ArrowLeftOutlined /></Button>
          ),
        },
    ];

    const columns = [
        {
          title    : 'Pagante',
          dataIndex: 'pagante',
          key      : 'pagante',
          width: 140
        },
        {
          title    : 'N° Cheque',
          dataIndex: 'nCheque',
          key      : 'nCheque',
          width: 85
        },
        {
          title    : 'Vencimento',
          dataIndex: 'vencimento',
          key      : 'vencimento',
          width: 95
        },
        {
          title    : 'Parcela R$',
          dataIndex: 'vParcela',
          key      : 'vParcela',
          width: 100
        },
        {
          title  : '',
          key    : 'action',
          render : (row: TableListItem) => (
            <Button type="link" size="small" onClick={() => AddCheque(row.key)} ><ArrowRightOutlined /></Button>
          ),
        },
    ];

    const formRef = useRef<
        ProFormInstance<{}>
    >();

    const AddCheque = (key: any) => {
        // console.log(data);
        let newData = data.filter(function(item){ return item.key != key });
        // console.log(data);
    
    }
      
    for(let i=1;i<=100;i++){
        data[i] = ({
            key: i,
            pagante: 'John Brown - '+i,
            nCheque: 32,
            vencimento: '16/12/2021',
            vParcela: 'R$ 123,50'
        });
    }

    const SetDisplay = (conf: boolean) => {

        if(conf)
            setChequeDisplay("flex");
        else
            setChequeDisplay("none");
        
    }

    const onFinish = async (values: []) => {
        
        setConfirmLoading(true);

        try {
            
            await api.post("financeiro/insert-conta-pagar", values);

            message.info('Dados salvos!');
            formRef.current?.resetFields()
            setConfirmLoading(true);

        } catch (error) {
            if(axios.isAxiosError(error)){
                
                if (error.response?.data.errors) {
                    for (const name in error.response?.data.errors) {
                        
                        const errors = (error.response?.data.errors as any)[name] || [];
                        formRef.current?.setFields([{ name, errors }]);
                       
                    }
                }
            
                if (error.message) {
                    message.error("Verifique os campos.");
                }
            }
        }

    }

    const SetInput = (value: number) => {  
        setDisplayTipo(value);
    }

    async function CloseParcelas() {
        setParcelasVisible(false);
    }

    const ValorTotal = (value: any) => {
        setValorTotal(value);
    }

    const DataVencimento = (value: any) => {
        setDataVencimento(value.format('YYYY-MM-DD'));
    }

    const DataEmissao = (value: any) => {
        setDataEmissao(value.format('DD/MM/YYYY'));
    }

    return <>
        <Modal
        title="Contas a Pagar"
        open={props.visible}
        onCancel={async (e) => {
            props.onCancel();
        }}
        onOk={async (e) => {}}
        width="80%"
        style={{ maxWidth: "1280px", top: 10 }}
        footer={false}
    >
        <ProForm
            submitter={{
                resetButtonProps: {
                  style: {
                    display: 'none',
                  },
                },
                submitButtonProps: {
                    icon: <SaveOutlined />
                },
                render: (props, doms) => {
                    return [
                        <Row justify="end">
                            <Col>
                                <Button loading={confirmLoading} type="primary" icon={<SaveOutlined />}  key="submit" onClick={() => props.form?.submit() }>Salvar</Button>
                            </Col>
                        </Row>
                    ];
                  }
            }}

            onFinish={onFinish}

            size="middle"
        >
            <Row gutter={24} >
                <Col span={8} ><ProFormText name="descricao" label="Descrição" placeholder="Descrição"  required rules={[{ required: true, message: 'Campo Obrigatório'}]} formItemProps={{className: "MarginBottomFormInput"}} /></Col>
                <Col span={4} >
                    <ProFormDatePicker name="vencimento" fieldProps={{ format: 'DD/MM/YYYY', onChange: (value) => {DataVencimento(value)} }} label="Vencimento" placeholder="__/__/____" required rules={[{ required: true, message: 'Campo Obrigatório'}]} formItemProps={{className: "MarginBottomFormInput", style: {width:"100%"}}} />
                </Col>
                <Col span={4} >
                    <ProFormDatePicker name="Emissao" label="Data Emissão" placeholder="__/__/____" fieldProps={{ format: 'DD/MM/YYYY', onChange:(value) => {DataEmissao(value)}  }} required rules={[{ required: true, message: 'Campo Obrigatório'}]} formItemProps={{className: "MarginBottomFormInput"}} />
                </Col>
                <Col span={3} >
                    <ProForm.Item name="valorFinal" label="Valor" required>
                        <InputMoney onChange={ValorTotal}/>
                    </ProForm.Item>
                </Col>
                <Col span={5} >
                    <ProForm.Item name="clinica" label="Clínica" required>
                        <SelectClinica />
                    </ProForm.Item>
                </Col>
            </Row>        
            <Row gutter={24} >
                {/* <Col span={6}>
                    
                </Col> */}
                <Col span={6}>
                    <ProFormText name="documento" label="Documento" placeholder="Doc." required rules={[{ required: true, message: 'Campo Obrigatório'}]} formItemProps={{className: "MarginBottomFormInput"}} />
                </Col>
                <Col span={6}>
                    <ProForm.Item name="contaPagarTipo" label="Tipo" required>
                        <ContaPagarTipo onChange={SetInput} />
                    </ProForm.Item>
                </Col>
                
                    {
                        displayTipo === 1 && 
                        <Col span={6}>
                            <ProForm.Item name="fornecedor" label="Fornecedor">
                                <SelectFornecedor />
                            </ProForm.Item>
                        </Col>
                    }
                    {
                        displayTipo === 5 && 
                        <Col span={6}>
                            <ProForm.Item name="usuario" label="Funcionário">
                                <SelectUsuario/>
                            </ProForm.Item>
                        </Col>
                    }
                    {
                        displayTipo === 2 && 
                        <Col span={6}>
                            <ProForm.Item name="paciente" label="Paciente">
                                <SelectPaciente />
                            </ProForm.Item>
                        </Col>
                    }
                <Col span={6}>
                    <ProForm.Item name="contaGrupos" label="Conta Grupos" required>
                        <GrupoContas name="contaGrupos" />                
                    </ProForm.Item>
                </Col>
            </Row>
            <Row gutter={24} style={{display: chequeDisplay}}>
                <Col span={12}>
                    <Card title="Parcelas a serem utilizadas" >
                        <Row gutter={24} >
                            <Col span={24}>
                                <Row gutter={24} >
                                    <Col span={10}>
                                        <ProFormText name="pagante" label={false} placeholder="Pagante"/>
                                    </Col>
                                    <Col span={9}>
                                        <ProFormText name="nCheque" label={false} placeholder="Nº Cheque"/>
                                    </Col>
                                    <Col span={4}>
                                        <Button size="small" type="default" ghost><SearchOutlined /> Buscar</Button>
                                    </Col>
                                </Row>
                                <Table columns={columns} dataSource={data} pagination={false} scroll={{ y: 180 }} size="small" title={() => "Lista de parcelas"} />

                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="Parcelas selecionadas">
                        <Row gutter={24} >
                            <Col span={24}>
                                <Table columns={columnsRes} dataSource={dataRes} pagination={false} scroll={{ y: 180 }} footer={() => 'Total: R$ 0,00'} size="small" title={() => "Parcelas selecionadas"} />
                            </Col>
                        </Row>
                        <Row gutter={24} >
                            <Col span={24}>
                                <Button style={{float: 'right', marginTop: '6px'}} size="middle" icon={<SaveOutlined />} type="primary">Salvar</Button>
                                <Button style={{float: 'right', marginTop: '6px', marginRight: '6px'}} size="middle" onClick={() => SetDisplay(false)} icon={<CloseOutlined />} type="primary" danger>Cancelar</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} >
                
                <Col span={5}  >
                    <ProForm.Item name="bancosSaida" label="Bancos" required>
                        <BancosSaida name="BancosSaida" />
                    </ProForm.Item>
                </Col>
                <Col span={5}  >
                    <ProForm.Item name="formaPagamento" label="Forma Pagto." required>
                        <FormaPagamento/>
                    </ProForm.Item>
                </Col>  
                <Col span={5}  >
                    <ProForm.Item name="centroCusto" label="Centro Custo" required>
                        <CentroCusto/>
                    </ProForm.Item>
                </Col>
                <Col span={9}  >
                    <ProFormTextArea width="xl" label="Observação" name="observacao" formItemProps={{className: "MarginBottomFormInput"}} />
                </Col>
            </Row>
            <Row gutter={24} >
                <Col span={12}>
                <Space>
                    <Popover content="A Conta parcelada dividira o valor pela quantidade de parcelas definidas ao clicar nesse botão." placement="topLeft">
                        <Button size="middle" icon={<OrderedListOutlined />} className="btn-info" onClick={() => {setParcelasVisible(true);}} >Conta Parcelada</Button>
                    </Popover>
                    <Popover content="Parcela fixa de todos os meses do ano atual" placement="topLeft">
                        <Button size="middle" icon={<OrderedListOutlined />} className="btn-info"  >Conta Recorrente</Button>
                    </Popover>
                </Space>
                </Col>     
            </Row>
        </ProForm>
    </Modal>
    <ModalParcelamento visible={parcelasVisible} onClose={CloseParcelas} valorParcela={valorTotal} dataVencimento={dataVencimento} dataEmissao={dataEmissao} setDataVencimento={setDataVencimento} />
    </>
    ;
};
export default ModalAPagarAgendado;