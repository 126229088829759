import ProForm, { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Button, Modal, Tabs } from "antd";
import React from "react";

const { TabPane } = Tabs;


interface ModalNovoPagante {
    visible: boolean;
    novoPagante: boolean;
    onOk?: () => void;
    onCancel?: () => void;
  }

const emissaoRaioX: React.FC<ModalNovoPagante> = (props) => {
  return(
      <>
      <Modal
        title={"Dados Pagante"}
        width={"45%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <Tabs defaultActiveKey="1" size="middle">
          <TabPane tab="Selecione o Pagante" key="1">
          <ProForm.Group>  
           <ProFormSelect
             name="tipoDocumento"
             label="Tipo de documento:"
             options={[
                {
                  value: "1",
                  label: "CPF",
                },
                { value: "2", label: "CNPJ" },
                { value: "3", label: "ID ESTRANGEIRO" },
              ]}
           ></ProFormSelect> 
           <ProFormText
             name="numero"
             label="Número"
           ></ProFormText>  
            <Button type="primary">
              Buscar
            </Button>  
          </ProForm.Group>
          </TabPane>
        </Tabs>
      </Modal>
    
      </>
  );
}


export default emissaoRaioX;