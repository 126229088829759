import { Modal, Table, } from "antd";
import React from "react";

interface ModalCondicoes {
  visible: boolean;
  condicoes: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const Gerenciar: React.FC<ModalCondicoes> = (props) => {
  return (
    <>
      <Modal
        title={"Condições"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <Table
          columns={[
            {
              title: "Parcela de",
              dataIndex: "parcelaD",
            },
            {
              title: "Parcela Até",
              dataIndex: "parcelaA",
            },
            {
              title: "Juros Percentual",
              dataIndex: "jurosP",
            },
            {
              title: "Desconto Percentual",
              dataIndex: "desconto",
            },
            {
              title: "Paciente Score",
              dataIndex: "pacienteScore",
            },
            {
              title: "Condição Especial",
              dataIndex: "condicao",
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default Gerenciar;
