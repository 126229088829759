import {SearchOutlined} from "@ant-design/icons";
import {AutoComplete, Button, DatePicker,Select} from 'antd';
import { SelectProps } from 'antd/es/select';
import React, { useState } from 'react';

const { RangePicker } = DatePicker;
const { Option } = Select;
function getRandomInt(max: number, min: number = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
}

const searchResult = (query: string) =>
    new Array(getRandomInt(5))
        .join('.')
        .split('.')
        .map((_, idx) => {
            const category = `${query}${idx}`;
            return {
                value: category,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
            <span>
              Found {query} on{' '}
                <a
                    href={`https://s.taobao.com/search?q=${query}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                {category}
              </a>
            </span>
                        <span>{getRandomInt(200, 100)} results</span>
                    </div>
                ),
            };
        });

const HeaderFilter: React.FC = () => {
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);

    const handleSearch = (value: string) => {
        setOptions(value ? searchResult(value) : []);
    };

    const onSelect = (value: string) => {
        console.log('onSelect', value);
    };

    function onChange(value: any) {
        console.log(`selected ${value}`);
    }

    function onSearch(val: any) {
        console.log('search:', val);
    }

    // @ts-ignore
    // @ts-ignore
    return (
        <div className="filters">
            <label>
                Paciente/Prontuário:

                <AutoComplete
                    showSearch={false}
                    dropdownMatchSelectWidth={252}
                    style={{ width: 300 }}
                    options={options}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                >
                </AutoComplete>
            </label>
            <label>
                Clínica:
                <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder=""
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="jack">Londrina</Option>
                    <Option value="lucy">Sao Paulo</Option>
                    <Option value="tom">Rio de Janeiro</Option>
                </Select>
            </label>
            <label>
                Periodo:
                <RangePicker />
            </label>
            <Button type="primary" icon={<SearchOutlined />} size="large">
                Buscar
            </Button>
        </div>
    );
};

export default HeaderFilter