import axios from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useClinica } from "../../../contexts";
import api from "../../../services/api";
import { apiAgenda } from "../../../services/apis";
import { UsuarioModel } from "../../../services/models/Usuario";

interface UsuariosContextData {
  usuarios: UsuarioModel[];
  isLoading: boolean;
  error?: any;
}

export const UsuariosContext = createContext<UsuariosContextData>({
  usuarios: [],
  isLoading: false,
} as unknown as UsuariosContextData);

export const UsuariosProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
	const { clinicaAtual } = useClinica();

	const [usuarios, setUsuarios]   = useState<UsuarioModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError]         = useState(null);
	const [selecionados, setSelecionados] = useState<UsuarioModel[]>([]);


	useEffect(() => {
		setIsLoading(true);
		setError(null);

		apiAgenda
			.getUsuarios({ idClinica: clinicaAtual.id, tipo: 1 })
			.then((response) => {
				const { data } = response.data;
				setUsuarios(data);
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [clinicaAtual.id]);

  return (
    <UsuariosContext.Provider
		value={{
			usuarios,
			isLoading,
			error
		}}
    >
      {children}
    </UsuariosContext.Provider>
  );
};

export function useUsuarios() {
  const context = useContext(UsuariosContext);

  if (!context) {
    throw new Error("useUsuarios must be used within an UsuariosProvider.");
  }

  return context;
}
