import { Col, Empty, Row } from "antd";
import Text from "antd/es/typography/Text";
import React, { useState } from "react";

const OrdemServico: React.FC = () => {
  const [dados] = useState<any | null>({
    Numero: "688100",
    dataCadastro: "05/01/2022",
    Paciente: "Eliana Rodrigues De Paula",
    Prontuário: "BAN-0009298-BAN",
    Dentista: "JOSÉ LÁZARO RODRIGUES VIEIRA JUNIOR",
    Unidade: "Londrina - Av. Bandeirantes",
    DataSolicitada: "10/01/2022 08:00",
    Laboratorio: "Art Dent",
  });
  return (
    <div>
      {dados ? (
        <div className="usuarioDetalhes">
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.Numero}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Número:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.dataCadastro}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Data de Cadastro:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.Paciente}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Paciente:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.Prontuário}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Prontuário:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.Dentista}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Dentista:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.Unidade}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Unidade:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.DataSolicitada}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Data solicitada:</Text>
            </Col>
          </Row>
          <Row>
            <Col span={17} push={7}>
              <Text type="secondary">{dados.Laboratorio}</Text>
            </Col>
            <Col span={7} pull={17}>
              <Text>Laboratório:</Text>
            </Col>
          </Row>
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default OrdemServico;
