import { PageContainer } from "@ant-design/pro-layout";
import type { ActionType, ProColumns } from "@ant-design/pro-table";
import ProTable from "@ant-design/pro-table";
import { Button, message, Popconfirm } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SelectCompromisso } from "../../components/SelectCompromisso";
import { SelectUsuariosComercial } from "../../components/SelectUsuariosComercial";
import { useClinica } from "../../contexts";
import { useDebounce } from "../../hooks/useDebounce";
import { apiAgenda } from "../../services/apis";
import { AgendaUsuarioCompromissoModel } from "../../services/models/AgendaUsuarioCompromisso";
import { UsuarioModel } from "../../services/models/Usuario";
import {
  useUsuarios,
  UsuariosProvider,
} from "../Agenda/contexts/UsuariosContext";
import {
  EventoCompromisso,
  useAgendaUsuarioCompromissos,
} from "../Agenda/hooks/useAgendaCompromissos";
import { NovoCompromissoUsuarioModal } from "./components/AgendaUsuarioCompromissoFormModal";

export const AgendaUsuarioCompromisso: React.FC = () => {
  type Evento = EventoCompromisso;

  const navigate = useNavigate();

  const tableRef = React.useRef<ActionType>(null);

  const { idClinicaAtual } = useClinica();
  const usuarios = useUsuarios();

  const [resources, setResources] = useState<UsuarioModel[]>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idAgendaCompromisso, setIdAgendaCompromisso] = useState<number | null>(
    null
  );

  async function openModalForm(idAgendaCompromisso: number | null) {
    setIdAgendaCompromisso(idAgendaCompromisso);
    setIsModalVisible(true);
  }
  const closeModal = () => {
    setIdAgendaCompromisso(null);
    setIsModalVisible(false);
  };

  // Agenda
  const [dataRange, setDataRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);

  const dataRangeDebounce = useDebounce(dataRange);

  const compromissos = useAgendaUsuarioCompromissos({
    idClinica: idClinicaAtual,
    dataInicial: dataRangeDebounce[0],
    dataFinal: dataRangeDebounce[1],
    idUsuario: usuarios.usuarios.map((u) => u.idUsuario),
  });

  useEffect(() => {
    const temp = usuarios.usuarios;
    setResources(temp);
  }, [compromissos.compromissos, usuarios.usuarios]);

  const menuAcoes = (row: AgendaUsuarioCompromissoModel) => {
    let title = "Esta certo disso ?";
    let mensagemDelete = "Deletando...";

    const onConfirm = () => {
      message.loading({ content: mensagemDelete, key: "delete" });
      apiAgenda
        .excluirCompromisso(row.idAgendaCompromisso)
        .then(() => {
          message.success({ content: "Pronto", key: "delete" });
          tableRef.current?.reload();
        })
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response?.data?.message) {
            message.error({
              content: error.response?.data?.message,
              key: "delete",
            });
          } else {
            message.error({
              content: "Erro desconhecido",
              key: "delete",
            });
          }
        })
        .finally(() => {});
    };

    return (
      <Popconfirm
        title="Esta certo disso ?"
        onConfirm={onConfirm}
        placement="top"
      >
        <Button type="primary" danger>
          Deletar
        </Button>
      </Popconfirm>
    );
  };

  const columns: ProColumns<AgendaUsuarioCompromissoModel>[] = [
    {
      title: "Usuário",
      dataIndex: ["usuario", "nomeUsuario"],
      valueType: "text",
      renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
        if (type === "form") {
          return null;
        }

        return <SelectUsuariosComercial />;
      },
      search: {
        transform: (value) => ({
          idUsuario: value,
        }),
      },
    },
    {
      title: "Data Início",
      dataIndex: "dataInicio",
      valueType: "date",
      initialValue: dayjs(),
      fieldProps: {
        format: ["DD/MM/YYYY", "YYYY-MM-DD"],
      },
      search: {
        transform: (value) => ({
          dataInicio: value.format("YYYY-MM-DD"),
        }),
      },
      render: (dom, row) =>
        row.dataInicio ? dayjs(row.dataInicio).format("DD/MM/YYYY") : "",
    },
    {
      title: "Data Fim",
      dataIndex: "dataFinal",
      valueType: "date",
      fieldProps: {
        format: ["DD/MM/YYYY", "YYYY-MM-DD"],
      },
      initialValue: dayjs(),
      search: {
        transform: (value) => ({
          dataFinal: value.format("YYYY-MM-DD"),
        }),
      },
      render: (dom, row) =>
        row.dataFinal ? dayjs(row.dataFinal).format("DD/MM/YYYY") : "",
    },
    {
      title: "Hora Início",
      dataIndex: "horaInicio",
      valueType: "time",
      hideInSearch: true,
      render: (dom, row) =>
        row.horaInicio ? dayjs(row.horaInicio, "HH:mm:ss").format("HH:mm") : "",
    },
    {
      title: "Hora Fim",
      dataIndex: "horaFim",
      hideInSearch: true,
      render: (dom, row) =>
        row.horaFim ? dayjs(row.horaFim, "HH:mm:ss").format("HH:mm") : "",
    },
    {
      title: "Compromisso",
      dataIndex: ["compromisso", "motivo"],
      valueType: "text",
      renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
        if (type === "form") {
          return null;
        }

        return (
          <SelectCompromisso
            showSearch
            onSelect={rest.onSelect}
            value={rest.value}
            mode={"multiple"}
          />
        );
      },
      search: {
        transform: (value) => ({
          idCompromisso: value,
        }),
      },
    },
    {
      hideInSearch: true,
      title: "Ações",
      key: "action",
      render: (dom, row: any) => [menuAcoes(row)],
    },
  ];
  return (
    <UsuariosProvider>
      <PageContainer
        title="Horários Indisponíveis"
        header={{
          onBack: () => navigate("..?aba=comercial"),
        }}
        extra={[
          <Button key="1" type="primary" onClick={() => openModalForm(null)}>
            Novo
          </Button>,
        ]}
      >
        <NovoCompromissoUsuarioModal
          idAgendaCompromisso={idAgendaCompromisso}
          visible={isModalVisible}
          onOk={() => {
            tableRef.current?.reload(true);
            closeModal();
          }}
          onCancel={closeModal}
        />
        <ProTable<AgendaUsuarioCompromissoModel>
          actionRef={tableRef}
          columns={columns}
          rowKey="idAgendaCompromisso"
          pagination={{
            showQuickJumper: true,
          }}
          request={(params, sorter, filter) => {
            const { pageSize, current, ...rest } = params;

            return apiAgenda
              .getTableUsuarioCompromissos({
                params: {
                  per_page: pageSize,
                  page: current,
                  idClinica: idClinicaAtual,
                  ...rest,
                },
              })
              .then((data) => ({
                data: data.data.data,
                success: true,
                total: data.data.meta.total,
              }))
              .catch((error) => ({
                data: [],
                success: false,
                total: 0,
              }));
          }}
          onChange={() => tableRef.current?.reload(true)}
          search={{
            layout: "vertical",
            defaultCollapsed: true,
          }}
          dateFormatter={false}
        />
      </PageContainer>
    </UsuariosProvider>
  );
};
