import {
  CaretRightOutlined,
  EditOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { ProFormSelect } from "@ant-design/pro-form";
import { PageContainer } from "@ant-design/pro-layout";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { useClinica } from "../../contexts";
import api from "../../services/api";
import FiltroAReceber from "./FiltroAReceber";
import ModalAntecipado from "./ModalAntecipado";
import ModalCompensado from "./ModalCompensado";
import ModalHistoricoParcela from "./ModalHistoricoParcela";
import ModalLancamento from "./ModalLançamentoDiversos";
import ModalNotaFiscal from "./ModalNotaFiscal";
import ModalObs from "./ModalObservcao";
import { AReceberAjax, AReceberLista, Pagination } from "./types";

const { Text } = Typography;

const AReceber: React.FC = () => {
  const { clinicaAtual } = useClinica();

  const [load, setLoad] = useState(false);
  const [table, setTable] = useState<AReceberLista[]>([]);
  const [pagina, setPagina] = useState<Pagination>({
    current: 1,
    total: 0,
    pageSize: 15,
  });
  const [isModalLancamentoVisible, setIsModalLancamentoVisible] =
    useState(false);
  const [isModalNotaFiscalVisible, setIsModalNotaFiscalVisible] =
    useState(false);
  const [isModalCompensadoVisible, setIsModalCompensadoVisible] =
    useState(false);
  const [isModalObsVisible, setIsModalObsVisible] = useState(false);
  const [isModalAntecipadoVisible, setIsModalAnteciapadoVisible] =
    useState(false);
  const [isModalHistoricoParcelaVisible, setIsModalsetHistoricoParcelaVisible] =
    useState(false);
  const [post, setPost] = useState<[]>([]);
  const [notaFiscal, setNotaFiscal] = useState(false);
  const [compensado, setCompensado] = useState(false);
  const [obs, setObs] = useState(false);
  const [antecipados, setAntecipados] = useState(false);
  const [historicoParcela, setHistoricoParcela] = useState(false);


  const LoadData = async (params: [], page = 1, pageSize = 15) => {
    setLoad(true);

    if (!params) params = post;

    try {
      const { data } = await api.get<AReceberAjax>("financeiro/conta-receber", {
        params: {
          page: page,
          per_page: pageSize,
          idClinica: clinicaAtual.id,
          params: params,
        },
      });
      setTable(data.data);
      setPagina({
        ...pagina,
        current: page,
        total: data.meta.total,
        pageSize: pageSize,
      });
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
    setLoad(false);
  };

  useEffect(() => {
    LoadData([]);
  }, []);

  const options = [
    { value: 1, label: 'Compensado' },
    { value: 2, label: 'Não Compensado' },
    { value: 3, label: 'Antecipado' },
  ];
  
  const columns: ProColumns<AReceberLista>[] = [
    {
      title: "NF",
      dataIndex: ["conta_receber_nota_fiscal", "notaFiscal"],
    },
    {
      title: "Paciente / Descrição",
      dataIndex: ["paciente", "nomePaciente"],
      render: (row, record) => {
        return (
          <Text>
            {row}-{record.descricao}
          </Text>
        );
      },
    },
    {
      title: "Pagante",
      dataIndex: ["paciente", "nomePaciente"],
      render: (row, record) => {
        return <Text>{row}</Text>;
      },
    },
    {
      title: "Contrato",
      dataIndex: ["contrato", "idContrato"],
      render: (row, record) => {
        return (
          <Text>
            {row} v.{record.versaoContrato}
          </Text>
        );
      },
    },
    {
      title: "Fechador",
      dataIndex: "nomeUsuario",
    },
    {
      title: "Avaliador",
      dataIndex: "nomeAvaliador",
    },
    {
      title: "Forma Pagamento",
      dataIndex: ["forma_pagamento", "nomeFormaPagamento"],
      render: (row, record) => {
        return (
          <Text>
            {row} - {record.nomeInstituicao}{" "}
          </Text>
        );
      },
    },
    {
      title: "Financeira",
      dataIndex: ["status_financeiro", "sigla"],
    },
    {
      title: "Venc.",
      dataIndex: "dataVencimento",
    },
    {
      title: "Data Baixa",
      dataIndex: "dataBaixa",
    },
    {
      title: "Vl. Bruto",
      dataIndex: "valor",
    },
    {
      title: "Vl. Liquido",
      dataIndex: "valorFinal",
    },
    {
      title: "Status",
      dataIndex: ["status_pagamento", "statusPagamento"],
    },
    {
      valueType: "option",
      render: (dom, row) => [
        <Button
         onClick={() => OpenModalHistorico(row)}
        >
         <FileTextOutlined />
        </Button>
      ],
    },
    {
      valueType: "option",
      render: (Dom, row) => [
        <Button
          onClick={() => {
            OpenModalNotaFiscal(row);
          }}
        >
          <EditOutlined />
          Alterar
        </Button>,
      ],
    },
    {
      title: "Ações",
      valueType: "option",
      render: (Dow, row) => [
       <ProFormSelect
         style={{ width: 150 }}
         placeholder='Selecione...'
         options={options}
         fieldProps={{
           onChange: (value: number) => {
            ShowModel(value);
           }
         }}
       ></ProFormSelect>
      ],
    },
    {
      valueType: "option",
      render: (Dom, row) => [
        <Button  
        onClick={() => {
          OpenModalCompensado(row)
        }}>
          <CaretRightOutlined />
        </Button>,
      ],
    },
  ];

  const ShowModel = (value: number) => {
    OpenModalObs(true);

    if (value > 1 && value < 4) {
      if (value == 2)
      OpenModalObs(false);
    } 
  };

  async function OpenModalNotaFiscal(row: any) {
    if (row.idContaReceberNotaFiscal) {
      setNotaFiscal(row.idContaReceberNotaFiscal);
    } else {
      setNotaFiscal(false);
    }
    setIsModalNotaFiscalVisible(true);
  }

  async function OpenModalCompensado(row: any) {
    if (row.idContaReceber) {
      setCompensado(row.idContaReceber);
    } else {
      setCompensado(false);
    }
    setIsModalCompensadoVisible(true);
  }

  async function OpenModalObs(row: any) {
    if (row.idContaReceber) {
      setObs(row.idContaReceber);
    } else {
      setObs(false);
    }
    setIsModalObsVisible(true);
  }

  async function OpenModalAntecipado(row: any) {
    if (row.idContaReceber) {
      setAntecipados(row.idContaReceber);
    } else {
      setAntecipados(false);
    }
    setIsModalObsVisible(true);
  }

  async function OpenModalHistorico(row: any) {
    if (row.idContratoParcela) {
      setHistoricoParcela(row.idContratoParcela);
    } else {
      setHistoricoParcela(false);
    }
    setIsModalsetHistoricoParcelaVisible(true);
  }

  const CloseModalCompensado = () => {
    setIsModalCompensadoVisible(false);
  };

  const CloseModalObs = () => {
    setIsModalObsVisible(false);
  };

  const CloseModalAntecipado = () => {
    setIsModalAnteciapadoVisible(false);
  };

  const CloseModalHistoricoParcela = () => {
    setIsModalsetHistoricoParcelaVisible(false);
  };

  const CloseModalLancamento = () => {
    setIsModalLancamentoVisible(false);
  };

  const CloseModalNotaFiscal = () => {
    setIsModalNotaFiscalVisible(false);
  };

  async function OpenModalLancamento(row: any) {
    setIsModalLancamentoVisible(true);
  }

  const SetParamsSearch = (params: any) => {
    setPost(params);
    console.log(params);
    LoadData(params);
  };

  return (
    <>
      <ModalLancamento
        visible={isModalLancamentoVisible}
        onCancel={CloseModalLancamento}
      />
      <ModalNotaFiscal
        nota={notaFiscal}
        visible={isModalNotaFiscalVisible}
        onCancel={CloseModalNotaFiscal}
      />
      <ModalCompensado
        acao={compensado}
        visible={isModalCompensadoVisible}
        onCancel={CloseModalCompensado}
      />
      <ModalObs
        obs={obs}
        visible={isModalObsVisible}
        onCancel={CloseModalObs}
      />
      <ModalAntecipado
        antecipado={antecipados}
        visible={isModalAntecipadoVisible}
        onCancel={CloseModalAntecipado}
      />
      <ModalHistoricoParcela
        historicoParcela={historicoParcela}
        visible={isModalHistoricoParcelaVisible}
        onCancel={CloseModalHistoricoParcela}
      />

      <PageContainer
        extra={[
          <Button key="1" type="primary">
            Lançar Nota Fiscal
          </Button>,
          <Button key="2" type="primary" onClick={OpenModalLancamento}>
            Lançamentos Diversos
          </Button>,
        ]}
      >
        <FiltroAReceber loadPage={SetParamsSearch} />
        <ProTable<AReceberLista>
          columns={columns}
          rowKey="key"
          options={false}
          search={false}
          dateFormatter="string"
          loading={load}
          dataSource={table}
          pagination={{
            current: pagina.current,
            total: pagina.total,
            pageSize: pagina.pageSize,
            onChange: (page, pageSize) => {
              LoadData(post, page, pageSize);
            },
          }}
        />
      </PageContainer>
    </>
  );
};

export default AReceber;
