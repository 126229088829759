import ProTable, { ProColumns } from "@ant-design/pro-table";
import React, { useEffect, useState } from "react";

import { HistoricoVisualizacao } from "./types";

const OrdemServico: React.FC = () => {
  const [dados, setDados] = useState<HistoricoVisualizacao[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: HistoricoVisualizacao[] = [];

    for (let i = 0; i < 1; i += 1) {
      tableList.push({
        status: "Enviado ao LAB EXTERNO",
        observacao: "Laboratório: Art Dent",
        usuarioHistorico:
          "Ana Carolina Fernandes De Azevedo em 05/01/2022 10:49",
      });
    }
    setDados(tableList);
  }, []);

  const columns: ProColumns<HistoricoVisualizacao>[] = [
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Observação",
      dataIndex: "observacao",
    },
    {
      title: "Usuário/Data de Alteração",
      dataIndex: "usuarioHistorico",
    },
  ];
  return (
    <>
      <ProTable<HistoricoVisualizacao>
        columns={columns}
        request={(params, sorter, filter) => {
          return Promise.resolve({
            data: tableList,
            success: true,
          });
        }}
        rowKey="key"
        pagination={false}
        search={false}
        dateFormatter="string"
      />
    </>
  );
};

export default OrdemServico;
