import { SaveOutlined } from "@ant-design/icons";
import ProForm, {
  ProFormDatePicker,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-form";
import { Button, Col, message, Modal, Row , Typography } from "antd";
import Link from "antd/es/typography/Link";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import api from "../../services/api";
import { ModalAlterarNotaFiscal } from "./types";

const { Title } = Typography;

const AlteraNotaFiscal: React.FC<ModalAlterarNotaFiscal> = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);


  const formRef = useRef<
    ProFormInstance<{
       notaFiscal: string;
       linkNFSE: string;
       dataNotaFiscal: Date;
    }>
  >();
  
  useEffect(() => {
    const VM = props.visible;

    if (VM == true) {
      if (props.nota) {
        GetNotaFiscal();
      } else {
        message.error('A Parcela não possui nota fiscal cadastrada!')
      }
    }
  });


  async function GetNotaFiscal() {
    const { data } = await api.get(`financeiro/nota-fiscal/${props.nota}`);
    formRef.current?.setFieldsValue(data.data);
  }

  function carregaLink() { 
    const data = formRef.current?.getFieldValue('linkNFSE')
    window.open(data,'_blank')
  }

  return (
    <>
      <Modal
        title="Lançamento de Nota Fiscal"
        open={props.visible}
        onCancel={async (e) => {
          props.onCancel();
          setConfirmLoading(false);
        }}
        width="80%"
        style={{ maxWidth: "1280px" }}
        footer={false}
        confirmLoading={confirmLoading}
      >
        <ProForm
          
          submitter={{
            resetButtonProps: {
              style: {
                display: "none",
              },
            },
            submitButtonProps: {
              icon: <SaveOutlined />,
            },
            render: (props, doms) => {
              return [
                <Row justify="end">
                  <Col>
                    <Button
                      type="primary"
                      icon={<SaveOutlined />}
                      key="submit"
                      onClick={() => props.form?.submit()}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>,
              ];
            },
          }}
          formRef={formRef}
          onFinish={async (e) => {
            setConfirmLoading(true);
  
            try {
              let data;
              if (props.nota) {
                data = await api.put(
                  `financeiro/nota-fiscal/${props.nota}`,
                  formRef.current?.getFieldsValue()
                );
              } else {
                data = await api.post(
                  `financeiro/nota-fiscal`,
                  formRef.current?.getFieldsValue()
                );
              }
  
              props.onCancel();

              
              message.info("Dados salvos!");
  
              formRef.current?.resetFields();
            } catch (error) {
              if (axios.isAxiosError(error)) {
                if (error.response?.data.errors) {
                  for (const name in error.response?.data.errors) {
                    const errors =
                      (error.response?.data.errors as any)[name] || [];
                    formRef.current?.setFields([{ name, errors }]);
                  }
                }
                if (error.message) {
                  message.error("Verifique os campos.");
                }
              }
            }
            setConfirmLoading(false);
          }}
          size="middle"
          dateFormatter="string"

        >
          <Title level={5}>Dados Lançamento</Title>
          <Row gutter={24}>
            <Col span={10}>
              <ProFormSelect name="nomePaciente" label="Paciente / Pagante" ></ProFormSelect>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <ProFormText name="notaFiscal" label="Número NF"></ProFormText>
            </Col>
            <Col span={6}>
              <ProFormDatePicker
                name="dataNotaFiscal"
                label="Data Nota Fiscal"
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <ProFormText name="linkNFSE" label="Link NF"></ProFormText>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Button type="link">
                <Link onClick={() => {carregaLink()}} >Carregar Link</Link>
              </Button>
            </Col>
          </Row>
        </ProForm>
      </Modal>
    </>
  );
};

export default AlteraNotaFiscal;
