/* eslint-disable react-hooks/rules-of-hooks */
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { Button, Cascader, Form, Tabs } from "antd";
import React, { useState } from "react";

import ModalPagante from "./ModalPagante";
import { paymentsOptions } from "./types";

const { TabPane } = Tabs;

const emissaoRaioX: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagante, setPagante] = useState(false);

  async function OpenModalPagante(row: any) {
    if (row) {
      setPagante(row);
    } else {
      setPagante(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
      <br />
      <Tabs defaultActiveKey="1" size="middle">
        <TabPane tab="Forma pagamento" key="1">
          <br />
          <br />
          <ProForm.Group>
            Forma Pagamento:
            <Cascader options={paymentsOptions}></Cascader>
          </ProForm.Group>
          <br />
          <ProForm.Group>
            <ProFormText
              width="sm"
              name="valor"
              label="Valor "
              placeholder="120,00"
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText
              width="sm"
              name="pagante"
              label="Pagante "
              placeholder="Rodolfo"
            />
            {pagante && (
              <ModalPagante
                visible={isModalVisible}
                pagante={pagante}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
              />
            )}
            <Button type="primary" onClick={OpenModalPagante}>
              Selecionar Pagante para esta forma
            </Button>
          </ProForm.Group>
          <Form.Item label="Detalhes Pagamento:">
            <span className="ant-form-text"></span>
          </Form.Item>
        </TabPane>
      </Tabs>
    </>
  );
};

export default emissaoRaioX;
