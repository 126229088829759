import ProCard from "@ant-design/pro-card";
import {
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-form";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, Form, Tabs } from "antd";
import React, { useState } from "react";

import ModalCodBarras from "../ControlePedido/ModalCodBarras";
import SelecioneOS from "./ModalSelecioneOS";

const { TabPane } = Tabs;

const ControlePedido: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selecioneOS, setSelecioneOS] = useState(false);
  const [CodBarras, setCodBarras] = useState(false);

  async function Open(row: any) {
    if (row) {
      setSelecioneOS(row);
    } else {
      setSelecioneOS(false);
    }
  }

  async function OpenModal(row: any) {
    if (row) {
      setCodBarras(row);
    } else {
      setCodBarras(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
      <PageContainer>
        <ProCard
          style={{ width: "600px", marginLeft: "auto", marginRight: "auto" }}
        >
          <Tabs defaultActiveKey="1" size="middle">
            <TabPane tab="Ordem de Serviço" key="1">
              <Form>
                <ProFormSelect
                  label="Clínica"
                  name="nomeClinica"
                  width="md"
                  options={[
                    {
                      value: "1",
                      label: "Londrina - Av. Bandeirantes",
                    },
                    { value: "2", label: "Londrina - Zona Norte" },
                  ]}
                ></ProFormSelect>
                <ProFormSelect
                  label="Laboratório"
                  name="lab"
                  width="lg"
                  options={[
                    {
                      value: "1",
                      label: "Art Dent",
                    },
                    { value: "2", label: "Almir" },
                    { value: "3", label: "Baixo" },
                  ]}
                ></ProFormSelect>
                <ProFormText
                  label="Nº da OS"
                  name="os"
                  width="sm"
                ></ProFormText>
                <ProFormText
                  label="Paciente"
                  name="nomePaciente"
                  width="sm"
                ></ProFormText>
                <ProFormText
                  label="Prontuário"
                  name="prontuario"
                  width="sm"
                ></ProFormText>
                <ProFormText
                  label="Dentista"
                  name="dentista"
                  width="sm"
                ></ProFormText>
                <ProFormDateRangePicker
                  name="dateTime"
                  label="  Data:	"
                  fieldProps={{
                    format: "DD/MM/YYYY",
                  }}
                />
                <ProFormSelect
                  label="Data solicitada:"
                  name="dataSolicitada"
                  width="lg"
                  options={[
                    {
                      value: "1",
                      label: "Criação",
                    },
                    { value: "2", label: "Entrega Solicitada" },
                    { value: "3", label: "Entrega Prevista" },
                  ]}
                ></ProFormSelect>
                <ProFormSelect
                  name="statusAtual"
                  label="Status Atual:"
                  valueEnum={{
                    cadastro: "Cadastro",
                    recibo: "Recibo LAB INTERNO",
                    enviado: "Enviado ao LAB INTERNO",
                  }}
                  fieldProps={{
                    mode: "multiple",
                  }}
                />
                <div>
                  <Button type="primary" onClick={Open}>
                    Buscar
                  </Button>{" "}
                  <Button type="primary" onClick={OpenModal}>
                    Buscar P/Código de Barras
                  </Button>{" "}
                </div>
              </Form>
            </TabPane>
          </Tabs>
        </ProCard>
        <br />
        <br />
        {selecioneOS && (
          <SelecioneOS
            visible={isVisible}
            selecioneOs={selecioneOS}
            onOk={() => setIsVisible(false)}
            onCancel={() => setIsVisible(false)}
          />
        )}
        {CodBarras && (
          <ModalCodBarras
            visible={isModalVisible}
            codBarras={CodBarras}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
          />
        )}
      </PageContainer>
    </>
  );
};

export default ControlePedido;
