import { Card } from "antd";
import React from "react";

const emissaoRaioX: React.FC = () => {
  return (
    <>
    <div id={"divListaEspera"}>
      <br />
      <br />
     <div className={"raioX"}>
          <Card title="Pacientes Aguardando Exame">
            <p style={{textAlign: "center"}}> Nenhum paciente na espera! </p>
          </Card>
      </div>
      <div className={"raioXPago"}>
          <Card title="Pacientes Aguardando para Pagar" >
            <p style={{textAlign: "center"}}> Nenhum paciente na espera! </p>
          </Card>
      </div>
     </div>
 
    </>
  );
};

export default emissaoRaioX;
