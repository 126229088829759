import { Form, Modal, Radio, Skeleton } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { DatePicker } from "../../../components";
import { SelectClinica } from "../../../components/SelectClinica";
import { SelectCompromisso } from "../../../components/SelectCompromisso";
import { SelectDentista } from "../../../components/SelectDentista";
import { apiAgendaCompromisso } from "../../../services/apis";
import { AgendaCompromissoModel } from "../../../services/models";

interface AgendaCompromissoFormFields extends AgendaCompromissoModel {
  tipo: string;
  data_range: [dayjs.Dayjs, dayjs.Dayjs];
  hora_range: [dayjs.Dayjs, dayjs.Dayjs];
}

export interface AgendaCompromissoFormModalProps {
  defaultTipo?: string;
  idAgendaCompromisso?: null | number;
  visible?: boolean;
  onCancel?: () => void;
  onOk?: (data: AgendaCompromissoModel) => void;
}

export const AgendaCompromissoFormModal: React.FC<
  AgendaCompromissoFormModalProps
> = (props) => {
  const { defaultTipo, idAgendaCompromisso, onCancel, onOk } = props;

  const [isLoading, setIsLoading] = useState(!!idAgendaCompromisso);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tipo, setTipo] = useState(defaultTipo || "dentista");
  const [initialValues, setInitialValues] = useState<
    Partial<AgendaCompromissoFormFields>
  >({});

  const [form] = Form.useForm();

  useEffect(() => {
    if (!idAgendaCompromisso || !props.visible) {
      const tipo = defaultTipo || "dentista";
      setTipo(tipo);
      setInitialValues({
        tipo,
      });
      setIsLoading(false);

      return;
    }

    setIsLoading(true);

    apiAgendaCompromisso
      .get(idAgendaCompromisso)
      .then((response) => {
        const { data } = response.data;

        let tipo = "";
        if (data.idClinica === 0 && data.idDentista === 0) {
          tipo = "global";
        } else if (data.idClinica && data.idDentista === 0) {
          tipo = "clinica";
        } else if (data.idDentista) {
          tipo = "dentista";
        }

        setTipo(tipo);

        setInitialValues({
          ...data,
          tipo,
          data_range: [dayjs(data.dataInicio), dayjs(data.dataFinal)],
          hora_range: [
            dayjs(`${data.dataInicio} ${data.horaInicio}`),
            dayjs(`${data.dataFinal} ${data.horaFim}`),
          ],
        });
      })
      .catch((error) => {
        // setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [defaultTipo, form, idAgendaCompromisso, props, props.visible]);

  useEffect(() => {
    if (!isLoading) {
      form.resetFields();
    }
  }, [form, initialValues, isLoading]);

  let content = null;
  if (isLoading) {
    content = <Skeleton active />;
  } else {
    const onFinish = (values: AgendaCompromissoFormFields) => {
      setIsSubmitting(true);

      const { data_range, hora_range, tipo, ...data } = values;

      data.dataInicio = data_range[0].format("YYYY-MM-DD");
      data.dataFinal = data_range[1].format("YYYY-MM-DD");
      data.horaInicio = hora_range[0].format("HH:mm");
      data.horaFim = hora_range[1].format("HH:mm");

      const promise = idAgendaCompromisso
        ? apiAgendaCompromisso.atualizaCompromisso(idAgendaCompromisso, data)
        : apiAgendaCompromisso.novoCompromisso(data);

      promise
        .then((response) => {
          const { data } = response.data;
          onOk?.(data);
        })
        .catch((error: any) => {
          if (axios.isAxiosError(error)) {
            const result = error.response?.data;
            if (result.errors) {
              for (const e of form.getFieldsError()) {
                form.setFields([{ name: e.name, errors: [] }]);
              }

              for (const key in result.errors) {
                let name = "data_range";
                const errors = (result.errors as any)[key] || [];

                if (["dataInicio", "dataFinal"].includes(key)) {
                  name = "data_range";
                } else if (["horaInicio", "horaFim"].includes(key)) {
                  name = "hora_range";
                } else if (form.getFieldInstance(key)) {
                  name = key;
                }

                form.setFields([{ name, errors }]);
              }
              return;
            }
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    };

    content = (
      <Form<AgendaCompromissoFormFields>
        form={form}
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
        onValuesChange={(values) => {
          if ("tipo" in values) {
            setTipo(values.tipo);
          }
        }}
      >
        <Form.Item
          name="tipo"
          label="Tipo"
          rules={[{ required: true, message: "Por favor selecione um tipo!" }]}
        >
          <Radio.Group disabled={!!idAgendaCompromisso}>
            <Radio.Button value="dentista">Dentista</Radio.Button>
            <Radio.Button value="clinica">Clínica</Radio.Button>
            <Radio.Button value="global">Global</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {tipo === "clinica" && (
          <Form.Item
            name="idClinica"
            label="Clínica"
            rules={[
              { required: true, message: "Por favor selecione uma clínica!" },
            ]}
          >
            <SelectClinica showSearch disabled={!!idAgendaCompromisso} />
          </Form.Item>
        )}
        {tipo === "dentista" && (
          <Form.Item
            name="idDentista"
            label="Dentista"
            rules={[
              { required: true, message: "Por favor selecione um dentista!" },
            ]}
          >
            <SelectDentista showSearch />
          </Form.Item>
        )}
        <Form.Item
          name="idCompromisso"
          label="Compromisso"
          rules={[
            { required: true, message: "Por favor selecione um compromisso!" },
          ]}
        >
          <SelectCompromisso showSearch />
        </Form.Item>
        <Form.Item
          name="data_range"
          label="Data"
          rules={[
            {
              required: true,
              message: "Por favor selecione um intervalo de data!",
            },
          ]}
        >
          <DatePicker.RangePicker format={"DD/MM/YYYY"} />
        </Form.Item>
        <Form.Item
          name="hora_range"
          label="Horário"
          rules={[
            {
              required: true,
              message: "Por favor selecione um intervalo de horário!",
            },
          ]}
        >
          <DatePicker.RangePicker
            picker="time"
            mode={undefined}
            format={"HH:mm"}
            minuteStep={5}
          />
        </Form.Item>
      </Form>
    );
  }

  return (
    <Modal
      title="Horárido indisponível"
      open={props.visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        onCancel?.();
      }}
      confirmLoading={isSubmitting}
    >
      {content}
    </Modal>
  );
};
