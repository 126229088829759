import { Route } from "@ant-design/pro-layout/es/typing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";

import { useAuth } from "../contexts";

interface MenusArgs {
  siglaClinica: string;
}

const baseUrl = process.env["REACT_APP_DH_URL"]?.replace(/\/$/, "");

function generateIcon(
  name: string,
  image?: string,
  faIcon?: string,
  color?: string
) {
  if (!color) {
    color = process.env.REACT_APP_STYLE_COLOR_PRIMARY;
  }

  if (faIcon) {
    return (
      <>
        <FontAwesomeIcon color={color} icon={faIcon as any} fixedWidth />{" "}
      </>
    );
  }

  if (image) {
    return (
      <>
        <img
          alt={name}
          src={`${baseUrl}/${image}`}
          className="ant-pro-sider-menu-icon"
        />{" "}
      </>
    );
  }

  return (
    <>
      <FontAwesomeIcon
        color={color}
        icon={"fa-solid fa-certificate" as any}
        fixedWidth
      />{" "}
    </>
  );
}

export function useMenus(props: MenusArgs) {
  const { siglaClinica } = props;

  const auth = useAuth();

  const menus = useMemo<Route>(() => {
    if (!siglaClinica) {
      return {};
    }

    const route: Route = {
      path: "",
      name: "Home",
      children: [],
    };

    const menuList = auth.user?.menus || [];
    for (const m of menuList) {
      const linkMenu = m.linkMenu.replace(/^[?/]/, "");

      if (m.target === "main") {
        route.children?.push({
          exact: true,
          key: `${m.idMenu}`,
          name: m.nomeMenu,
          path: `${siglaClinica}/legado?${linkMenu}`,
          icon: generateIcon(
            m.nomeMenu,
            m.imagemMenu,
            m.iconeMenu,
            m.corIconeMenu
          ),
        });
      }

      if (m.target === "react") {
        route.children?.push({
          name: m.nomeMenu,
          key: `${m.idMenu}`,
          path: `${siglaClinica}/${linkMenu}`,
          icon: generateIcon(
            m.nomeMenu,
            m.imagemMenu,
            m.iconeMenu,
            m.corIconeMenu
          ),
        });
      }

      if (m.target !== "sub" || !m.grupos?.length) {
        continue;
      }

      const nivel1: Route = {
        name: m.nomeMenu,
        key: `${m.idMenu}`,
        children: [],
        icon: generateIcon(
          m.nomeMenu,
          m.imagemMenu,
          m.iconeMenu,
          m.corIconeMenu
        ),
      };

      for (const g of m.grupos) {
        if (!g.itens.length) {
          continue;
        }

        const nivel2: Route = {
          name: g.descMenuItem,
          key: `${m.idMenu}-${g.descMenuItem}`,
          children: [],
        };

        for (const i of g.itens) {
          const linkMenuItem = i.linkMenuItem.replace(/^[?/]/, "");
          if (i.target === "main") {
            nivel2.children?.push({
              exact: true,
              key: `${m.idMenu}-${g.descMenuItem}-${linkMenuItem}`,
              name: i.nomeMenuItem,
              path: `${siglaClinica}/legado?${linkMenuItem}`,
              icon: generateIcon(
                i.nomeMenuItem,
                i.imagemMenuItem,
                i.iconeMenuItem,
                i.corIconeMenuItem
              ),
            });
          }

          if (i.target === "react") {
            nivel2.children?.push({
              name: i.nomeMenuItem,
              key: `${m.idMenu}-${g.descMenuItem}-${linkMenuItem}`,
              path: `${siglaClinica}/${linkMenuItem}`,
              icon: generateIcon(
                i.nomeMenuItem,
                i.imagemMenuItem,
                i.iconeMenuItem,
                i.corIconeMenuItem
              ),
            });
          }
        }

        nivel1.children?.push(nivel2);
      }

      route.children?.push(nivel1);
    }

    return route;
  }, [auth.user?.menus, siglaClinica]);

  return {
    menus,
  };
}
