import { PageHeader } from '@ant-design/pro-layout';
import { Empty, Table} from "antd";
import React, {useEffect, useState} from 'react';

import {ParcelasVisualizarProps} from "../types";

const ParcelasVisualizar: React.FC<ParcelasVisualizarProps> = (props) => {
    const [parcelas, setParcelas] = useState<any | null>(null);
    function initializeContract(){
        if(props.id){
            const newParcelas: any = {
                id: props.id,
                paciente: "Aarao Lyra",
                list:[]
            }
            for (let v = 0; v < 2; v += 1){
                newParcelas.list.push({
                    id: (444+v).toString(),
                    parcela: v,
                    status: "Não Compensado",
                    data: "22/01/2019",
                    data_vencimento: "22/01/2019",
                    valor_parcela: "R$ 1.000,00",
                    forma_pagamento: "Boleto Bancário(Banco Sicredi)",
                })
            }

            setParcelas(newParcelas)
        } else{
            setParcelas(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };


    return (
        <div>
            {parcelas ?
                <div className="parcelasDetalhes" >
                    <PageHeader
                        className="site-page-header"
                        title="Paciente"
                        subTitle={parcelas.paciente}
                    />
                    <p>Negociações</p>
                    <Table
                        rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                        }}
                        pagination={false} dataSource={parcelas.list} columns={[
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            key: 'id',
                        },
                        {
                            title: 'Parcelas',
                            dataIndex: 'parcelas',
                            key: 'parcelas',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                        },
                        {
                            title: 'Data',
                            dataIndex: 'data',
                            key: 'data',
                        },
                        {
                            title: 'Data Vencimento',
                            dataIndex: 'data_vencimento',
                            key: 'data_vencimento',
                        },
                        {
                            title: 'Valor Parcela',
                            dataIndex: 'valor_parcela',
                            key: 'valor_parcela',
                        },
                        {
                            title: 'Forma Pagamento',
                            dataIndex: 'forma_pagamento',
                            key: 'forma_pagamento',
                        }
                    ]} />
                </div>
                :<Empty />}
        </div>
    );
};

export default ParcelasVisualizar