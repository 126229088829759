import api from "../../api";
import { AgendaMotivoModel } from "../../models";
import { PaginatedRequest, PaginatedResponse } from "../../utilities";

export function list(
  options: {
    params?: PaginatedRequest<{
      q?: string;
      idAgendaStatus?: number;
      exigeMotivoEscrito?: boolean;
    }>;
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaMotivoModel>>(
    "cadastros/agenda-motivos",
    options
  );
}
