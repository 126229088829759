import { Col, Empty, Image, Row } from "antd";
import Text from "antd/es/typography/Text";
import React, { useState } from 'react';

const PacienteRaioX: React.FC = () => {
    const [usuario] = useState<any | null>({
        nome: "Ayla Amabyla Teixeira Mendonca",
        cpf: "009.577.709-10",
    });
    return (
        <div>
            {usuario ?
                <div className="usuarioDetalhes" >
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.nome}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>Nome:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17} push={7}>
                            <Text type="secondary">
                                {usuario.cpf}
                            </Text>
                        </Col>
                        <Col span={7} pull={17}>
                            <Text>CPF:</Text>
                        </Col>
                    </Row>

                    <div className='image-raio-x-contents'>
                        <Image.PreviewGroup>
                            <Image width={130} src="https://i.ytimg.com/vi/E3eAv7TOYeA/maxresdefault.jpg" />
                            <Image
                                width={130}
                                src="https://aventurasnahistoria.uol.com.br/media/_versions/maior_dente_do_mundo_widelg.jpg"
                            />
                        </Image.PreviewGroup>
                    </div>
                </div>
                :<Empty />}
        </div>
    );
};

export default PacienteRaioX