import { CheckOutlined } from "@ant-design/icons";
import ProForm, {
  ProFormDatePicker,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTreeSelect,
} from "@ant-design/pro-form";
import { Button, Col, message, Modal, Row , Typography } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { useClinica } from "../../contexts";
import api from "../../services/api";
import { InputMoney } from "./components";
import { ModalAntecipadoProps, ModalAReceberAcaoProps } from "./types";


const { Title } = Typography;

const ModalAntecipado: React.FC<ModalAntecipadoProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formRef = useRef<
    ProFormInstance<{
      idClinica: number;
      nomeClinica: string;
      descricao: string;
      dataDocumento: Date;
      dataVencimento: Date;
      valorFinal: string;
      valor: string;
    }>
  >();

  const [dataDocumento, setDataBaixa] = useState();
  const [dataVencimento, setDataVencimento] = useState();
  const [valorTotal, setValorTotal] = useState(0);

  const { clinicaAtual } = useClinica();

  const [formaPagamento, setFormaPagamento] = useState<[]>([]);
  const [contaGrupo, setContaGrupo] = useState<[]>([]);

  const [loadBancoSaida, setLoadBancoSaida] = useState(false);
  const [bancosSaida, setBancosSaida] = useState();

  const ValorTotal = (value: any) => {
    setValorTotal(value);
  };

  const DataContrato = (value: any) => {
    setDataVencimento(value.format("YYYY-MM-DD"));
  };

  const DataBaixa = (value: any) => {
    setDataBaixa(value.format("YYYY-MM-DD"));
  };

  const listaContaGrupo = async () => {
    if (contaGrupo.length) return contaGrupo;

    let lista: any = [];

    try {
      const { data } = await api.get("financeiro/list-grupo-conta", {
        params: { idClinica: clinicaAtual.id, tipoGrupo: 2 },
      });

      data.map(function (result: any, index: number) {
        lista[lista.length] = {
          value: result.idContaGrupo,
          title: result.nomeContaGrupo,
          children: result.childs.length ? ContasGrupos(result.childs) : [],
        };
      });
      setContaGrupo(lista);
      return lista;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  const ContasGrupos = (childs: []) => {
    let lista: any = [];

    childs.map(function (result: any, index: number) {
      lista[lista.length] = {
        value: result.idContaGrupo,
        title: result.nomeContaGrupo,
        children: result.childs.length ? ContasGrupos(result.childs) : [],
      };
    });

    return lista;
  };

  const listaBancosSaida = async () => {
    setLoadBancoSaida(true);
    let lista: any = [];

    try {
      const { data } = await api.get("financeiro/list-conta-capital", {
        params: { idClinica: clinicaAtual.id },
      });

      data.data.map(function (result: any, index: number) {
        lista[index] = {
          value: result.idContaCapital,
          label: result.contaCapital,
        };
      });

      setBancosSaida(lista);
      setLoadBancoSaida(false);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    listaBancosSaida();
  }, []);

  const listaFormaRecebimento = async () => {
    if (formaPagamento.length) return formaPagamento;

    let Fpagamento: any = [];

    try {
      const { data } = await api.get("financeiro/list-forma-recebimento", {
        params: {
          idClinica: clinicaAtual.id,
        },
      });

      data.map(function (result: any, index: number) {
        Fpagamento[index] = {
          value: result.idFormaPagamento,
          label: result.nomeFormaPagamento,
        };
      });
      setFormaPagamento(Fpagamento);
      return Fpagamento;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };
  
  useEffect(() => {
    const VM = props.visible;

    if (VM == true) {
      if (props.antecipado) {
        GetAntecipado();
      }
    }
  });

  async function GetAntecipado() {
    const { data } = await api.get(`financeiro/acao/${props.antecipado}`);
    formRef.current?.setFieldsValue(data.data);
  }

  return (
    <>
      <Modal
        title="Antecipado"
        open={props.visible}
        onCancel={async (e) => {
          formRef.current?.resetFields();
          props.onCancel();
          setConfirmLoading(false);
        }}
        onOk={async (e) => {
          formRef.current?.submit();
        }}
        width="80%"
        style={{ maxWidth: "1280px" }}
        footer={false}
        confirmLoading={confirmLoading}
      >
        <ProForm<{
          nomeClinica: string;
          descricao: string;
          valorFinal: string;
        }>
          formRef={formRef}
          request={async () => {
            return {
              nomeClinica: "",
              descricao: "",
              valorFinal: "",
            };
          }}
          submitter={{
            resetButtonProps: {
              style: {
                display: "none",
              },
            },
            submitButtonProps: {
              icon: <CheckOutlined />,
            },
            render: (props, doms) => {
              return [
                <Row justify="end">
                  <Col>
                    <Button
                      type="primary"
                      icon={<CheckOutlined />}
                      key="submit"
                      onClick={() => props.form?.submit()}
                    >
                      Baixa Lançamentos
                    </Button>
                  </Col>
                </Row>,
              ];
            },
          }}
          size="middle"
          dateFormatter="string"
          onFinish={async (e) => {
            setConfirmLoading(true);

            try {
              let data;

              if (props.idContaReceber) {
                data = await api.put(
                  `financeiro/acao/${props.idContaReceber}`,
                  formRef.current?.getFieldsValue()
                );
              } else {
                data = await api.post(
                  `financeiro/acao`,
                  formRef.current?.getFieldsValue()
                );
              }

              props.onCancel();

              message.info("Dados salvos!");

              formRef.current?.resetFields();
            } catch (error) {
              if (axios.isAxiosError(error)) {
                if (error.response?.data.errors) {
                  for (const name in error.response?.data.errors) {
                    const errors =
                      (error.response?.data.errors as any)[name] || [];
                    formRef.current?.setFields([{ name, errors }]);
                  }
                }
                if (error.message) {
                  message.error("Verifique os campos.");
                }
              }
            }
            setConfirmLoading(false);
          }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <ProFormText name="nomePaciente" label="Nome Paciente" disabled />
            </Col>
            <Col span={4}>
              <ProFormText name="cpfPaciente" label="CPF / CNPJ" disabled />
            </Col>
            <Col span={4}>
              <ProFormText
                name="prontuarioPaciente"
                label="Número Prontuario"
                disabled
              />
            </Col>
            <Col span={5}>
              <ProFormText name="nomeClinica" label="Clínica" disabled />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={3}>
              <ProFormText
                name="valorContratoFinal"
                label="Valor Contrato"
                disabled
              />
            </Col>
            <ProFormText name="idContrato" label="Contrato" disabled />
            <Col span={4}>
              <ProFormText 
                  name="dataContrato" label="Data Contrato"  
                  fieldProps={{
                  onChange: (value) => {
                    DataContrato(value);
                  },
                }} 
                disabled />
            </Col>
            <Col span={4}>
              <ProFormText
                name="dataVencimento"
                label="Data Vencimento"
                disabled
              />
            </Col>
            <Col span={4}>
            <ProFormDatePicker
                name="dataBaixa"
                fieldProps={{
                  format: "DD/MM/YYYY",
                  onChange: (value) => {
                    DataBaixa(value);
                  },
                }}
                label="Data Baixa"
                placeholder="__/__/____"
                required
                rules={[{ required: true, message: "Campo Obrigatório" }]}
                formItemProps={{
                  className: "MarginBottomFormInput",
                  style: { width: "100%" },
                }}
              />
            </Col>
            <Col span={3}>
              <ProForm.Item name="valorFinal" label="Valor Principal">
                <InputMoney disabled onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
          </Row>
          <br />
          <Title level={5}>Contabilização</Title>
          <Row gutter={24}>
            <Col span={5}>
              <ProForm.Item name="idContaCapital" label="Bancos" required>
                <ProFormSelect
                  options={bancosSaida}
                  name="idContaCapital"
                  fieldProps={{
                    loading: loadBancoSaida,
                  }}
                />
              </ProForm.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={5}>
              <ProForm.Item
                name="idFormaPagamento"
                label="Forma Recebimento"
                required
              >
                <ProFormSelect
                  placeholder="Selecione..."
                  request={listaFormaRecebimento}
                />
              </ProForm.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <ProFormTreeSelect
                label="Conta Grupo"
                name="idContaGrupo"
                request={listaContaGrupo}
                fieldProps={{
                  showArrow: true,
                  filterTreeNode: true,
                  showSearch: true,
                  dropdownMatchSelectWidth: true,
                  labelInValue: true,
                  autoClearSearchValue: true,
                  treeNodeFilterProp: "title",
                  fieldNames: {
                    label: "title",
                  },
                }}
              />
            </Col>
            <Col span={9}>
              <ProFormTextArea
                width="xl"
                label="Observação"
                name="observacao"
                formItemProps={{ className: "MarginBottomFormInput" }}
              />
            </Col>
          </Row>
          <Title level={5}>Baixa</Title>
          <Row gutter={24}>
            <Col span={3}>
              <ProForm.Item name="valorFinal" label="Valor">
                <InputMoney disabled onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
            <Col span={3}>
              <ProForm.Item name="juros" label="Valor Juros">
                <InputMoney onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
            <Col span={3}>
              <ProForm.Item name="multa" label="Valor Multa">
                <InputMoney onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
            <Col span={3}>
              <ProForm.Item name="desconto" label="Valor Desconto">
                <InputMoney onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
            <Col span={3}>
              <ProForm.Item name="valorIOF" label="IOF">
                <InputMoney onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
            <Col span={3}>
              <ProForm.Item name="valorTarifa" label="Tarifa">
                <InputMoney onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
            <Col span={3}>
              <ProForm.Item name="valorFinal" label="Total">
                <InputMoney disabled onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
          </Row>
        </ProForm>
      </Modal>
    </>
  );
};

export default ModalAntecipado;
