import { Form, Modal, Radio, Skeleton } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { DatePicker } from "../../../components";
import  {SelectUsuariosComercial}  from "../../../components/SelectUsuariosComercial";
import { useClinica } from "../../../contexts";
import { apiAgenda, apiAgendaCompromisso } from "../../../services/apis";
import { AgendaUsuarioHorarioModel } from "../../../services/models/AgendaUsuarioHorario";

interface AgendaUsuarioHorarioFormFields extends AgendaUsuarioHorarioModel {
  data_range: [dayjs.Dayjs, dayjs.Dayjs];
  primeiro_turno: [dayjs.Dayjs, dayjs.Dayjs];
  segundo_turno: [dayjs.Dayjs, dayjs.Dayjs];
  idUsuario: number;
}

export interface AgendaUsuarioHorarioFormModalProps {
  defaultTipo?: string;
  idAgendaUsuarioHorario?: null | number;
  visible?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
}

export const AgendaUsuarioHorarioFormModal: React.FC<
  AgendaUsuarioHorarioFormModalProps
> = (props) => {
  const { defaultTipo, idAgendaUsuarioHorario, onCancel, onOk } = props;

  const [isLoading, setIsLoading] = useState(!!idAgendaUsuarioHorario);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tipo, setTipo] = useState(defaultTipo || "dentista");
  const [initialValues, setInitialValues] = useState<
    Partial<AgendaUsuarioHorarioFormFields>
  >({});
	const { clinicaAtual } = useClinica();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!idAgendaUsuarioHorario || !props.visible) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    apiAgendaCompromisso
      .get(idAgendaUsuarioHorario)
      .then((response) => {
        const { data } = response.data;

        setInitialValues({
          ...data,
          data_range: [dayjs(data.dataInicio), dayjs(data.dataFinal)],
          primeiro_turno: [
            dayjs(`${data.dataInicio} ${data.horaInicio}`),
            dayjs(`${data.dataFinal} ${data.horaFim}`),
          ],
			segundo_turno: [
				dayjs(`${data.dataInicio} ${data.horaInicio}`),
				dayjs(`${data.dataFinal} ${data.horaFim}`),
			]
        });
      })
      .catch((error) => {
        // setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [defaultTipo, form, idAgendaUsuarioHorario, props, props.visible]);

  useEffect(() => {
    if (!isLoading) {
      form.resetFields();
    }
  }, [form, initialValues, isLoading]);

  let content = null;
  if (isLoading) {
    content = <Skeleton active />;
  } else {
    const onFinish = (values: AgendaUsuarioHorarioFormFields) => {
      
		setIsSubmitting(true);
		
		const data = {
      idClinica: clinicaAtual.id,
			idUsuario: values.idUsuario,
			inicioPrimeiroTurno: dayjs(values.primeiro_turno[0]).format("HH:mm"),
			finalPrimeiroTurno: dayjs(values.primeiro_turno[1]).format("HH:mm"),
			inicioSegundoTurno: values.segundo_turno?dayjs(values.segundo_turno[0]).format("HH:mm"):'',
			finalSegundoTurno: values.segundo_turno?dayjs(values.segundo_turno[1]).format("HH:mm"):'',
			dataAbertura: dayjs(values.data_range[0]).format("YYYY-MM-DD"),
			dataFechamento: dayjs(values.data_range[1]).format("YYYY-MM-DD"),
		};

      const promise = apiAgenda.addHorarioLiberado(data);
      promise
        .then((response) => {
          onOk?.();
        })
        .catch((error: any) => {
          if (axios.isAxiosError(error)) {
            const result = error.response?.data;
            if (result.errors) {
              for (const e of form.getFieldsError()) {
                form.setFields([{ name: e.name, errors: [] }]);
              }

              for (const key in result.errors) {
                let name = "data_range";
                const errors = (result.errors as any)[key] || [];

                if (["dataInicio", "dataFinal"].includes(key)) {
                  name = "data_range";
                } else if (["horaInicio", "horaFim"].includes(key)) {
                  name = "hora_range";
                } else if (form.getFieldInstance(key)) {
                  name = key;
                }

                form.setFields([{ name, errors }]);
              }
              return;
            }
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    };

    content = (
      <Form<AgendaUsuarioHorarioFormFields>
        form={form}
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
        onValuesChange={(values) => {
          if ("tipo" in values) {
            setTipo(values.tipo);
          }
        }}
      >
        
          <Form.Item
			name="idUsuario"
			label="Usuario"
			rules={[
				{
					required: true
				}
			]}
          >
            <SelectUsuariosComercial/>
          </Form.Item>
        
        <Form.Item
          name="data_range"
          label="Data"
          rules={[
            {
              required: true,
              message: "Por favor selecione um intervalo de data!",
            },
          ]}
        >
          <DatePicker.RangePicker format={"DD/MM/YYYY"} />
        </Form.Item>
        <Form.Item
          name="primeiro_turno"
          label="Horário"
          rules={[
            {
              required: true,
              message: "Por favor selecione um intervalo de horário!",
            },
          ]}
        >
          <DatePicker.RangePicker
            picker="time"
            mode={undefined}
            format={"HH:mm"}
            minuteStep={5}
          />
        </Form.Item>
        <Form.Item
          name="segundo_turno"
          label="Horário"
        >
          <DatePicker.RangePicker
            picker="time"
            mode={undefined}
            format={"HH:mm"}
            minuteStep={5}
          />
        </Form.Item>
      </Form>
    );
  }

  return (
    <Modal
      title="Horárido indisponível"
      open={props.visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        onCancel?.();
      }}
      confirmLoading={isSubmitting}
    >
      {content}
    </Modal>
  );
};
