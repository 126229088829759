import { EyeOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Table } from 'antd';
import React, { useState } from "react";

import OrcamentoModal from "./OrcamentoModal";

const { Column } = Table;

const data: any[] = [];

for (let i = 0; i <= 3; i++) data.push({
        clinica: "Londrina - Zona Norte",
        n_orcamento: "1234432",
        paciente: "Alex Teixeira da Silva",
        cpf: "837.389.331-98",
        data: "27/01/2021",
        valor_final: "R$ 773,00",
        pr: "60%"
    })

const AprovacaoOrcamento: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalBody, setModalBody] = useState<number>(1);
    const [ContractSelected, setContractSelected] = useState(null);

    async function OpenModal(id: any, key: number) {
        setContractSelected(id)
        setModalBody(key)
        setIsModalVisible(true);
    }

    const CloseModal = () => {
        setModalBody(1)
        setContractSelected(null)
        setIsModalVisible(false);
    };
    const menu = (row: any) =>  (
        <Menu onClick={(data) => OpenModal(row, parseInt(data.key))}>
            <Menu.Item key="4">Concluir Orçamento</Menu.Item>
            <Menu.Item key="3">Adicionar Observaçao</Menu.Item>
            <Menu.Item key="5">Alterar Orçamento</Menu.Item>
            <Menu.Item key="6">Imprimir</Menu.Item>
        </Menu>
    );
    return <div>
        <OrcamentoModal body={modalBody} visible={isModalVisible} onClose={CloseModal} id={ContractSelected} />
        <Table dataSource={data}>
            <Column title="Clinica" dataIndex="clinica" key="clinica" />
            <Column title="Nro. Orçamento" dataIndex="n_orcamento" key="n_orcamento" />
            <Column title="Nome Paciente" dataIndex="paciente" key="paciente" />
            <Column title="CPF" dataIndex="cpf" key="cpf" />
            <Column title="Data do Orçamento" dataIndex="data" key="data" />
            <Column title="Valor Final" dataIndex="valor_final" key="valor_final" />
            <Column title="" dataIndex="pr" key="pr" />
            <Column
                title=""
                dataIndex="n_orcamento"
                key="n_orcamento"
                render={row => (
                    <>
                        <Dropdown.Button onClick={() => OpenModal(row, 1)} overlay={menu.bind(null, row)}>
                            <EyeOutlined />
                        </Dropdown.Button>
                    </>
                )}
            />

        </Table>
    </div>

}

export default AprovacaoOrcamento