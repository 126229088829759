import ProForm, { ProFormText } from "@ant-design/pro-form";
import { Modal } from "antd";
import React from "react";

interface ModalAddRaioX {
  visible: boolean;
  addRaioX: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const Gerenciar: React.FC<ModalAddRaioX> = (props) => {
  return (
    <>
      <Modal
        title={"Adicionar Tipo Raio-X"}
        width={"50%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
     <ProForm submitter={false}>
        <ProForm.Group>
          <ProFormText name="nome" label="Tipo Raio-X"></ProFormText>
        </ProForm.Group>
      </ProForm>
      </Modal>
    </>
  );
};

export default Gerenciar;
