import { SearchOutlined } from "@ant-design/icons";
import { ProFormSelect,ProFormText, QueryFilter } from '@ant-design/pro-form';
import { Card } from "antd";
import React, { useState } from "react";

import styles from './search-filter.module.less';

type SearchProfissaoProps = {
  onFilterChange?: (allValues: any) => void;
  onSearch?: (text: string) => void;
  onTypeChange?: (type: string) => void;
  defaultType?: string;
};

const Profissao: React.FC<SearchProfissaoProps> = (props) => {
    
    const [showFilter] = useState<boolean>(true);

    
    
    return (
        <>
        <Card 
          bodyStyle={{ paddingBottom: 0 }}
          bordered={false}
          className={showFilter ? '' : styles.hiddenFilter}
         > 
        <div>
          </div>
   
             <QueryFilter
              labelWidth="auto"
              className={styles.filter}
              onFinish={async (values) => {
                console.log(values);
              }}
              submitter={{
                searchConfig: { submitText     : 'Buscar' },
                submitButtonProps: { icon      : <SearchOutlined /> },
              }}
             >
              <ProFormText 
                name="profissaoDescricao" 
                label="Nome Profissão" 
                placeholder="Nome da profissão"
              />
              <ProFormSelect
                name="profissaoStatus"
                showSearch
                label="Status"
                placeholder="Selecione um status"
                options={[
                  {
                    value: 0,
                    label: 'Inativo'
                  },
                  {
                    value: 1,
                    label: 'Ativo'
                  }
                ]}
              />
            </QueryFilter> 
        </Card>  
        </>
    );
}

export default Profissao;