import { PageContainer } from "@ant-design/pro-layout";
import { Result } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useClinica } from "../contexts";

export const Legado: React.FC = () => {
  let [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const clinica = useClinica();

  if (!searchParams.get("action")) {
    return <Result status={404} title={"Não encontrado"} />;
  }

  const baseUrl = process.env["REACT_APP_DH_URL"]?.replace(/\/$/, "");

  const params = searchParams.toString();
  const react = encodeURIComponent(`?${params}&registra_log=1&chamada_menu=1`);

  const url = `${baseUrl}/go.home?react=${react}&force_clinica=${clinica.clinicaAtual.id}`;

  return (
    <>
      <iframe
        title="Sistema Legado"
        src={url}
        className="legado"
        onLoad={(e) => {
          setIsLoading(false);
        }}
        onError={() => {
          setIsLoading(false);
        }}
        sandbox="allow-downloads allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      ></iframe>
      {isLoading && (
        <PageContainer title={false} breadcrumb={{}} loading={true} />
      )}
    </>
  );
};
