import React, { createContext, useContext, useState } from "react";


interface CheckoutContextData {
    optionsFace: any,
    teethSelected:  any,
    optionFaceSelected: number;
    setOptionFaceSelected(option: number): void;
    addTeeth(option: number): void;
    removeTeeth(option: number): void;
}

export const CheckoutContext = createContext<CheckoutContextData>(
    {} as CheckoutContextData
);

export const CheckoutProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [optionFaceSelected, setOptionFaceSelected] = useState<number>(1),
        [teethSelected, setTeethSelected] = useState<any>([]),
        optionsFace={
            1: 'boca',
            2: 'arcada',
            3: 'quadrante',
            4: 'dente',
        }

    function addTeeth(teeth: number){
        let newTeeth = teethSelected;
        newTeeth.push(teeth)
        setTeethSelected( newTeeth )
    }

    function removeTeeth(teeth: number){
        if(teethSelected.indexOf(teeth) !== -1){
            let newTeeth = teethSelected;
            newTeeth.splice(newTeeth.indexOf(teeth))
            setTeethSelected( newTeeth )
        }
    }


    return (
        <CheckoutContext.Provider
            value={{
                optionFaceSelected,
                optionsFace,
                teethSelected,
                setOptionFaceSelected,
                addTeeth,
                removeTeeth,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

export function useCheckout() {
    const context = useContext(CheckoutContext);

    if (!context) {
        throw new Error("useCheckout must be used within an CheckoutProvider.");
    }

    return context;
}
