import { Popover, PopoverProps } from "antd";
import classNames from "classnames";
import React from "react";

import styles from "./index.module.scss";

export type HeaderDropdownProps = {
  content: React.ReactNode | (() => React.ReactNode) | any;
} & Omit<PopoverProps, "content">;

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
  overlayClassName: cls,
  ...restProps
}) => (
  <Popover
    overlayClassName={classNames(styles.container, cls)}
    trigger={["hover"]}
    {...restProps}
  />
);

export default HeaderDropdown;
