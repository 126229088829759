import "./index.scss";

import { PageContainer } from "@ant-design/pro-layout";
import { Tabs } from 'antd';
import React from "react";

import ConsultasEmEsperasList from "./Components/ConsultasEmEsperasList";
import ConsultasRealizadasList from "./Components/ConsultasRealizadasList";
import HeaderFilter from "./Components/HeaderFilter";

const { TabPane } = Tabs;

const Orcamentos: React.FC = () => {
  return <PageContainer title="AVALIAÇÃO">
    <HeaderFilter />
    <div className="avaliacoes">
      <Tabs defaultActiveKey="4" centered>
        <TabPane tab="EM ESPERA" key="1">
          <ConsultasEmEsperasList ></ConsultasEmEsperasList>
        </TabPane>
        <TabPane tab="ATENDIDOS" key="2">
          <ConsultasRealizadasList />
        </TabPane>
      </Tabs>
    </div>
  </PageContainer>;
};

export default Orcamentos;