import { Select } from "antd";
import { RefSelectProps, SelectProps } from "antd/es/select";
import React, { useEffect, useState } from "react";

import { useUsuarios } from "../pages/Agenda/contexts/UsuariosContext";
import { UsuarioModel } from "../services/models/Usuario";

interface SelectUsuariosComercialProps<ValueType = number>
  extends SelectProps<ValueType, UsuarioModel> {
  idUsuario?: number;
}

export const SelectUsuariosComercial = React.forwardRef<
  RefSelectProps,
  SelectUsuariosComercialProps
>((props, ref) => {

  const { idUsuario, ...rest } = props;

	const usuarios = useUsuarios();
  
  return (
    <Select<number, UsuarioModel>
      allowClear
      placeholder={"Digite o nome do paciente"}
      showAction={["click", "focus"]}
      options={usuarios.usuarios}
      optionFilterProp={"apelidoDentista"}
      fieldNames={{
        label: "nomeUsuario",
        value: "idUsuario",
      }}
      {...rest}
      ref={ref}
    />
  );
});
