import { Input } from 'antd';
import React, { useState } from 'react';

const { TextArea } = Input;


const Observacao: React.FC = () => {

    return (
        <div style={{backgroundColor: "white"}}>
            <label>
                Observação:
                <TextArea rows={4} />
            </label>

        </div>
    );
};

export default Observacao