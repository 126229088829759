import { Modal, Tabs } from "antd";
import React from "react";

import ConfIndividual from "./ConfIndividual";
import DadosClinica from "./dadosClinica";
import Destinacao from "./Destinacoes";
import FormasPagamento from "./formaPagamento";
import Laboratorios from "./Laboratorio";
import RaioX from "./RaioX";
import Salas from "./Salas";

const { TabPane } = Tabs;

interface ModalGerenciadorClinica {
  visible: boolean;
  clinica: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const Gerenciar: React.FC<ModalGerenciadorClinica> = (props) => {
  return (
    <>
        <Modal
          title={"Formulário Gerenciador Clinica"}
          width={"70%"}
          open={props.visible}
          onOk={props.onOk}
          onCancel={props.onCancel}
        >
          <Tabs defaultActiveKey="1" size="middle">
            <TabPane tab="Dados Clínica" key="1">
              <DadosClinica />
            </TabPane>
            <TabPane tab="Formas de Pagamento" key="2">
              <FormasPagamento />
            </TabPane>
            <TabPane tab="Destinações" key="3">
              <Destinacao />
            </TabPane>
            <TabPane tab="Configurações Individuais" key="4">
              <ConfIndividual />
            </TabPane>
            <TabPane tab="Salas" key="5">
              <Salas />
            </TabPane>
            <TabPane tab="Laboratórios" key="6">
              <Laboratorios />
            </TabPane>
            <TabPane tab="Raio-X" key="7">
              <RaioX />
            </TabPane>
          </Tabs>
        </Modal>
    </>
  );
};

export default Gerenciar;
