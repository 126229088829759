import axios from "axios";

let basename = process.env.PUBLIC_URL || "";

if (/^https?:/.test(basename)) {
  const url = new URL(basename);
  basename = url.pathname
}

const api = axios.create({
  baseURL: `${basename.replace(/\/$/, "")}/front/`,
  headers: {
    "React-Front": "true",
  },
});

export default api;
