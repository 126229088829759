import { CheckOutlined,CloseOutlined } from "@ant-design/icons";
import ProForm, { ProFormInstance, ProFormSwitch , ProFormText } from "@ant-design/pro-form";
import { message,Modal } from "antd";
import axios from "axios";
import React, { useEffect,useRef, useState } from "react";

import api from "../../../services/api";
import { ModalProfissaoProps } from "./types";

const ModalProfissao: React.FC<ModalProfissaoProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formRef = useRef<
    ProFormInstance<{
      profissaoDescricao: string;
      profissaoStatus: boolean;
    }>
  >();

  useEffect(() => {
    const VM = props.visible;

    if (VM == true) {
      if (props.profissao) {
        GetProfissao();
      }
    }
  });

  async function GetProfissao() {
    const { data } = await api.get(`cadastros/profissoes/${props.profissao}`);
    formRef.current?.setFieldsValue(data.data);
  }

  return (
    <Modal
      title="Formulario gerenciamento de profissão"
      open={props.visible}
      onCancel={async (e) => {
        formRef.current?.resetFields();
        props.onCancel();
        setConfirmLoading(false);
      }}
      onOk={async (e) => {
        formRef.current?.submit();
      }}
      confirmLoading={confirmLoading}
    >
      <ProForm<{
        profissaoDescricao: string;
        profissaoStatus: boolean;
      }>
        formRef={formRef}
        formKey="profissao"
        request={async () => {
          return {
            profissaoDescricao: "",
            profissaoStatus: (true),
          };
        }}
        autoFocusFirstInput
        submitter={false}
        onFinish={async (e) => {
          setConfirmLoading(true);

          try {
            let data;

            if (props.profissao) {
              data = await api.put(
                `cadastros/profissoes/${props.profissao}`,
                formRef.current?.getFieldsValue()
              );
            } else {
              data = await api.post(
                `cadastros/profissoes`,
                formRef.current?.getFieldsValue()
              );
            }

            props.onCancel();
            props.loadPage();

            message.info("Dados salvos!");

            formRef.current?.resetFields();
          } catch (error) {
            if (axios.isAxiosError(error)) {
              if (error.response?.data.errors) {
                for (const name in error.response?.data.errors) {
                  const errors =
                    (error.response?.data.errors as any)[name] || [];
                  formRef.current?.setFields([{ name, errors }]);
                }
              }
              if (error.message) {
                message.error("Verifique os campos.");
              }
            }
          }
          setConfirmLoading(false);
        }}
      >
        <ProFormText
          name="profissaoDescricao"
          required
          label="Nome da profissão"
          placeholder="Nome da profissão"
          rules={[{ required: true, message: "Campo Obrigatório" }]}
        />
        <ProFormSwitch
          name="profissaoStatus"
          required
          label="Status"
          placeholder="Status da profissão"
          valuePropName={"checked"}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </ProForm>
    </Modal>
  );
};
export default ModalProfissao;
