import axios from "axios";
import React, { createContext,useContext, useEffect, useState } from "react";

import api from "../services/api";
import { Clinica, useClinica } from ".";

interface MenuItem {
  nomeMenuItem: string;
  imagemMenuItem: string;
  iconeMenuItem: string;
  corIconeMenuItem: string;
  linkMenuItem: string;
  descMenuItem: string;
  target: "main" | "react";
}

interface MenuGrupo {
  descMenuItem: string;
  itens: MenuItem[];
}

interface MenuPrincipal {
  idMenu: number;
  nomeMenu: string;
  imagemMenu: string;
  iconeMenu: string;
  corIconeMenu: string;
  linkMenu: string;
  target: "main" | "sub" | "react";
  grupos?: MenuGrupo[];
}

interface User {
  id: number;
  idDentista: number | null;
  usuario: string;
  nome: string;
  idClinicaPrincipal: number;
  idClinicaAtual: number;
  permissoes: { idConfigRegras: number; keyConfigRegras: string }[];
  menus: MenuPrincipal[];
}

interface LoginData {
  username: string;
  password: string;
  remember?: boolean;
}

interface LoginResultSuccess {
  isSuccess: true;
}

interface LoginResultFailed {
  isSuccess: false;
  errors?: {
    username?: string[];
    password?: string[];
  };
  message?: string;
}

type LoginResult = LoginResultSuccess | LoginResultFailed;

interface AuthContextData {
  authenticated: boolean;
  user: User | null;
  isCheckingAuthenticated: boolean;
  isLogging: boolean;
  isLogouting: boolean;

  /**
   * Verifica se o usuário tem permissão a todas permissões passadas no argumento.
   * Caso não tenha acesso a qualquer uma, retornar=a false
   */
  hasPermission(permissions: string | string[]): boolean;

  /**
   * Verifica se o usuário tem permissão a qualquer permissões passadas no argumento.
   * Caso não tenha acesso a todas, retornar=a false
   */
  hasAnyPermission(permissions: string | string[]): boolean;

  login(login: LoginData): Promise<LoginResult>;
  logout(): Promise<void>;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isCheckingAuthenticated, setIsCheckingAuthenticated] = useState(true);
  const [isLogging, setIsLogging] = useState(false);
  const [isLogouting, setIsLogouting] = useState(false);

  const clinica = useClinica();

  async function checkIsLogged() {
    setIsCheckingAuthenticated(true);
    try {
      const { data } = await api.get<User & { clinicas: Clinica[] }>("user/me");

      setUser({
        id: data.id,
        idDentista: data.idDentista,
        usuario: data.usuario,
        nome: data.nome,
        idClinicaPrincipal: data.idClinicaPrincipal,
        idClinicaAtual: data.idClinicaAtual,
        permissoes: data.permissoes,
        menus: data.menus,
      });

      clinica.setClinicas(data.clinicas);
      clinica.setClinicaPrincipal(data.idClinicaPrincipal);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
      setUser(null);
    }

    setIsCheckingAuthenticated(false);
  }

  useEffect(() => {
    checkIsLogged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function login({
    username,
    password,
    remember,
  }: LoginData): Promise<LoginResult> {
    setIsLogging(true);

    try {
      await api.post("login", {
        username,
        password,
        remember,
      });

      await checkIsLogged();

      return {
        isSuccess: true,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data;

        return {
          isSuccess: false,
          ...data,
        };
      }
    } finally {
      setIsLogging(false);
    }

    return {
      isSuccess: false,
      message: "Erro desconhecido",
    };
  }

  async function logout() {
    setIsLogouting(true);

    try {
      await api.post("logout");
      await checkIsLogged();
    } catch (error) {}

    setIsLogouting(false);
  }

  function hasPermission(permissions: string | string[]) {
    if (!user) {
      return false;
    }

    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }

    return permissions.every((permission) =>
      user.permissoes.find((v) => v.keyConfigRegras === permission)
    );
  }

  function hasAnyPermission(permissions: string | string[]) {
    if (!user) {
      return false;
    }

    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }

    return permissions.some((permission) =>
      user.permissoes.find((v) => v.keyConfigRegras === permission)
    );
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        isCheckingAuthenticated,
        isLogging,
        isLogouting,
        hasPermission,
        hasAnyPermission,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}
