/* eslint-disable react-hooks/rules-of-hooks */
import { DeleteOutlined, MonitorOutlined } from "@ant-design/icons";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Dropdown,Menu, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";

import ModalVisualizarImg from "./ModalVisualizarImg";
import { BuscarRaioXData } from "./types";

interface BuscarRaioXDatA {
  visible: boolean;
  buscarImg: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const menuAcoes = (key: any) => {
  return (
    <Menu>
      <Menu.Item key="1" danger icon={<DeleteOutlined />}>
        <Popconfirm
          title="Esta certo disso ?"
          onConfirm={() => {}}
          onCancel={() => {}}
          placement="top"
        >
          Deletar
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
};

const emissaoRaioX: React.FC<BuscarRaioXDatA> = () => {
  const [dados, setDados] = useState<BuscarRaioXData[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: BuscarRaioXData[] = [];

    for (let i = 0; i < 1; i += 1) {
      tableList.push({
        tipoRaioX: "Documentação Ortodôntica",
        quantidade: "1",
        hora: "16:41",
        paciente: "Robson Assis",
        prontuario: "LZN-0000022-LZN",
        valor: "R$ 120,00",
        formaPagamento: "Boleto Bancário(Banco Bradesco)",
        usuario: "Usuario Sistema",
        recibo: "",
        obs: "teste",
      });
    }
    setDados(tableList);
  }, []);

  const columns: ProColumns<BuscarRaioXData>[] = [
    {
      title: "Tipo Raio-X",
      dataIndex: "tipoRaioX",
    },
    {
      title: "Qtde.",
      dataIndex: "quantidade",
    },
    {
      title: "Hora",
      dataIndex: "hora",
    },
    {
      title: "Paciente",
      dataIndex: "paciente",
    },
    {
      title: "Prontuario",
      dataIndex: "prontuario",
    },
    {
      title: "Valor",
      dataIndex: "valor",
    },
    {
      title: "Forma Pgto.",
      dataIndex: "formaPagamento",
    },
    {
      title: "Usuário",
      dataIndex: "usuario",
    },
    {
      title: "Recibo",
      dataIndex: "recibo",
    },
    {
      title: "Observação",
      dataIndex: "obs",
    },
    {
      title: "Imagens",
      dataIndex: "imagens",
      render: (dom, row) => (
        <span>
          <Dropdown.Button
            onClick={() => OpenModalVisualizar(row)}
            overlay={menuAcoes(row)}
            trigger={["click"]}
          >
            <MonitorOutlined />
            Visualizar Imagens
          </Dropdown.Button>{" "}
        </span>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imagens, setImagens] = useState(false);

  async function OpenModalVisualizar(row: any) {
    if (row) {
      setImagens(row);
    } else {
      setImagens(false);
    }
    setIsModalVisible(true);
  }

  return (
    <>
      {imagens && (
        <ModalVisualizarImg
          visible={isModalVisible}
          viImg={imagens}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      <ProTable<BuscarRaioXData>
        columns={columns}
        request={(params, sorter, filter) => {
          return Promise.resolve({
            data: tableList,
            success: true,
          });
        }}
        rowKey="key"
        pagination={{
          showQuickJumper: true,
        }}
        search={false}
        dateFormatter="string"
      />
    </>
  );
};

export default emissaoRaioX;
