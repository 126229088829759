import { DatePicker, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { SelectCompromisso } from "../../../components/SelectCompromisso";
import { useClinica } from "../../../contexts";
import { apiAgenda } from "../../../services/apis";
import { AgendaUsuarioCompromissoModel } from "../../../services/models/AgendaUsuarioCompromisso";
import { UsuarioModel } from "../../../services/models/Usuario";
import { useUsuarios,UsuariosProvider } from "../../Agenda/contexts/UsuariosContext";


interface NovoCompromissoUsuarioModalProps {
  data?: string;
  horaFim?: string;
  horaInicio?: string;
  idAgendaCompromisso?: null | number;
  idUsuario?: number;
  idCompromisso?: number;
  visible: boolean;
  onOk?: (agenda: AgendaUsuarioCompromissoModel) => void;
  onCancel?: () => void;
}

export const NovoCompromissoUsuarioModal: React.FC<
  NovoCompromissoUsuarioModalProps
> = (props) => {
  const { visible, onCancel, onOk, idAgendaCompromisso } = props;

  const clinica = useClinica();
  const usuarios = useUsuarios();

  const [isLoading, setIsLoading] = useState(!!idAgendaCompromisso);
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(
    {}
  );
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});

  const [form] = Form.useForm();

  useEffect(() => {

    if (visible) {
      form.resetFields();
      setFormValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, visible, initialValues]);

  const onFinish = (values: any) => {
    setIsLoading(true);

    const data = {
      dataInicio: values.data_range[0].format("YYYY-MM-DD"),
      dataFinal: values.data_range[1].format("YYYY-MM-DD"),
      horaInicio: values.hora_range[0].format("HH:mm"),
      horaFim: values.hora_range[1].format("HH:mm"),
      idUsuario: values.idUsuario,
      idCompromisso: values.idCompromisso,
      idClinica: clinica.clinicaAtual.id,
    };

    const promise = apiAgenda.novoUsuarioCompromisso(data);

    promise
    .then((response: { data: any; }) => {
      onOk?.(response.data);
    })
    .catch((error: any) => {
      if (axios.isAxiosError(error)) {
        const result = error.response?.data;
        if (result.errors) {
          for (const e of form.getFieldsError()) {
            form.setFields([{ name: e.name, errors: [] }]);
          }

          for (const key in result.errors) {
            let name = "data_range";
            const errors = (result.errors as any)[key] || [];

            if (["dataInicio", "dataFinal"].includes(key)) {
              name = "data_range";
            } else if (["horaInicio", "horaFim"].includes(key)) {
              name = "hora_range";
            } else if (form.getFieldInstance(key)) {
              name = key;
            }

            form.setFields([{ name, errors }]);
          }
          return;
        }
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <UsuariosProvider>
      <Modal
        title="Horários Indisponíveis"
        width={450}
        open={visible}
        onCancel={() => {
          onCancel?.();
        }}
        onOk={() => form.submit()}
        confirmLoading={isLoading}
      >
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
          onValuesChange={(_, values) => {
            setFormValues(values);
          }}
        >
          <Form.Item hidden name="idAgendaCompromisso">
            <Input type={"hidden"} />
          </Form.Item>

      <Form.Item
				name="idUsuario"
				label="Usuario"
				rules={[
				{ required: true, message: "Por favor selecione um Usuario!" }, 
				]}
			>
				<Select<number, UsuarioModel>
					allowClear
					placeholder={"Digite o nome do Usuario"}
					options={usuarios.usuarios}
					optionFilterProp={"nomeUsuario"}
          fieldNames={{
              label: "nomeUsuario",
              value: "idUsuario",
           }}
				/>
			</Form.Item>
          <Form.Item
            name="idCompromisso"
            label="Compromisso"
            rules={[
              {
                required: true,
                message: "Por favor selecione um compromisso!",
              },
            ]}
          >
            <SelectCompromisso showSearch />
          </Form.Item>
          <Form.Item
            name="data_range"
            label="Data"
            rules={[
              {
                required: true,
                message: "Por favor selecione um intervalo de data!",
              },
            ]}
          >
            <DatePicker.RangePicker format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item
            name="hora_range"
            label="Horário"
            rules={[
              {
                required: true,
                message: "Por favor selecione um intervalo de horário!",
              },
            ]}
          >
            <DatePicker.RangePicker
              picker="time"
              mode={undefined}
              format={"HH:mm"}
              minuteStep={15}
            />
          </Form.Item>
        </Form>
      </Modal>
      </UsuariosProvider>
    </>
  );
};
