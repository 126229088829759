import ProForm, { ProFormSelect } from "@ant-design/pro-form";
import { Modal } from "antd";
import React from "react";

import SelecioneDentes from "./SelecioneDentes";

interface ModalSelecioneAdd {
  visible: boolean;
  selecioneAdd: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const OrdemServico: React.FC<ModalSelecioneAdd> = (props) => {
  // const [isVisible, setIsVisible] = useState(false);
  // const [selecioneDentes, setSelecioneDentes] = useState(false);

  // async function OpenDentes(row: any) {
  //   if (row) {
  //     setSelecioneDentes(row);
  //   } else {
  //     setSelecioneDentes(false);
  //   }
  // }

  return (
    <>
      <Modal
        title={"Serviços"}
        width={"40%"}
        open={props.visible}
        onOk={props.onOk}
        onCancel={props.onCancel}
      >
        <table>
          <thead>
            <tr>
              <td align="left">
                <b>Adicionar serviço:</b>
              </td>
            </tr>
            <ProForm.Group>
              <ProFormSelect
                label="Setor"
                name="setor"
                width="sm"
                rules={[
                  {
                    required: true,
                  },
                ]}
                options={[
                  {
                    value: "1",
                    label: "CAD / CAM",
                  },
                ]}
              />
              <ProFormSelect
                label="Serviço"
                name="servico"
                rules={[
                  {
                    required: true,
                  },
                ]}
                options={[
                  {
                    value: "1",
                    label: "ENCERAMENTO DE DIAGNOSTICO POR ELEMENTO",
                  },
                ]}
              />
            </ProForm.Group>
            <br />
            <tr>
              <td align="left">
                <b>Selecione Dentes:</b>
              </td>
            </tr>
            <br />
            <SelecioneDentes visible={false} selecioneDentes={false} />
          </thead>
        </table>
      </Modal>
    </>
  );
};

export default OrdemServico;
