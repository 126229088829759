import { UserOutlined } from "@ant-design/icons";
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Dropdown, Empty, Menu, Table, Tag } from "antd";
import React, { useEffect, useState } from 'react';

import { ParcelasVisualizarProps } from "../types";
import ContratoParcelaModal from "./ContratoParcelaModal";

const ContratoParcelas: React.FC<ParcelasVisualizarProps> = (props) => {
    const [parcelas, setParcelas] = useState<any | null>(null);
    const [tipoJuros, setTipoJuros] = useState<string>("2");
    const [hasSelection, setHasSelection] = useState<boolean>(false);
    const [qtdPagamentos, setQtdPagamentos] = useState<number>(1);
    function initializeContract(){
        if(props.id){
            const newParcelas: any = {
                id: props.id,
                paciente: "Aarao Lyra",
                list:[]
            }
            for (let v = 0; v < 2; v += 1){
                newParcelas.list.push({
                    id: (4434+v).toString(),
                    parcela: v+1,
                    status: "Não Compensado",
                    data: "22/01/2019",
                    data_vencimento: "22/01/2019",
                    valor_parcela: "R$ 1.000,00",
                    forma_pagamento: {text: "Boleto Bancário(Banco Sicredi)", color: "geekblue"},
                })
            }
            for (let v = 0; v < 2; v += 1){
                newParcelas.list.push({
                    id: (4434+v).toString(),
                    parcela: v+3,
                    status: "Compensado",
                    data: "22/01/2019",
                    data_vencimento: "22/01/2019",
                    valor_parcela: "R$ 1.400,00",
                    forma_pagamento: {text: "Cartão de Débito(Elo Debito)", color: "green"},
                })
            }

            for (let v = 0; v < 1; v += 1){
                newParcelas.list.push({
                    id: (222+v).toString(),
                    parcela: 2,
                    status: "Não Formalizado",
                    data: "22/01/2019",
                    data_vencimento: "22/01/2019",
                    valor_parcela: "R$ 1.400,00",
                    forma_pagamento: {text: "Boleto Bancário(Banco Sicredi)", color: "geekblue"},
                })
            }

            setParcelas(newParcelas)
        } else{
            setParcelas(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    function handleChange(value: string) {
        setTipoJuros(value)
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalBody, setModalBody] = useState<number>(1);
    const [CotratoSelected, setCotratoSelected] = useState(null);

    async function OpenModal(id: any, key: number) {
        setCotratoSelected(id)
        setModalBody(key)
        setIsModalVisible(true);
    }
    const CloseModal = () => {
        setModalBody(1)
        setCotratoSelected(null)
        setIsModalVisible(false);
    };

    const menu = (row: any) =>  (
        <Menu onClick={(data) => OpenModal(row, parseInt(data.key))}>
            <Menu.Item key="3">Alterar Pagante</Menu.Item>
            <Menu.Item key="6">Reverter para status inicial</Menu.Item>
            <Menu.Item key="2">Alterar data de vencimento</Menu.Item>
            <Menu.Item key="7">Formalizar</Menu.Item>
        </Menu>
    );

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setHasSelection(selectedRows.length > 0)
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            {parcelas ?
                <div className="parcelasDetalhes" >
                    <ContratoParcelaModal visible={isModalVisible} id={CotratoSelected} body={modalBody} onClose={CloseModal} />
                    <PageHeader
                        className="site-page-header"
                        title="Paciente"
                        subTitle={parcelas.paciente}
                    />

                    <div className={"filters"} style={{marginBottom: 30}}>
                        <p style={{margin: 4}}>Ações para parcelas </p>
                        <div>
                            <Button disabled={!hasSelection} type="dashed">
                                Gerar Recibo
                            </Button>
                            <Button disabled={!hasSelection} type="dashed">
                                Gerar Boleto
                            </Button>
                            <Button disabled={!hasSelection} type="dashed">
                                Alterar Pagantes
                            </Button>
                            <Button type="dashed">
                                Conferir e enviar vendas
                            </Button>
                        </div>
                    </div>

                    <Table id="customTableParcelas"
                           rowSelection={{
                               type: "checkbox",
                               ...rowSelection,
                           }}
                           rowClassName={(record, index) => (record.status === "Compensado" ? "bg-green" : "")}
                        pagination={false} dataSource={parcelas.list} columns={[
                        {
                            title: '#',
                            dataIndex: 'parcela',
                            key: 'parcela',
                            render: p => (
                                p === 1 ? "ENTRADA": "PARCELAS"
                            )
                        },
                        {
                            title: 'ID',
                            dataIndex: 'id',
                            key: 'id',
                        },
                        {
                            title: 'Forma Pagamento',
                            dataIndex: 'forma_pagamento',
                            key: 'forma_pagamento',
                            filters: [
                                { text: 'Boleto Bancário', value: '1' },
                                { text: 'Dinheiro', value: '2' },
                                { text: 'Pix', value: '4' },
                                { text: 'Cartão de Débito', value: '4' },
                                { text: 'Parcelas Carteira', value: '3' },
                            ],
                            render: p => (
                                <Tag color={p.color}>{ p.text }</Tag>
                            ),
                        },
                        {
                            title: 'Parcelas',
                            dataIndex: 'parcela',
                            key: 'parcela',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                        },
                        {
                            title: 'Data',
                            dataIndex: 'data',
                            key: 'data',
                        },
                        {
                            title: 'Data Vencimento',
                            dataIndex: 'data_vencimento',
                            key: 'data_vencimento',
                        },
                        {
                            title: 'Valor Parcela',
                            dataIndex: 'valor_parcela',
                            key: 'valor_parcela',
                        },
                        {
                            title: 'Ações',
                            dataIndex: 'id',
                            key: 'id',
                            render: (p, row) => (
                                <>
                                    <Dropdown.Button onClick={() => OpenModal(row, 1)} overlay={menu.bind(null, row)}>
                                        <UserOutlined />
                                    </Dropdown.Button>
                                </>
                            ),
                        }
                    ]} />
                </div>
                :<Empty />}
        </div>
    );
};

export default ContratoParcelas