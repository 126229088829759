import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Event } from "react-big-calendar";

import { apiAgenda } from "../../../services/apis";
import { AgendaUsuarioCompromissoModel } from "../../../services/models/AgendaUsuarioCompromisso";

export interface EventoCompromisso
  extends Event,
    AgendaUsuarioCompromissoModel {}

interface CompromissoArgs {
  dataInicial: dayjs.ConfigType;
  dataFinal?: dayjs.ConfigType;
  dataInicio?: dayjs.ConfigType;
  idClinica: number;
  idUsuario: number[];
  idAgendaCompromisso?: number;
  idCompromisso?: number;
}

export function useAgendaUsuarioCompromissos({
  dataInicial,
  dataFinal,
  idClinica,
  idUsuario,
  idAgendaCompromisso,
  dataInicio,
}: CompromissoArgs) {
  const [compromissos, setCompromissos] = useState<EventoCompromisso[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    apiAgenda
      .getAgendaUsuarioCompromissos({
          dataFinal: dayjs(dataFinal).format("YYYY-MM-DD"),
          dataInicio: dayjs(dataInicial).format("YYYY-MM-DD"),
          idClinica: idClinica,
          idUsuario,
      })
      .then((response) => {
        const { data } = response.data;

        const lista: EventoCompromisso[] = [];

        for (const d of data) {
          const start = dayjs(
            `${d.dataInicio.substring(0, 10)} ${d.horaInicio}`
          );
          const fim = dayjs(`${d.dataFinal.substring(0, 10)} ${d.horaFim}`);

          let i = start.clone();

          while (i.isSameOrBefore(fim)) {
            lista.push({
              ...d,
              title: d.compromisso.motivo,
              start: i.toDate(),
              end: i.hour(fim.hour()).minute(fim.minute()).toDate(),
            });

            i = i.add(1, "day");
          }
        }

        setCompromissos(lista);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // useEffect usa comparação rasa, então arrays vazios são diferentes
    // para contornar isso, usamos o JSON.stringify para converter para string o valor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idClinica,
    dataInicial,
    dataFinal,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(idUsuario),
  ]);

  return {
    compromissos,
    error,
    isLoading
  };
}
