import ProForm, { ProFormSelect } from "@ant-design/pro-form";
import ProList from "@ant-design/pro-list";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";

import { Salas } from "./types";

const { TabPane } = Tabs;

const Gerenciar: React.FC = () => {
  const [dados, setDados] = useState<Salas[]>([]);
  const List = dados;

  useEffect(() => {
    const List: Salas[] = [];

    for (let i = 0; i < 5; i += 1) {
      List.push({
        numeroSala: "AVA1",
      });
    }
    setDados(List);
  }, []);

  return (
    <>
      <Tabs defaultActiveKey="1" size="middle">
        <TabPane tab="Salas" key="1">
          <p>Cadastrados: </p>
          <ProList<Salas>
            rowKey="key"
            dataSource={List}
            onRow={(record: any) => {
              return {
                  onClick: () => {
                      console.log(record);
                  },
              };
          }}
          />
          <p>Adicionar: </p>
          <ProForm submitter={false}>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "0",
                },
                {
                  value: "2",
                  label: "1",
                },
              ]}
              name="avaliacao"
              label="Avaliação"
              width="md"
            ></ProFormSelect>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "0",
                },
                {
                  value: "2",
                  label: "1",
                },
              ]}
              name="consulta"
              label="Consulta"
              width="md"
            ></ProFormSelect>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "0",
                },
                {
                  value: "2",
                  label: "1",
                },
              ]}
              name="cirurgia"
              label="Cirurgia"
              width="md"
            ></ProFormSelect>
          </ProForm>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Gerenciar;
