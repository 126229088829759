import { AutoComplete, Button, Col, Empty, Row, Table } from "antd";
import { SelectProps } from "antd/es/select";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from 'react';

import { OrcamentoVisualizarProps } from "../types";

function getRandomInt(max: number, min: number = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
}
const searchResult = (query: string) =>
    new Array(getRandomInt(5))
        .join('.')
        .split('.')
        .map((_, idx) => {
            const category = `${query}${idx}`;
            return {
                value: category,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
            <span>
              Found {query} on{' '}
                <a
                    href={`https://s.taobao.com/search?q=${query}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                {category}
              </a>
            </span>
                        <span>{getRandomInt(200, 100)} results</span>
                    </div>
                ),
            };
        });

const OrcamentoVisualizar: React.FC<OrcamentoVisualizarProps> = (props) => {
    const [oracamento, setOrcamento] = useState<any | null>(null);
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);

    const handleSearch = (value: string) => {
        setOptions(value ? searchResult(value) : []);
    };
    function initializeContract(){
        if(props.id){
            const newOrcamento: any = {
                oracamento_id: props.id,
                paciente: "Simone Aparecida Ribeiro Da Silva (C+p)",
                contrato: "371379",
                versao: "1",
                data: "01/02/2022",
                valor_final: "R$ 156,18",
                parcelas:[]
            }

            for (let v = 0; v < 2; v += 1){
                newOrcamento.parcelas.push({
                    id: (444+v).toString(),
                    parcela: v,
                    status: "Não Compensado",
                    data: "22/01/2019",
                    data_vencimento: "22/01/2019",
                    valor_parcela: "R$ 1.000,00",
                    forma_pagamento: "Boleto Bancário(Banco Sicredi)",
                })
            }

            setOrcamento(newOrcamento)
        } else{
            setOrcamento(null)
        }
    }
    function callback(key: any) {
        console.log(key);
    }
    const onSelect = (value: string) => {
        console.log('onSelect', value);
    };
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <div>
            {oracamento ?
                <div className="oracamentoDetalhes" >
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                <strong>{oracamento.contrato}</strong>
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Contrado:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.paciente}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Paciente:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.versao}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Versão:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.data}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Data:</Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={18} push={6}>
                            <Text type="secondary">
                                {oracamento.valor_final}
                            </Text>
                        </Col>
                        <Col span={6} pull={18}>
                            <Text>Valor Final:</Text>
                        </Col>
                    </Row>
                    <Title style={{marginTop: "20px"}} level={5}>Parcelas para alteração</Title>
                    <Table
                        rowClassName={(record, index) => (record.orcamento_anterior ? "bg-cyan-2" : "")}
                        pagination={false} dataSource={oracamento.parcelas} columns={[

                        {
                            title: 'Numero',
                            dataIndex: 'parcelas',
                            key: 'parcelas',
                        },
                        {
                            title: 'Data Vencimento',
                            dataIndex: 'data_vencimento',
                            key: 'data_vencimento',
                        },
                        {
                            title: 'Forma Pagamento',
                            dataIndex: 'forma_pagamento',
                            key: 'forma_pagamento',
                        },
                        {
                            title: 'Pagante Atual',
                            dataIndex: 'pagante_atual',
                            key: 'pagante_atual',
                        },
                        {
                            title: 'Valor Parcela',
                            dataIndex: 'valor_parcela',
                            key: 'valor_parcela',
                        },

                    ]} />
                    <div className="filters">
                        <label>
                            Novo Pagante:

                            <AutoComplete
                                showSearch={false}
                                dropdownMatchSelectWidth={252}
                                style={{ width: 300 }}
                                options={options}
                                onSelect={onSelect}
                                onSearch={handleSearch}
                            >
                            </AutoComplete>
                        </label>
                        <Button type="primary" size="large">
                            Definir
                        </Button>
                    </div>
                </div>
                :<Empty />}
        </div>
    );
};

export default OrcamentoVisualizar