import api from "../../api";
import { AgendaStatusModel } from "../../models";
import { PaginatedRequest, PaginatedResponse } from "../../utilities";

export function list(
  options: {
    params?: PaginatedRequest<{
      q?: string;
      exibeAvaliacao?: boolean;
      exigeMotivo?: boolean;
      removeLista?: boolean;
    }>;
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaStatusModel>>(
    "cadastros/agenda-status",
    options
  );
}
