import { EllipsisOutlined } from "@ant-design/icons";
import ProLayout, {
  DefaultFooter,
  MenuDataItem,
  PageContainer,
} from "@ant-design/pro-layout";
import { Col, List, Popover, Row, Tabs } from "antd";
import { ConfigContext } from "antd/es/config-provider";
import OverrideContext from "antd/es/menu/OverrideContext";
import useStyle from "antd/es/menu/style";
import classNames from "classnames";
import Overflow from "rc-overflow";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import GlobalHeaderRight from "../components/MenuHeaderRight";
import { useClinica } from "../contexts";
import { useMenus } from "../hooks/useMenus";
import logoRetangular from "../media/logo-s.png";

const ListLinks: React.FC<{
  title: string;
  menus?: MenuDataItem[];
}> = (props) => {
  return (
    <List
      size="small"
      header={<b>{props.title}</b>}
      dataSource={props.menus}
      renderItem={(item) => {
        let menu: any = "";

        if (item.path) {
          menu = <Link to={item.path}>{item.name}</Link>;
        }

        if (item.icon) {
          if (React.isValidElement(item.icon)) {
            menu = (
              <span>
                {item.icon}
                {menu}
              </span>
            );
          }
        }
        return <List.Item className="ant-menu-item">{menu}</List.Item>;
      }}
    />
  );
};

const DropdownMenu: React.FC<{
  menu: MenuDataItem;
}> = (props) => {
  const menuData = props.menu;

  if (menuData.path) {
    return (
      <Link to={menuData.path}>
        {menuData.icon}
        {menuData.name}
      </Link>
    );
  }

  const submenu = menuData.children?.map((c, i) => {
    return (
      <Col key={i + ""}>
        <ListLinks title={c.name || ""} menus={c.children} />
      </Col>
    );
  });

  return (
    <Popover
      // open={true}
      className="ant-menu-title-content"
      placement="bottom"
      content={
        <div
          className="menu-popover-personalizado"
          style={{
            padding: "0px 8px",
            width: "auto",
            maxWidth: "calc(100vw - 100px)",
            height: "auto",
            maxHeight: "calc(100vh - 80px)",
            overflowY: "auto",
          }}
        >
          <Row gutter={[16, 16]}>{submenu}</Row>
        </div>
      }
    >
      <div className="ant-menu-submenu-title">
        {menuData.icon}
        {menuData.name}
        {/* <CaretDownFilled /> */}
      </div>
    </Popover>
  );
};

const AllDropdownMenu: React.FC<{
  menus: MenuDataItem[];
}> = (props) => {
  return (
    <Popover
      // open={true}
      className="ant-menu-title-content"
      placement="bottom"
      content={
        <div
          className="menu-popover-personalizado"
          style={{
            padding: "0px 8px",
            width: "calc(100vw - 100px)",
            maxWidth: "calc(100vw - 4px)",
            height: "auto",
            maxHeight: "calc(100vh - 80px)",
            overflowY: "auto",
          }}
        >
          <Tabs
            centered
            defaultActiveKey="0"
            items={props.menus.map((menus, index) => ({
              label: (
                <>
                  {menus.icon}
                  {menus.name}
                </>
              ),
              key: index + "",
              children: (
                <Row gutter={[16, 16]} justify="center">
                  {(menus.children || [menus])?.map((c, i) => (
                    <Col key={i + ""}>
                      <ListLinks
                        title={c.name || ""}
                        menus={c.children || [menus]}
                      />
                    </Col>
                  ))}
                </Row>
              ),
            }))}
          />
        </div>
      }
    >
      <div className="ant-menu-submenu-title">
        <EllipsisOutlined />
      </div>
    </Popover>
  );
};

const PageLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const clinica = useClinica();
  const location = useLocation();
  const navigate = useNavigate();

  const siglaClinica =
    clinica.clinicaAtual?.sigla || clinica.clinicaPrincipal.sigla;

  const { menus } = useMenus({ siglaClinica });

  // @ts-ignore
  const override = React.useContext(OverrideContext);
  const { getPrefixCls } = React.useContext(ConfigContext);

  const prefixCls = getPrefixCls("menu");
  const [wrapSSR, hashId] = useStyle(prefixCls, !override);

  return (
    <>
      <ProLayout
        logo={logoRetangular}
        title="Doctor Help"
        onMenuHeaderClick={() => {
          navigate("/");
        }}
        layout="top"
        navTheme="light"
        route={menus}
        location={location}
        headerContentRender={(props, defaultDom) => {
          if (props.isMobile) {
            return defaultDom;
          }

          return wrapSSR(
            <div
              className="menu-personalizado"
              style={{
                // display: "flex",
                alignItems: "center",
                marginInlineEnd: 52,
                width: "100%",
              }}
            >
              <Overflow<MenuDataItem>
                className={classNames(
                  "ant-menu ant-menu-root ant-menu-horizontal ant-menu-light top-nav-menu",
                  hashId
                )}
                prefixCls={prefixCls}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                component="ul"
                itemComponent="li"
                data={props.menuData || []}
                renderItem={(menuData) => (
                  <DropdownMenu menu={menuData} key={menuData.path} />
                )}
                renderRest={(omitted) => <AllDropdownMenu menus={omitted} />}
                maxCount={"responsive"}
              />
            </div>
          );
        }}
        menuItemRender={(item: any, dom: any) => {
          let menu = dom;

          if (item.path) {
            menu = <Link to={item.path}>{item.name}</Link>;
          }

          if (item.icon) {
            if (React.isValidElement(item.icon)) {
              menu = (
                <>
                  {item.icon}
                  {menu}
                </>
              );
            } else if (typeof item.icon === "string") {
              menu = (
                <>
                  <img
                    alt={item.name}
                    src={item.icon as string}
                    className="ant-pro-sider-menu-icon"
                  />
                  {menu}
                </>
              );
            }
          }
          return menu;
        }}
        // disableContentMargin={false}
        rightContentRender={() => <GlobalHeaderRight />}
        footerRender={() => (
          <DefaultFooter links={false} copyright="Oral Sin Franquias" />
        )}
      >
        {children ? (
          children
        ) : (
          <React.Suspense
            fallback={
              <PageContainer title={false} breadcrumb={{}} loading={true} />
            }
          >
            <Outlet />
          </React.Suspense>
        )}
      </ProLayout>
    </>
  );
};

export default PageLayout;
