import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import ProForm, {
  ProFormCheckbox,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-form";
import { Button } from "antd";
import React from "react";

const Gerenciar: React.FC = () => {
  return (
    <>

          <ProForm
            submitter={false}
          >
            <ProFormCheckbox>
              Consulta Crednet Serasa automática ao criar orçamento/contrato
              (farol):
            </ProFormCheckbox>
            <ProForm.Group>
              <ProFormText
                name="celularSMS"
                label="Celular para envio de SMS de condição especial solicitada (11-1111-1111)"
                width="md"
              ></ProFormText>
              <Button type="primary" danger>
                <MinusOutlined />
              </Button>
              <Button type="primary">
                <PlusOutlined />
              </Button>
            </ProForm.Group>
            <ProFormCheckbox>
              Agendamento apenas com contrato PRD:
            </ProFormCheckbox>
            <ProFormCheckbox>
              Agendamento apenas com contrato PLA:
            </ProFormCheckbox>
            <ProForm.Group>
              <ProFormCheckbox>
                Agendamento apenas com contrato PLA:
              </ProFormCheckbox>
              <ProFormSelect
                options={[
                  {
                    value: "1",
                    label: "COM1",
                  },
                  {
                    value: "2",
                    label: "COM2",
                  },
                ]}
                name="porta"
                label="Porta"
              ></ProFormSelect>
            </ProForm.Group>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Desativado",
                },
                {
                  value: "2",
                  label: "3",
                },
              ]}
              name="dias"
              label="Dias após vencimento para registrar inadimplência automática	"
              width="md"
            ></ProFormSelect>
            <ProForm.Group label="Forma de pagamento para registrar inadimplência automática (Caso todos sejam desativados será registrado o padrão: Boleto Bancário e Carteira)">
              <ProFormCheckbox>Boleto Bancario</ProFormCheckbox>
              <ProFormCheckbox>Carteira</ProFormCheckbox>
            </ProForm.Group>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "3",
                },
              ]}
              name="dias"
              label="Tempo padrão Agenda Produção	"
              width="sm"
            ></ProFormSelect>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "3",
                },
              ]}
              name="dias"
              label="Tempo padrão Agenda Planejamento	"
              width="sm"
            ></ProFormSelect>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "3",
                },
              ]}
              name="dias"
              label="Tempo padrão Agenda Avaliação"
              width="sm"
            ></ProFormSelect>
            <ProFormCheckbox>
              Permitir envio manual de SMS de cobrança
            </ProFormCheckbox>
            <ProFormCheckbox>
              Permitir envio manual de e-mail de cobrança
            </ProFormCheckbox>
            <ProForm.Group label="Envio automático de e-mail de cobrança">
              <ProFormCheckbox>Faltam alguns dias</ProFormCheckbox>
              <ProFormCheckbox>Falta um dia</ProFormCheckbox>
              <ProFormCheckbox>Vence hoje</ProFormCheckbox>
              <ProFormCheckbox>Vencido</ProFormCheckbox>
            </ProForm.Group>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "3",
                },
              ]}
              name="dias"
              label="Dias para enviar e-mail de cobrança de parcela a vencer (Faltam alguns dias)"
              width="sm"
            ></ProFormSelect>
            <ProFormText
              name="emailOrigem"
              label="E-mail de origem de cobrança (padrão no-reply@oralsin.com.br)"
              width="md"
            ></ProFormText>
          </ProForm>
    </>
  );
};

export default Gerenciar;
