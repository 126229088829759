/* eslint-disable react-hooks/rules-of-hooks */
import ProForm, {
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-form";
import { Button, Form } from "antd";
import React, { useState } from "react";

import ModalEmitirRaioXGratuito from "./ModalEmitirRaioXGratuito";
import ModalGratuito from "./ModalGratuitoImg";

const emissaoRaioX: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [imagemG, setImagemG] = useState(false);
  const [emitirRaioXGratuito, setEmitirRaioXGratuito] = useState(false);

  async function OpenModalGratuito(row: any) {
    if (row) {
      setImagemG(row);
    } else {
      setImagemG(false);
    }
    setIsModalVisible(true);
  }

  async function OpenModalEmitirRaioXGratuito(row: any) {
    if (row) {
      setEmitirRaioXGratuito(row);
    } else {
      setEmitirRaioXGratuito(false);
    }
    setIsModalVisible2(true);
  }


  return (
    <>
      <div>
        <Form>
          <br />
          <br />
          <ProForm.Group>
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Panoramico",
                },
                {
                  value: "2",
                  label: "Periapical",
                },
              ]}
              width="sm"
              name="raio-x"
              label="Tipo Raio-X"
            />
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
                {
                  value: "4",
                  label: "4",
                },
              ]}
              width="sm"
              name="quantidade"
              label="Quantidade"
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText
              width="lg"
              name="paciente"
              label="Paciente/Prontuário"
            />
            <ProFormText width="lg" name="CPF" label="CPF" />
            <ProFormText
              width="lg"
              name="valor"
              label="Valor "
              placeholder="0,00"
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText width="lg" name="prontuario" label="Prontuário" />
            <ProFormText
              width="lg"
              name="nascimento"
              label="Data de Nascimento"
              dataFormat="DD/MM/YYYY"
            />
            <ProFormSelect
              options={[
                {
                  value: "1",
                  label: "Londrina - Zona Norte",
                },
                {
                  value: "2",
                  label: "Londrina - Av. Bandeirantes",
                },
              ]}
              width="sm"
              name="Clinica"
              label="Clinica"
            />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormTextArea width="xl" label="Observações" name="obs" />
          </ProForm.Group>
          <ProForm.Group>
            <ProFormText width="lg" name="solicitado" label="Solicitado Por" />
            <ProFormText width="lg" name="Aprovado" label="Aprovado Por" />
          </ProForm.Group>
          <ProForm.Group style={{ textAlign: "center" }}>
            {imagemG && (
              <ModalGratuito
                visible={isModalVisible}
                imagemGratuita={imagemG}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
              />
            )}
            {emitirRaioXGratuito && (
              <ModalEmitirRaioXGratuito
                visible={isModalVisible2}
                emitirRaioXG={emitirRaioXGratuito}
                onOk={() => setIsModalVisible2(false)}
                onCancel={() => setIsModalVisible2(false)}
              />
            )}
            <Button key="1" type="primary" onClick={OpenModalGratuito}>
              Adicionar Imagem
            </Button>
            <Button key="2" type="primary" onClick={OpenModalEmitirRaioXGratuito}>
              Emitir Raio-X Gratuito
            </Button>
            <Button key="3" type="primary" danger>
              Cancelar
            </Button>
          </ProForm.Group>
        </Form>
      </div>
    </>
  );
};

export default emissaoRaioX;
