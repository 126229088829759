import api from "../api";
import { AgendaCompromissoModel } from "../models";
import {
  PaginatedRequest,
  PaginatedResponse,
  SingleResponse,
} from "../utilities";

export function list(
  options: {
    params?: PaginatedRequest<{
      idClinica?: number;
      dataInicial?: string;
      dataFinal?: string;
      idDentista?: number[];
      idClinicaDentista?: number[];
      tipo?: "clinica" | "dentista" | "global";
    }>;
    signal?: AbortSignal;
  } = {}
) {
  return api.get<PaginatedResponse<AgendaCompromissoModel>>(
    "agenda/compromissos",
    options
  );
}

export function get(
  idAgendaCompromisso: number,
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.get<SingleResponse<AgendaCompromissoModel>>(
    `agenda/compromissos/${idAgendaCompromisso}`,
    options
  );
}

export function novoCompromisso(
  data: AgendaCompromissoModel,
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.post<SingleResponse<AgendaCompromissoModel>>(
    `agenda/compromissos`,
    data,
    options
  );
}

export function atualizaCompromisso(
  idAgendaCompromisso: number,
  data: AgendaCompromissoModel,
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.patch<SingleResponse<AgendaCompromissoModel>>(
    `agenda/compromissos/${idAgendaCompromisso}`,
    data,
    options
  );
}

export function excluir(
  idAgendaCompromisso: number,
  options: {
    signal?: AbortSignal;
  } = {}
) {
  return api.delete<SingleResponse<AgendaCompromissoModel>>(
    `agenda/compromissos/${idAgendaCompromisso}`,
    options
  );
}
