import { ProFormSelect } from '@ant-design/pro-form';
import axios from "axios";
import React, { useEffect,useState} from "react";

import { useClinica } from "../contexts";
import api from "../services/api";

function BancosSaida(props: any){

    const [bancosSaida, setBancosSaida]       = useState();
    const { clinicaAtual }                    = useClinica();
    const [loadBancoSaida, setLoadBancoSaida] = useState(false);

    const listaBancosSaida = async () => {
        
        setLoadBancoSaida(true);
        let lista: any = [];

        try {
            const { data } = await api.get("financeiro/list-conta-capital", { params: { idClinica: clinicaAtual.id } });
            
            data.data.map(function (result: any, index: number) {
                lista[index] = { value: result.idContaCapital, label: clinicaAtual.sigla+' / '+result.contaCapital }; 
            });

            setBancosSaida(lista);
            setLoadBancoSaida(false);
            
        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        listaBancosSaida();
      }, []);

    return (

        <ProFormSelect
            options={bancosSaida}
            name="bancosSaida"
            fieldProps={{
                loading: loadBancoSaida
            }}
        />
            
    )    

}
export default BancosSaida;
