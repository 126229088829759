import { ProFormTreeSelect } from '@ant-design/pro-form';
import axios from "axios";
import { useState } from "react";

import { useClinica } from "../contexts";
import api from "../services/api";

function GrupoContas(props: any){

    const [contaGrupo, setContaGrupo] = useState<[]>([]);
    const { clinicaAtual }            = useClinica();

    const listaContaGrupo = async () => {
        
        if(contaGrupo.length)
            return contaGrupo;

        let lista: any = [];

        try {
            const { data } = await api.get("financeiro/list-conta-grupo", { params: { idClinica: clinicaAtual.id, tipoGrupo: 1 } });
            
            data.map(function (result: any, index: number) {
                lista[lista.length] = {
                    value: result.idContaGrupo, 
                    title: result.nomeContaGrupo, 
                    children: (result.childs.length)?ContasGrupos(result.childs):[]
                };
                
            });
            setContaGrupo(lista);
            return lista;

        } catch (error) {
            if (!axios.isAxiosError(error)) {
                console.log(error);
            }
        }
    };

    const ContasGrupos = (childs: []) => {

        let lista: any = [];

        childs.map(function (result: any, index: number) {
            lista[lista.length] = { 
                value: result.idContaGrupo, 
                title: result.nomeContaGrupo, 
                children: (result.childs.length)?ContasGrupos(result.childs):[]
            };
        });

        return lista;

    }

    return (

        <ProFormTreeSelect
            name={props.name}
            allowClear
            request={listaContaGrupo}
            fieldProps={{
                showArrow: false,
                filterTreeNode: true,
                showSearch: true,
                dropdownMatchSelectWidth: false,
                labelInValue: true,
                autoClearSearchValue: true,
                multiple: props.multiple,
                treeNodeFilterProp: 'title',
                fieldNames: {
                label: 'title',
                },
            }}
        />
            
    )    

}
export default GrupoContas;