import type { ProFormInstance } from '@ant-design/pro-form';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { message, Modal, Skeleton } from 'antd';
import axios from "axios";
import React, { useEffect,useRef, useState } from 'react';

import api from "../../services/api";
import { ModalBancosProps} from './types';

const ModalBancos: React.FC<ModalBancosProps> = (props) => {

    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [skeleton, setSkeleton]             = useState(false);

    const formRef = useRef<
        ProFormInstance<{
            nome: string;
            numero: string;
        }>
    >();

    useEffect(() => {

        const VM = props.visible;

        if (VM === true) {
            if(props.banco){
                setSkeleton(true);
                GetBanco();
            }
        }

    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.visible],
    );

    async function GetBanco(){
        const { data } = await api.get(`cadastros/bancos/${props.banco}`);
        setSkeleton(false);
        formRef.current?.setFieldsValue(data.data);
    }

    return <Modal
        title="Formulário gerenciamento de Banco"
        open={props.visible}
        onCancel={async (e) => {
            formRef.current?.resetFields();
            props.onCancel();
            setConfirmLoading(false);
        }}
        onOk={async (e) => {
            formRef.current?.submit()
        }}
        confirmLoading={confirmLoading}
    >
        {skeleton ?
            <Skeleton></Skeleton>
        :
            <ProForm<{
                nome: string;
                numero: string;
            }>
                formRef={formRef}
                formKey="gerar-banco"
                request={async () => {
                    return {
                        nome: '',
                        numero: '',
                    };
                }}
                autoFocusFirstInput
                submitter={false}
                onFinish={async (e) => { 

                    setConfirmLoading(true);

                    try {

                        if(props.banco){
                            await api.put(`cadastros/bancos/${props.banco}`, formRef.current?.getFieldsValue());
                        }else{
                            await api.post(`cadastros/bancos`, formRef.current?.getFieldsValue());
                        }

                        props.onCancel();
                        props.loadPage();

                        message.info('Dados salvos!');

                        formRef.current?.resetFields();

                    } catch (error) {
                        if(axios.isAxiosError(error)){
                            
                            if (error.response?.data.errors) {
                                for (const name in error.response?.data.errors) {
                                    
                                    const errors = (error.response?.data.errors as any)[name] || [];
                                    formRef.current?.setFields([{ name, errors }]);
                                    
                                }
                            }
                        
                            if (error.message) {
                                message.error("Verifique os campos.");
                            }
                        }
                    }

                    setConfirmLoading(false);
                    
                    

                }}
            >
                <ProFormText
                    name="nome"
                    required
                    label="Titulo Banco"
                    placeholder="Título do Banco"
                    rules={[
                        { required: true, message: 'Campo Obrigatório' }]
                    }
                />
                <ProFormText
                    name="numero"
                    required
                    label="Número do banco"
                    placeholder="Número do banco"
                    rules={[
                        { required: true, message: 'Campo Obrigatório' },
                        { max: 3, message: 'Máximo de 3 digitos.' },
                        { min: 3, message: 'Minimo de 3 digitos.' },
                        { pattern: /^\d+$/, message: 'Apenas números.' }
                    ]}
                />

            </ProForm>
        }
    </Modal>;

}

export default ModalBancos;