import {Modal, notification} from 'antd';
import React, {useEffect, useState} from 'react';

import { ModalProps } from './../types';
import NegociacaoFormalizar from "./NegociacaoFormalizar";
import NegociacaoParcelas from "./NegociacaoParcelas";

const OrcamentoModal: React.FC<ModalProps> = (props) => {
    const titles: any = {
        1: {
            title: "Parcelas",
            cancelText: "Cancelar",
            okText: "Negociar as parcelas selecionadas"
        },
        2: {
            title: "Parcelas",
            cancelText: "Cancelar",
            okText: "Salvar Negociação"
        },
    }
    const [id, setId] = useState<any | null>(null),
          [body, setBody] = useState<number | null>(null),
          [modalConf, setModalConf] = useState<any | null>(titles[1])

    function initializeContract(){
        setId(props.id)
        setBody(props.body)

        if(props.body){
            setModalConf(titles[props.body])
        } else {
            setModalConf(titles[1])
        }
    }

    function nextStep(){
        if(body === 2){
            notification.success({
                placement: 'topRight',
                message: "Dados Salvos com sucesso!"
            })
            props.onClose()
        } else {
            setBody(2)
            setModalConf(titles[2])
        }
    }

    function callback(key: any) {
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.body]);

    return <Modal
        title={modalConf.title}
        open={props.visible}
        cancelText={modalConf.cancelText}
        okText={modalConf.okText}
        width="900px"
        onCancel={async (e) => {
            props.onClose();
        }}
        onOk={() => nextStep()}
    >
        { body === 1 && <NegociacaoParcelas id={props.id} /> }
        { body === 2 && <NegociacaoFormalizar id={props.id} /> }

    </Modal>;
}

export default OrcamentoModal;