import "./index.scss";

import ProForm from "@ant-design/pro-form";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button } from "antd";
import React, { useEffect, useState } from "react";

import ModalAddRaioX from "./ModalAddRaioX";
import { RaioX } from "./types";

const Gerenciar: React.FC = () => {
  const [dados, setDados] = useState<RaioX[]>([]);
  const tableList = dados;

  useEffect(() => {
    const tableList: RaioX[] = [];

    for (let i = 0; i < 20; i += 1) {
      tableList.push({
        nome: "Panorâmico",
        gratuito: "0,00",
        pago: "0,00",
      });
    }
    setDados(tableList);
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addRaioX, setaAddRaioX] = useState(false);

  async function OpenModalRaioX(row: any) {
    if (row) {
      setaAddRaioX(row);
    } else {
      setaAddRaioX(false);
    }
    setIsModalVisible(true);
  }

  const columns: ProColumns<RaioX>[] = [
    {
      title: "Nome",
      dataIndex: "nome",
    },
    {
      title: "Gratuito",
      dataIndex: "gratuito",
    },
    {
      title: "Pago",
      dataIndex: "pago",
    },
  ];
  return (
    <>
      {addRaioX && (
        <ModalAddRaioX
          visible={isModalVisible}
          addRaioX={addRaioX}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
        />
      )}

      <div className="well warning">
        Ao desativar algum tipo de Raio-X ele só será efetivamente desativado no
        próximo dia.
      </div>
      <ProForm.Group>
        <Button type="primary" onClick={OpenModalRaioX}>
          Adicionar Novo Tipo de Raio-X
        </Button>
      </ProForm.Group>
      <ProTable<RaioX>
        columns={columns}
        request={() => {
          return Promise.resolve({
            data: tableList,
            success: true,
          });
        }}
        rowKey="key"
        search={false}
        dateFormatter="string"
      />
    </>
  );
};

export default Gerenciar;
