import { ClearOutlined,SearchOutlined } from "@ant-design/icons";
import ProForm, {
  ProFormDatePicker,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
  ProFormTreeSelect,
  QueryFilter,
} from "@ant-design/pro-form";
import { AutoComplete } from "antd";
import axios from "axios";
import React, { useState } from "react";

import { useClinica } from "../../contexts";
import api from "../../services/api";
import { InputMoney } from "./components";
import { FilterAReceberProps } from "./types";

const FilterAReceber: React.FC<FilterAReceberProps> = (props) => {
  const { clinicaAtual } = useClinica();
  const [loadBancoEntrada, setLoadBancoEntrada] = useState(false);
  const [ajaxTipo, setAjaxTipo] = useState<number>();
  const [labelTipo, setLabelTipo] = useState<string>();
  const [displayTipo, setDisplayTipo] = useState(true);
  const [labelFiltro, setLabelFiltro] = useState<string>();
  const [displayFiltro, setDisplayFiltro] = useState(true);
  const [bancosEntrada, setBancosEntrada] = useState();
  const [contaGrupo, setContaGrupo] = useState<[]>([]);
  const [statusPagamento, setStatusPagamento] = useState<[]>([]);
  const [formaPagamento, setFormaPagamento] = useState<[]>([]);
  const [statusFinanceiro, setStatusFinanceiro] = useState<[]>([]);
  const [displayBancosEntrada, setDisplayBancosEntrada] = useState(true);
  const [displayDataNF, setDisplayDataNF] = useState(true);
  const [displayValor, setDisplayValor] = useState(true);
  const [autocomplete, setAutoComplete] = useState("");
  const [valueAutocomplete, setValueAutocomplete] = useState<string>();
  const [listaAutocomplete, setListaAutoComplete] = useState<[]>([]);

  const onSearch = async (searchText: any) => {
    let tipo = "";

    setAutoComplete(searchText);
    console.log(ajaxTipo);

    if (ajaxTipo) {
      if (ajaxTipo == 1) {
        tipo = "fornecedor";
      } else if (ajaxTipo == 2) {
        tipo = "usuario";
      } else if (ajaxTipo == 3) {
        tipo = "paciente";
      }

      const { data } = await api.get("autocomplete/" + tipo, {
        params: { searchText: searchText, idClinica: clinicaAtual.id },
      });

      let res: any = [];

      data.data.map(function (result: any, index: number) {
        if (ajaxTipo == 1) {
          res[res.length] = {
            label: result.nomeFornecedor,
            value: result.idFornecedor,
          };
        } else if (ajaxTipo == 2) {
          res[res.length] = {
            label: result.nomeUsuario,
            value: result.idUsuario,
          };
        } else if (ajaxTipo == 3) {
          res[res.length] = {
            label: result.nomePaciente,
            value: result.idPaciente,
          };
        }
      });

      setListaAutoComplete(res);
    }
  };

  const onChange = (data: string) => {
    setValueAutocomplete(data);
    setAutoComplete(data);
  };

  const listaBancosEntrada = async () => {
    setLoadBancoEntrada(true);
    let lista: any = [];

    try {
      const { data } = await api.get("financeiro/list-conta-capital", {
        params: { idClinica: clinicaAtual.id },
      });

      data.data.map(function (result: any, index: number) {
        lista[index] = {
          value: result.idContaCapital,
          label: clinicaAtual.sigla + " / " + result.contaCapital,
        };
      });

      setBancosEntrada(lista);
      setLoadBancoEntrada(false);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  const listaContaGrupo = async () => {
    if (contaGrupo.length) return contaGrupo;

    let lista: any = [];

    try {
      const { data } = await api.get("financeiro/list-grupo-conta", {
        params: { idClinica: clinicaAtual.id, tipoGrupo: 2 },
      });

      data.map(function (result: any, index: number) {
        lista[lista.length] = {
          value: result.nomeContaGrupo,
          title: result.nomeContaGrupo,
          children: result.childs.length ? ContasGrupos(result.childs) : [],
        };
      });
      setContaGrupo(lista);
      return lista;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  const ContasGrupos = (childs: []) => {
    let lista: any = [];

    childs.map(function (result: any, index: number) {
      lista[lista.length] = {
        value: result.nomeContaGrupo,
        title: result.nomeContaGrupo,
        children: result.childs.length ? ContasGrupos(result.childs) : [],
      };
    });

    return lista;
  };

  const listaStatusPagamento = async () => {
    if (statusPagamento.length) return statusPagamento;

    let lista: any = [];

    try {
      const { data } = await api.get("financeiro/list-status-pagamento", {
        params: { idClinica: clinicaAtual.id },
      });

      data.map(function (result: any, index: number) {
        lista[lista.length] = {
          value: result.statusPagamento,
          title: result.statusPagamento,
        };
      });
      setStatusPagamento(lista);
      return lista;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  const listaStatusFinanceiro = async () => {
    if (statusFinanceiro.length) return statusFinanceiro;

    let Financeiro: any = [];

    try {
      const { data } = await api.get("financeiro/list-status-financeiro", {
        params: { idClinica: clinicaAtual.id },
      });

      data.map(function (result: any, index: number) {
        Financeiro[Financeiro.length] = {
          value: result.nomeStatusFinanceiro,
          title: result.nomeStatusFinanceiro,
        };
      });
      setStatusFinanceiro(Financeiro);
      return Financeiro;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  const listaFormaPagamento = async () => {
    if (formaPagamento.length) return formaPagamento;

    let Fpagamento: any = [];

    try {
      const { data } = await api.get("financeiro/list-forma-pagamento", {
        params: {
          idClinica: clinicaAtual.id,
        },
      });

      data.map(function (result: any, index: number) {
        Fpagamento[Fpagamento.length] = {
          value: result.nomeFormaPagamento + " - " + result.nomeInstituicao,
          title: result.nomeFormaPagamento + " - " + result.nomeInstituicao,
        };
      });
      setFormaPagamento(Fpagamento);
      return Fpagamento;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  let optionsFiltros = [
    {
      value: 0,
      label: "Nenhum",
    },
    {
      value: 1,
      label: "Banco Entrada",
    },
    {
      value: 2,
      label: "Contrato",
    },
    {
      value: 3,
      label: "Contrato Financeira",
    },
    {
      value: 4,
      label: "Data NF",
    },
    {
      value: 5,
      label: "idParcela",
    },
    {
      value: 6,
      label: "idParcelaRx",
    },
    {
      value: 7,
      label: "Numero NF",
    },
    {
      value: 8,
      label: "Pagante",
    },
    {
      value: 9,
      label: "Serviço",
    },
    {
      value: 10,
      label: "Valor Parcela",
    },
    {
      value: 11,
      label: "CV ou DOC",
    },
    {
      value: 12,
      label: "4 ult digitos",
    },
    {
      value: 13,
      label: "AUTO",
    },
    {
      value: 14,
      label: "Numero Cheque",
    },
  ];

  const SetInput = (value: number) => {
    setAjaxTipo(value);

    if (value === 0) setDisplayTipo(true);
    else setDisplayTipo(false);

    if (value === 1) setLabelTipo("Fornecedor");
    else if (value === 2) setLabelTipo("Funcionário");
    else if (value === 3) setLabelTipo("Paciênte");
  };

  const ShowInputs = (value: number) => {
    setDisplayFiltro(true);
    setDisplayBancosEntrada(true);
    setDisplayDataNF(true);
    setDisplayValor(true);

    if (value > 1 && value < 15) {
      if (value == 2) setLabelFiltro("Contrato");
      else if (value == 3) setLabelFiltro("Contrato Financeira");
      else if (value == 5) setLabelFiltro("idParcela");
      else if (value == 6) setLabelFiltro("idParcelaRx");
      else if (value == 7) setLabelFiltro("Numero NF");
      else if (value == 9) setLabelFiltro("Serviço");
      else if (value == 11) setLabelFiltro("CV ou DOC");
      else if (value == 12) setLabelFiltro("4 ult digitos");
      else if (value == 13) setLabelFiltro("AUTO");
      else if (value == 14) setLabelFiltro("Numero Cheque");

      setDisplayFiltro(false);
    } else if (value == 1) {
      listaBancosEntrada();
      setDisplayBancosEntrada(false);
    } else if (value == 4) {
      setDisplayDataNF(false);
    } else if (value == 10) setDisplayValor(false);
  };

  return (
    <QueryFilter
      defaultColsNumber={8}
      layout="vertical"
      submitter={{
        searchConfig: { submitText: "Pesquisar" },
        submitButtonProps: { icon: <SearchOutlined /> },
        resetButtonProps: { icon: <ClearOutlined /> },
      }}
      onFinish={async (values: any) => {
        values.autoComplete = valueAutocomplete;
        props.loadPage(values);
      }}
      onReset={() => {
        props.loadPage([]);
      }}
    >
      <ProFormSelect
        options={[
          {
            value: 1,
            label: "Data Vencimento",
          },
          {
            value: 2,
            label: "Data Baixa",
          },
          {
            value: 3,
            label: "Data Conferência",
          },
          {
            value: 4,
            label: "Data Destinação",
          },
          {
            value: 5,
            label: "Data Fomalização",
          },
          {
            value: 6,
            label: "Data Contrato Gerado",
          },
          {
            value: 7,
            label: "Data Documento",
          },
        ]}
        name="tipoIntervalo"
        label="Tipo Intervalo"
      />
      <ProFormDateRangePicker
        label="Data Inicial e Final"
        fieldProps={{ format: "DD/MM/YYYY" }}
        name="intervalo"
      />
      <ProFormSelect
        options={[
          {
            value: 0,
            label: "Todos",
          },
          {
            value: 1,
            label: "Fornecedor",
          },
          {
            value: 2,
            label: "Funcionário",
          },
          {
            value: 3,
            label: "Paciente",
          },
        ]}
        name="tipo"
        label="Tipo"
        fieldProps={{
          onChange: (tipo: number) => {
            SetInput(tipo);
          },
        }}
      />

      <div hidden={displayTipo}>
        <p style={{ marginBottom: 8 }}>{labelTipo}</p>
        <AutoComplete
          value={autocomplete}
          options={listaAutocomplete}
          onSearch={onSearch}
          onChange={onChange}
          style={{ width: "100%" }}
          placeholder={"Busca de " + labelTipo}
        />
      </div>

      {/* FILTRO */}
      <ProFormSelect
        options={optionsFiltros}
        name="filtro"
        label="Filtro"
        fieldProps={{
          onChange: (value: number) => {
            ShowInputs(value);
          },
        }}
      />

      <ProFormSelect
        options={bancosEntrada}
        name="bancosEntrada"
        label="Bancos"
        hidden={displayBancosEntrada}
        fieldProps={{
          loading: loadBancoEntrada,
        }}
      />

      <ProFormText
        label={labelFiltro}
        hidden={displayFiltro}
        name="valorFiltro"
      />

      <ProFormDatePicker
        label="Data NF"
        hidden={displayDataNF}
        name="dataNF"
      />

      <div hidden={displayValor} className="ant-col ant-col-24">
        <div className="ant-col ant-col-11" style={{ float: "left" }}>
          <ProForm.Item name="valorFrom" label="De">
            <InputMoney />
          </ProForm.Item>
        </div>
        <div className="ant-col ant-col-11" style={{ float: "right" }}>
          <ProForm.Item name="valorTo" label="Até">
            <InputMoney />
          </ProForm.Item>
        </div>
      </div>
      {/* FINAL DO FILTRO */}


      <ProFormTreeSelect
        label="Conta Grupo"
        name="nomeContaGrupo"
        request={listaContaGrupo}
        fieldProps={{
          multiple: true,
          treeNodeFilterProp: "title",
        }}
      />
      <ProFormSelect
        label="Financeira"
        name="sigla"
        placeholder="Digite a financeira ou selecione na lista"
        request={listaStatusFinanceiro}
        fieldProps={{
          mode: "multiple",
        }}
      />
      <ProFormSelect
        label="Forma Pagto"
        name="nomeFormaPagamento"
        placeholder="Digite a forma de pagamento ou selecione na lista"
        request={listaFormaPagamento}
        fieldProps={{
          mode: "multiple",
        }}
      />
      <ProFormTreeSelect
        label="Situação"
        name="statusPagamento"
        request={listaStatusPagamento}
        fieldProps={{
          multiple: true,
        }}
      />
    </QueryFilter>
  );
};

export default FilterAReceber;
