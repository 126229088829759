import { SaveOutlined } from "@ant-design/icons";
import ProForm, {
  ProFormDatePicker,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTreeSelect
} from "@ant-design/pro-form";
import { AutoComplete, Button, Col, message, Modal, Row, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { SelectClinica } from "../../components/SelectClinica";
import { useClinica } from "../../contexts";
import api from "../../services/api";
import { InputMoney } from "./components";
import SelectFornecedor from "./components/SelectFornecedor";
import { ModalAReceberLancamentoProps } from "./types";

const { Title } = Typography;

const ModalLancamento: React.FC<ModalAReceberLancamentoProps> = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formRef = useRef<
    ProFormInstance<{
      idClinica: number;
      nomeClinica: string;
      descricao: string;
      dataDocumento: Date;
      dataVencimento: Date;
      valorFinal: string;
      valor: string;
    }>
  >();

  const [dataDocumento, setDataDocumento] = useState();
  const [dataVencimento, setDataVencimento] = useState();
  const [valorTotal, setValorTotal] = useState(0);

  const { clinicaAtual } = useClinica();

  const [ajaxTipo, setAjaxTipo] = useState<number>();
  const [labelTipo, setLabelTipo] = useState<string>();
  const [displayTipo, setDisplayTipo] = useState(true);
  const [displayPaciente, setDisplayPaciente] = useState(true);
  const [displayFornecedor, setDisplayFornecedor] = useState(true);
  const [displayFuncionario, setDisplayFuncionario] = useState(true);

  const [formaPagamento, setFormaPagamento] = useState<[]>([]);
  const [contaGrupo, setContaGrupo] = useState<[]>([]);

  const [autocomplete, setAutoComplete] = useState("");
  const [valueAutocomplete, setValueAutocomplete] = useState<string>();
  const [listaAutocomplete, setListaAutoComplete] = useState<[]>([]);
  const [loadBancoSaida, setLoadBancoSaida] = useState(false);
  const [bancosSaida, setBancosSaida] = useState();
  const [statusFinanceiro, setStatusFinanceiro] = useState<[]>([]);

  const ValorTotal = (value: any) => {
    setValorTotal(value);
  };

  const DataVencimento = (value: any) => {
    setDataVencimento(value.format("YYYY-MM-DD"));
  };

  const DataDocumento = (value: any) => {
    setDataDocumento(value.format("YYYY-MM-DD"));
  };

  const onSearch = async (searchText: any) => {
    let tipo = "";

    setAutoComplete(searchText);
    console.log(ajaxTipo);

    if (ajaxTipo) {
      if (ajaxTipo == 1) {
        tipo = "fornecedor";
      } else if (ajaxTipo == 2) {
        tipo = "usuario";
      } else if (ajaxTipo == 3) {
        tipo = "paciente";
      }

      const { data } = await api.get("autocomplete/" + tipo, {
        params: { searchText: searchText, idClinica: clinicaAtual.id },
      });

      let res: any = [];

      data.data.map(function (result: any, index: number) {
        if (ajaxTipo == 1) {
          res[res.length] = {
            label: result.nomeFornecedor,
            value: result.nomeFornecedor,
          };
        } else if (ajaxTipo == 2) {
          res[res.length] = {
            label: result.nomeUsuario,
            value: result.idUsuario,
          };
        } else if (ajaxTipo == 3) {
          res[res.length] = {
            label: result.nomePaciente,
            value: result.nomePaciente,
          };
        }
      });

      setListaAutoComplete(res);
    }
  };

  const onChange = (data: string) => {
    setValueAutocomplete(data);
    setAutoComplete(data);
  };

  const SetInput = (value: number) => {
    setDisplayFornecedor(true);
    setDisplayFuncionario(true);
    setDisplayPaciente(true);

    if (value === 1) {
      setDisplayFornecedor(false);
    } else if (value === 2) {
      setDisplayFuncionario(false);
    } else if (value === 3) {
      setDisplayPaciente(false);
    }
  };

  const listaContaGrupo = async () => {
    if (contaGrupo.length) return contaGrupo;

    let lista: any = [];

    try {
      const { data } = await api.get("financeiro/list-grupo-conta", {
        params: { idClinica: clinicaAtual.id, tipoGrupo: 2 },
      });

      data.map(function (result: any, index: number) {
        lista[lista.length] = {
          value: result.idContaGrupo,
          title: result.nomeContaGrupo,
          children: result.childs.length ? ContasGrupos(result.childs) : [],
        };
      });
      setContaGrupo(lista);
      return lista;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  const ContasGrupos = (childs: []) => {
    let lista: any = [];

    childs.map(function (result: any, index: number) {
      lista[lista.length] = {
        value: result.idContaGrupo,
        title: result.nomeContaGrupo,
        children: result.childs.length ? ContasGrupos(result.childs) : [],
      };
    });

    return lista;
  };

  const listaBancosSaida = async () => {
    setLoadBancoSaida(true);
    let lista: any = [];

    try {
      const { data } = await api.get("financeiro/list-conta-capital", {
        params: { idClinica: clinicaAtual.id },
      });

      data.data.map(function (result: any, index: number) {
        lista[index] = {
          value: result.idContaCapital,
          label: clinicaAtual.sigla + " / " + result.contaCapital,
        };
      });

      setBancosSaida(lista);
      setLoadBancoSaida(false);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    listaBancosSaida();
  }, []);

  const listaFormaRecebimento = async () => {
    if (formaPagamento.length) return formaPagamento;

    let Fpagamento: any = [];

    try {
      const { data } = await api.get("financeiro/list-forma-recebimento", {
        params: {
          idClinica: clinicaAtual.id,
        },
      });

      data.map(function (result: any, index: number) {
        Fpagamento[index] = {
          value: result.idFormaPagamento,
          label: result.nomeFormaPagamento,
        };
      });
      setFormaPagamento(Fpagamento);
      return Fpagamento;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  const listaStatusFinanceiro = async () => {
    if (statusFinanceiro.length) return statusFinanceiro;

    let Financeiro: any = [];

    try {
      const { data } = await api.get("financeiro/list-status-financeiro", {
        params: { idClinica: clinicaAtual.id },
      });

      data.map(function (result: any, index: number) {
        Financeiro[index] = {
          value: result.idStatusFinanceiro,
          label: result.nomeStatusFinanceiro,
        };
      });
      setStatusFinanceiro(Financeiro);
      return Financeiro;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Modal
        title="Contas a Receber"
        open={props.visible}
        onCancel={async (e) => {
          formRef.current?.resetFields();
          props.onCancel();
          setConfirmLoading(false);
        }}
        onOk={async (e) => {
          formRef.current?.submit();
        }}
        width="80%"
        style={{ maxWidth: "1280px" }}
        footer={false}
        confirmLoading={confirmLoading}
      >
        <ProForm<{
          nomeClinica: string;
          descricao: string;
          valorFinal: string;
        }>
          formRef={formRef}
          request={async () => {
            return {
              nomeClinica: "",
              descricao: "",
              valorFinal: "",
            };
          }}
          submitter={{
            resetButtonProps: {
              style: {
                display: "none",
              },
            },
            submitButtonProps: {
              icon: <SaveOutlined />,
            },
            render: (props, doms) => {
              return [
                <Row justify="end">
                  <Col>
                    <Button
                      type="primary"
                      icon={<SaveOutlined />}
                      key="submit"
                      onClick={() => props.form?.submit()}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>,
              ];
            },
          }}
          size="middle"
          onFinish={async (e) => {
            setConfirmLoading(true);

            try {
              let data;

              if (props.idContaReceber) {
                data = await api.put(
                  `financeiro/lancamentos/${props.idContaReceber}`,
                  formRef.current?.getFieldsValue()
                );
              } else {
                data = await api.post(
                  `financeiro/lancamentos`,
                  formRef.current?.getFieldsValue()
                );
              }

              props.onCancel();

              message.info("Parcela Lançada!");

              formRef.current?.resetFields();
            } catch (error) {
              if (axios.isAxiosError(error)) {
                if (error.response?.data.errors) {
                  for (const name in error.response?.data.errors) {
                    const errors =
                      (error.response?.data.errors as any)[name] || [];
                    formRef.current?.setFields([{ name, errors }]);
                  }
                }
                if (error.message) {
                  message.error("Verifique os campos.");
                }
              }
            }
            setConfirmLoading(false);
          }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <ProFormText
                name="descricao"
                label="Descrição"
                placeholder="Descrição"
                required
                rules={[{ required: true, message: "Campo Obrigatório" }]}
              />
            </Col>
            <Col span={4}>
              <ProFormText
                name="cpfPaciente"
                label="CPF / CNPJ"
                fieldProps={{
                  maxLength: 14,
                }}
              />
            </Col>
            <Col span={5}>
              <ProForm.Item
                name="idClinica"
                label="Clínica"
                initialValue={clinicaAtual.id}
                required
              >
                <SelectClinica />
              </ProForm.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={3}>
              <ProForm.Item name="valor" label="Valor Total" required>
                <InputMoney onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
            <ProFormText
              name="documento"
              label="Documento"
              placeholder="Doc."
              formItemProps={{ className: "MarginBottomFormInput" }}
            />
            <Col span={4}>
              <ProFormDatePicker
                name="dataDocumento"
                fieldProps={{
                  format: "DD/MM/YYYY",
                  onChange: (value) => {
                    DataDocumento(value);
                  },
                }}
                label="Data Documento"
                placeholder="__/__/____"
                formItemProps={{
                  className: "MarginBottomFormInput",
                  style: { width: "100%" },
                }}
              />
            </Col>
            <Col span={4}>
              <ProFormDatePicker
                name="dataVencimento"
                fieldProps={{
                  format: "DD/MM/YYYY",
                  onChange: (value) => {
                    DataVencimento(value);
                  },
                }}
                label="Vencimento"
                placeholder="__/__/____"
                required
                rules={[{ required: true, message: "Campo Obrigatório" }]}
                formItemProps={{
                  className: "MarginBottomFormInput",
                  style: { width: "100%" },
                }}
              />
            </Col>
            <Col span={3}>
              <ProForm.Item
                name="valorFinal"
                label="Valor Principal"
                initialValue={0}
              >
                <InputMoney disabled onChange={ValorTotal} />
              </ProForm.Item>
            </Col>
          </Row>
          <br />
          <Title level={5}>Contabilização</Title>
          <Row gutter={24}>
            <Col span={6}>
              <ProFormSelect
                options={[
                  {
                    value: 0,
                    label: "Outros",
                  },
                  {
                    value: 1,
                    label: "Fornecedor",
                  },
                  {
                    value: 2,
                    label: "Funcionário",
                  },
                  {
                    value: 3,
                    label: "Paciente",
                  },
                ]}
                name="tipo"
                label="Tipo"
                fieldProps={{
                  onChange: (tipo: number) => {
                    SetInput(tipo);
                  },
                }}
              />
              <div hidden={displayFornecedor}>
                <SelectFornecedor />
              </div>
              <div hidden={displayTipo}>
                <p style={{ marginBottom: 8 }}>{labelTipo}</p>
                <AutoComplete
                  value={autocomplete}
                  options={listaAutocomplete}
                  onSearch={onSearch}
                  onChange={onChange}
                  style={{ width: "100%" }}
                  placeholder={"Busca de " + labelTipo}
                />
              </div>
              <br />
            </Col>
            <Col span={6}>
              <ProFormTreeSelect
                label="Conta Grupo"
                name="idContaGrupo"
                request={listaContaGrupo}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={5}>
              <ProForm.Item name="idContaCapital" label="Bancos" required>
                <ProFormSelect
                  options={bancosSaida}
                  name="idContaCapital"
                  fieldProps={{
                    loading: loadBancoSaida,
                  }}
                />
              </ProForm.Item>
            </Col>
            <Col span={5}>
              <ProForm.Item
                name="idFormaPagamento"
                label="Forma Recebimento"
                required
              >
                <ProFormSelect
                  placeholder="Selecione..."
                  request={listaFormaRecebimento}
                />
              </ProForm.Item>
            </Col>
            <Col span={5}>
              <ProForm.Item
                name="idStatusFinanceiroTipoFinanceira"
                label="Financeira"
                required
              >
                <ProFormSelect
                  placeholder="Selecione..."
                  request={listaStatusFinanceiro}
                />
              </ProForm.Item>
            </Col>
            <Col span={9}>
              <ProFormTextArea
                width="xl"
                label="Observação"
                name="observacao"
                formItemProps={{ className: "MarginBottomFormInput" }}
              />
            </Col>
          </Row>
        </ProForm>
      </Modal>
    </>
  );
};
export default ModalLancamento;
