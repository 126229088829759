import "./index.scss";

import { DeleteOutlined, EyeOutlined, StopOutlined } from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import type { ActionType, ProColumns } from "@ant-design/pro-table";
import ProTable from "@ant-design/pro-table";
import { Button, Dropdown, Menu, message, Popconfirm, Select } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate} from "react-router-dom";

import { SelectUsuariosComercial } from "../../components/SelectUsuariosComercial";
import { useClinica } from "../../contexts";
import { apiAgenda } from "../../services/apis";
import { AgendaUsuarioHorarioModel } from "../../services/models/AgendaUsuarioHorario";
import { UsuariosProvider } from "../Agenda/contexts/UsuariosContext";
import { AgendaUsuarioHorarioFormModal } from "./components/AgendaUsuarioHorarioFormModal";

export const AgendaUsuarioHorario: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idAgendaUsuarioHorario, setIdAgendaUsuarioHorario] = useState<number | null>(
    null
  );

	const tableRef = React.useRef<ActionType>(null);

	const navigate = useNavigate();

	const { clinicaAtual } = useClinica();

	async function openModalForm(idAgendaUsuarioHorario: number | null) {
		setIdAgendaUsuarioHorario(idAgendaUsuarioHorario);
		setIsModalVisible(true);
	}

	const closeModal = () => {
		setIdAgendaUsuarioHorario(null);
		setIsModalVisible(false);
	};

	const ReloadTable = () => {
		setIdAgendaUsuarioHorario(null);
		setIsModalVisible(false);
		tableRef.current?.reload();
	};

	const menuAcoes = (row: AgendaUsuarioHorarioModel) => {

		let botao = (
			<>
			<DeleteOutlined /> Deletar
			</>
		);

		let title = "Esta certo disso ?";
		let mensagemDelete = "Deletando...";

		const onConfirm = () => {
			console.log(row);
			message.loading({ content: mensagemDelete, key: "delete" });
			apiAgenda.apiAgendaDeletaHorario({
				idAgendaUsuarioHorario: row.idAgendaUsuarioHorario
			})
			.then(() => {
				message.success({ content: "Pronto", key: "delete" });
				tableRef.current?.reload();
			})
			.catch((error) => {
				ReloadTable();
			})
			.finally(() => {});
		};

		return (
			<Popconfirm title={title} onConfirm={onConfirm}>
				<Button
					type="primary"
					danger
				>
					{botao}
				</Button>
			</Popconfirm>
		);
	};

  const columns: ProColumns<AgendaUsuarioHorarioModel>[] = [
    
    {
      title: "Usuario",
      dataIndex: ["usuario", "nomeUsuario"],
      valueType: "select",
      renderFormItem: (_, { type, defaultRender, ...rest }, form) => {
        return (
			<SelectUsuariosComercial />
        );
      },
      search: {
        transform: (value) => ({
          idUsuario: value,
        }),
      },
    },
    {
      title: "Data Início",
      dataIndex: "dataInicio",
      valueType: "date",
      initialValue: dayjs(),
      fieldProps: {
        format: ["DD/MM/YYYY", "YYYY-MM-DD"],
      },
      search: {
        transform: (value) => ({
          dataInicio: value.format("YYYY-MM-DD"),
        }),
      },
      render: (dom, row) =>
        row.dataAbertura ? dayjs(row.dataAbertura).format("DD/MM/YYYY") : "",
    },
    {
      title: "Data Fim",
      dataIndex: "dataFinal",
      valueType: "date",
      fieldProps: {
        format: ["DD/MM/YYYY", "YYYY-MM-DD"],
      },
      search: {
        transform: (value) => ({
          dataFinal: value.format("YYYY-MM-DD"),
        }),
      },
      render: (dom, row) =>
        row.dataFechamento ? dayjs(row.dataFechamento).format("DD/MM/YYYY") : "",
    },
    {
      title: "Hora Início",
      dataIndex: "horaInicio",
      valueType: "time",
      hideInSearch: true,
      render: (dom, row) =>
        row.HoraInicio ? dayjs(row.HoraInicio, "HH:mm:ss").format("HH:mm") : "",
    },
    {
      title: "Hora Fim",
      dataIndex: "horaFim",
      hideInSearch: true,
      render: (dom, row) =>
        row.HoraFim ? dayjs(row.HoraFim, "HH:mm:ss").format("HH:mm") : "",
    },
    {
      hideInSearch: true,
      title: "Ações",
      valueType: "option",
      render: (dom, row: any) => [menuAcoes(row)],
    },
  ];
  return (
	<UsuariosProvider>
		<PageContainer
			title="Horários Disponíveis"
			header={{
				onBack: () => navigate(".."),
			}}
			extra={[
				<Button key="1" type="primary" onClick={() => openModalForm(null)}>
				Novo
				</Button>,
			]}
			>
			<AgendaUsuarioHorarioFormModal
				idAgendaUsuarioHorario={idAgendaUsuarioHorario}
				visible={isModalVisible}
				onOk={ReloadTable}
				onCancel={closeModal}
			/>
			<ProTable<AgendaUsuarioHorarioModel>
				actionRef={tableRef}
				columns={columns}
				request={(params, sorter, filter) => {
					const { pageSize, current, ...rest } = params;
					return apiAgenda.getListaHorarios({
						params: {
							per_page: pageSize,
							page: current,
							idClinica:clinicaAtual.id,
							...rest,
						},
					})
				.then((data) => ({
					data: data.data.data,
					success: true,
					total: data.data.meta.total,
				}))
				.catch((error) => ({
					data: [],
					success: false,
					total: 0,
				}));
			}}
			rowKey="idAgendaUsuarioHorario"
			pagination={{
				showQuickJumper: true,
			}}
			search={{
				layout: "vertical",
				defaultCollapsed: true,
			}}
			dateFormatter={false}
			/>
		</PageContainer>
	</UsuariosProvider>
  );
};
