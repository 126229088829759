import { Modal } from 'antd';
import React, {useEffect, useState} from 'react';

import { ModalProps } from './../types';
import Contratos from "./Contratos";
import DadosPaciente from "./DadosPaciente";
import Observacao from "./Observacao";

const M: React.FC<ModalProps> = (props) => {
    const titles: any = {
        1: {
            title: "Observação",
            cancelText: "Cancelar",
            okText: "Ok"
        },
        2: {
            title: "Contratos do paciente",
            cancelText: "Cancelar",
            okText: "Ok"
        },
        3: {
            title: "Dados do paciente",
            cancelText: "Cancelar",
            okText: "Ok"
        }
    }
    const [id, setId] = useState<any | null>(null),
          [body, setBody] = useState<number | null>(null),
          [modalConf, setModalConf] = useState<any | null>(titles[1])

    function initializeContract(){
        setId(props.id)
        setBody(props.body)

        if(props.body){
            setModalConf(titles[props.body])
        } else {
            setModalConf(titles[1])
        }
    }

    function callback(key: any) {
    }
    useEffect(() => {
        initializeContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.body]);

    return <Modal
        title={modalConf.title}
        open={props.visible}
        cancelText={modalConf.cancelText}
        okText={modalConf.okText}
        width="800px"
        onCancel={async (e) => {
            props.onClose();
        }}
    >
        {
            body === 1 && <Observacao />
        }
        {
            body === 2 && <Contratos />
        }
        {
            body === 3 && <DadosPaciente />
        }
    </Modal>;
}

export default M;